import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PanelModule } from 'primeng/panel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { CalendarModule } from 'primeng/calendar';
import { MatButtonModule } from '@angular/material/button';
import { CustomerComponent } from './pages/customer/customer.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { RequestComponent } from './pages/request/request.component';
import { MatStepperModule } from '@angular/material/stepper';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogModule } from 'primeng/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CartsComponent } from './pages/carts/carts.component';
import { CashierComponent } from './pages/cashier/cashier.component';
import { CoffreComponent } from './pages/coffre/coffre.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MonitoringComponent } from './pages/monitoring/monitoring.component';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FileUploadModule } from 'primeng/fileupload';
import { MatRadioModule } from '@angular/material/radio';
import { AccountDialogComponent } from './pages/account-dialog/account-dialog.component';
import { GroupDialogComponent } from './components/group-dialog/group-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { AddCustomerComponent } from './components/add-customer/add-customer.component';
import { MultiSelectModule } from 'primeng/multiselect';

import {MatDialogModule} from '@angular/material/dialog';
import { MemberRequestComponent } from './pages/member-request/member-request.component';
import { GoupRequestComponent } from './pages/goup-request/goup-request.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ApprobationComponent } from './pages/approbation/approbation.component';
import { ImageModule } from 'primeng/image';
import { CartDialogComponent } from './components/cart-dialog/cart-dialog.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import { DischargeComponent } from './components/discharge/discharge.component';
import { UsersComponent } from './pages/users/users.component';
import { CashFlowComponent } from './pages/cash-flow/cash-flow.component';
import { MemberRepaymentsComponent } from './pages/member-repayments/member-repayments.component';
import { GroupRepaymentsComponent } from './pages/group-repayments/group-repayments.component';
import { GroupPortfolioComponent } from './pages/group-portfolio/group-portfolio.component';
import { MemberPortfolioComponent } from './pages/member-portfolio/member-portfolio.component';
import { UserCollectsComponent } from './pages/user-collects/user-collects.component';
import { DatComponent } from './pages/dat/dat.component';
import { EavComponent } from './pages/eav/eav.component';
import { EavgComponent } from './pages/eavg/eavg.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChartModule } from 'primeng/chart';
import { EntrepriseComponent } from './pages/entreprise/entreprise.component';
import { AccountComponent } from './pages/account/account.component';
import { GroupComponent } from './pages/group/group.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NavBarComponent,
    FooterComponent,
    LoadingComponent,
    ExternalApiComponent,
    ErrorComponent,
    DashboardComponent,
    CustomerComponent,
    RequestComponent,
    CartsComponent,
    CashierComponent,
    CoffreComponent,
    UserDetailsComponent,
    TransactionsComponent,
    ConfigurationComponent,
    MonitoringComponent,
    CustomerDetailsComponent,
    AccountDialogComponent,
    GroupDialogComponent,
    AddCustomerComponent,
    MemberRequestComponent,
    GoupRequestComponent,
    ApprobationComponent,
    CartDialogComponent,
    TransactionsComponent,
    DischargeComponent,
    UsersComponent,
    CashFlowComponent,
    MemberRepaymentsComponent,
    GroupRepaymentsComponent,
    GroupPortfolioComponent,
    MemberPortfolioComponent,
    UserCollectsComponent,
    DatComponent,
    EavComponent,
    EavgComponent,
    EntrepriseComponent,
    AccountComponent,
    GroupComponent
  ],
  imports: [
    BrowserModule,
    MatListModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ImageModule,
    HttpClientModule,
    MatSlideToggleModule,
    NgbModule,
    MatDialogModule,
    MatDialogModule,
    FileUploadModule ,
    NgbModule,
    InputNumberModule ,
    DialogModule ,
    SplitButtonModule,
    ButtonModule ,
    TableModule,
    MessagesModule,
    MatTabsModule,
    CardModule ,
    DropdownModule,
    InputTextModule,
    MatIconModule,
    MultiSelectModule,
    MatDividerModule,
    MatDialogModule,
    MatIconModule,
    FileUploadModule,
    MatTableModule,
    MatRadioModule,
    MatSortModule,
    MatTabsModule,
    MatPaginatorModule,
    FormsModule,
    PanelModule,
    MatRadioModule,
    ChartModule,
    CalendarModule,
    MessagesModule,
    MatStepperModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    HighlightModule,
    FontAwesomeModule,
    NgxMatSelectSearchModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },
    [{
      provide: LOCALE_ID,
      useValue: 'fr-FR' // 'de' for Germany, 'fr' for France ...
    }
    ],
    DatePipe,
    MessageService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
