<H4 class="title"><span class="material-symbols-outlined">
        arrow_back_ios </span>{{ title }}
</H4>

<!-- commentaire-->
<form style="overflow: hidden !important" enctype="multipart/form-data" method="post">
    <mat-tab-group style="overflow: hidden !important">
        <mat-tab label="Infos Membre">
            <div>
                <h5>Informations personnelles</h5>
                <div style="display: flex">
                    <mat-form-field class="large" appearance="fill">
                        <mat-label>Nom</mat-label>
                        <input name="daLastName" matInput [(ngModel)]="model.lastname" required maxlength="50" />
                    </mat-form-field>
                    <!-- BRANCH -->
                    <mat-form-field class="list" appearance="fill">
                        <mat-label>Selectionner l'Agence</mat-label>
                        <mat-select name="daAgence" [(ngModel)]="userAgency.agencyId" required>
                            <mat-option [value]="userAgency.agencyId">{{userAgency.agencyName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="large" appearance="fill">
                        <mat-label>Post nom</mat-label>
                        <input name="daPostName" matInput [(ngModel)]="model.postname" maxlength="50" />
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field class="large" appearance="fill">
                        <mat-label>Prénom</mat-label>
                        <input name="daEditFName" matInput [(ngModel)]="model.firstname" required maxlength="50" />
                    </mat-form-field>
                </div>

                <div>
                    <div><mat-label>Genre *</mat-label></div>
                    <mat-radio-group aria-label="Select an option" name="daGenre" [(ngModel)]="model.gender" required>
                        <mat-radio-button *ngFor="let g of genders" value="{{g.gender}}">{{g.gender}}</mat-radio-button>

                    </mat-radio-group>
                </div>
                <div style="display: flex">
                    <mat-form-field class="extra-large" appearance="fill">
                        <mat-label>Adresse</mat-label>
                        <input name="daStreet" matInput [(ngModel)]="model.street" required maxlength="50"
                            placeholder="ex: N°129, Dodoma/Ngaliema" />
                    </mat-form-field>
                </div>

                <div style="display: flex">
                    <mat-form-field class="extra-large" appearance="fill">
                        <mat-label>Téléphone1</mat-label>
                        <input name="daTel1" type="tel" matInput [(ngModel)]="model.phone1" required maxlength="50" />
                    </mat-form-field>

                    <mat-form-field class="large city" appearance="fill">
                        <mat-label>Téléphone2</mat-label>
                        <input name="daTel2" type="tel" [(ngModel)]="model.phone2" matInput maxlength="50" />
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="large" appearance="fill">
                        <mat-label>Email</mat-label>
                        <input name="daEmail" matInput [(ngModel)]="model.email" maxlength="50"
                            placeholder="tshiunzab@gmail.com" />
                    </mat-form-field>
                    <mat-form-field class="large" appearance="fill" style="margin-left: 5px;">
                        <mat-label>Nationalité </mat-label>
                        <input matInput name="daNationality" [(ngModel)]="model.nationality" required/>
                    </mat-form-field>
                </div>
            </div>
            <!-- PARTIE PHOTOS, DOCUMENTS -->
            <div>
                <div style="display: relative">
                    <h5>Photos</h5>
                    <div>
                        Photo Membre
                        <p-fileUpload name="daprofileImage[]" chooseLabel="Photo Membre" uploadLabel="Upload"
                            cancelLabel="Annuler" [customUpload]="true" (uploadHandler)="onProfilUpload($event)"
                            accept="image/*">
                        </p-fileUpload>
                        <div class="photo" *ngIf="profilImage_url">
                            <img name="daprofile" [src]="profilImage_url" width="200px" height="200px" />
                            <div class="delete" title="Supprimer image">
                                <span class="material-symbols-outlined">
                                    delete
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <!--SPECIMEN  -->
                        Photo Specimen
                        <p-fileUpload name="daSpecimenImage[]" chooseLabel="Photo Specimen" [customUpload]="true"
                            (uploadHandler)="onSpecimenUpload($event)" uploadLabel="Upload" cancelLabel="Annuler"
                            accept="image/*">
                        </p-fileUpload>
                        <div class="photo" *ngIf="specimenImage_url">
                            <img name="daSpec" [src]="specimenImage_url" width="200px" height="200px" />
                            <div class="delete" title="Supprimer image">
                                <span class="material-symbols-outlined">
                                    delete
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5>Identité</h5>
                        <div>Date de Naissance</div>
                        <p-calendar name="dtNaissance" dateFormat="dd-mm-yy" inputId="basic"
                            [(ngModel)]="model.birthDate">
                        </p-calendar>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Lieu De Naissance</mat-label>
                            <input name="daLieuNaiss" [(ngModel)]="model.birth_place" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Type de Document </mat-label>
                            <mat-select name="daFileType" [(ngModel)]="model.identityPiece_type" required>
                                <mat-option *ngFor="let ft of filetypes"
                                    value="{{ft.fileType}}">{{ft.fileType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Numéro de Document</mat-label>
                            <input name="daDocumentNum" [(ngModel)]="model.document_num" matInput required
                                maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <div>Document Valable Jusqu'au</div>
                        <p-calendar name="dtDocExpiry" [(ngModel)]="model.identityPiece_expiryDate"
                            dateFormat="dd-mm-yy" inputId="basic">
                        </p-calendar>
                    </div>
                    <div>
                        <!--SPECIMEN  -->
                        Photo Document D'identité
                        <p-fileUpload name="daIdentityImage[]" chooseLabel="Photo Identité" [customUpload]="true"
                            (uploadHandler)="onIdentityUpload($event)" uploadLabel="Upload" cancelLabel="Annuler"
                            accept="image/*">
                        </p-fileUpload>
                        <div class="photo" *ngIf="identityImage_url">
                            <img name="daidentity" [src]="identityImage_url" width="200px" height="200px" />
                            <div class="delete" title="Supprimer image">
                                <span class="material-symbols-outlined">
                                    delete
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p-messages></p-messages>

            <mat-spinner *ngIf="loading"></mat-spinner>
            <button mat-raised-button (click)="onMemberSave()" color="primary" [disabled]="!formIsValid()">
                Enregistrer Membre
            </button>
        </mat-tab>
        <!-- INFOS COMPLEMENTAIRES -->
        <mat-tab label="Autres Infos">
            <h5>Infos Complémentaires</h5>
            <div class="details">
                <div style="margin-right: 50px">
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Niveau D'étude</mat-label>
                            <!-- Dropdown (voir autres exemples) -->
                            <mat-select name="daStudy" [(ngModel)]="selected_study_level_id">
                                <mat-option *ngFor="let level of levels"
                                    value="{{level.level}}">{{level.level}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Activité</mat-label>
                            <input name="daActivity" [(ngModel)]="model.activity" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- Activity -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Lieu D'activité</mat-label>
                            <input name="daActivityLocation" [(ngModel)]="model.activityLocation" matInput
                                maxlength="50" />
                        </mat-form-field>
                    </div>

                    <div>
                        <!-- Activity -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Source de revenu</mat-label>
                            <input name="daSourceOfIncome" [(ngModel)]="model.sourceOfIncome" matInput maxlength="50" />
                        </mat-form-field>
                    </div>

                    <div>
                        <!-- Activity -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Revenu mensuel</mat-label>
                            <input name="daSonthlyIncome" type="number" [(ngModel)]="model.monthlyIncome" matInput
                                maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- Activity -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Nb de personnes en charge</mat-label>
                            <input name="daPersonInCharge" type="number" [(ngModel)]="model.personInCharge" matInput
                                maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- Supply Location -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Lieu D'approvisionnement</mat-label>
                            <input name="daSupplyLocation" [(ngModel)]="model.supplyLocation" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- Other Activities -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Autres Activités</mat-label>
                            <input name="daOtherActivities" [(ngModel)]="model.otherActivity" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- Supplier -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Fournisseur</mat-label>
                            <input name="daFournisseur" [(ngModel)]="model.supplier" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- Experien DropDown (see other examples) -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Expériences</mat-label>
                            <input name="daExperience" matInput type="number" [(ngModel)]="model.experience" />
                        </mat-form-field>
                    </div>

                    <div>
                        <!-- Residence Type DropDown (see other examples) -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Type De Logement</mat-label>
                            <mat-select name="daResidenceType" [(ngModel)]="selected_residence_id">
                                <mat-option *ngFor="let residence of residences"
                                    value="{{residence.residence}}">{{residence.residence}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <div>
                        <!-- Client Type Dropdown (see other examples) -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Type De Client</mat-label>
                            <mat-select name="daTypeClient" [(ngModel)]="selected_customer_type_id">
                                <mat-option *ngFor="let customer_type of customerTypes"
                                    value="{{customer_type.customerType}}">{{customer_type.customerType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- Nbr Children Dropdown (see other examples) -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Nombre d'Enfants</mat-label>
                            <input name="daChildrenNumber" [(ngModel)]="model.childrenNumber" matInput type="number"
                                maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Nom Du Partenaire</mat-label>
                            <input name="daPatnerName" [(ngModel)]="model.partnerName" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Activité Du Partenaire</mat-label>
                            <input name="daPatnerActivity" [(ngModel)]="model.partnerActivity" matInput
                                maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>N° Téléphone du Partenaire</mat-label>
                            <input name="daPatenerPhone" [(ngModel)]="model.partnerPhone" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Mandataire</mat-label>
                            <input name="daMendator" [(ngModel)]="model.mandator" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>N° télépnone Mendataire</mat-label>
                            <input name="daMendatorPhone" [(ngModel)]="model.mandatorPhone" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Personne De Reference</mat-label>
                            <input name="daRefPerson" [(ngModel)]="model.referencePerson" matInput maxlength="50" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>N° Téléphone De Reference</mat-label>
                            <input name="daRefPersonPhone" [(ngModel)]="model.referencePerson_phone" matInput
                                maxlength="50" />
                        </mat-form-field>
                    </div>

                    <div>
                        <!-- Organisation Dropdown (see other examples) -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Organisation</mat-label>
                            <mat-select name="daOrganisation" [(ngModel)]="selected_organisation_id" >
                                <mat-option *ngFor="let organisation of organisations"
                                    value="{{organisation.organisation}}">{{organisation.organisation}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <!-- ??? je n'ai pas vu cette propriété... -->
                        <mat-form-field class="large" appearance="fill">
                            <mat-label>Sensibilisateur</mat-label>
                            <mat-select name="daSensibilisateur" [(ngModel)]="model.sensibilisator" >
                                <mat-option *ngFor="let agencyCollector of agencyCollectors"
                                    value="{{agencyCollector.lastname+' '+agencyCollector.firstname}}">{{agencyCollector.lastname+'
                                    '+agencyCollector.firstname}}</mat-option>
                            </mat-select>

                        </mat-form-field>
                    </div>
                    <p-messages></p-messages>

                    <button mat-raised-button color="primary" (click)="onComplete()">
                        Completer les infos
                    </button>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Comptes">
            <div style="display: inline-flex;">
                <h5 style="margin-right: 15px;">Informations Comptes</h5>
                <button mat-mini-fab color="primary" aria-label="icon button to add a branch" (click)="openDialog()">
                    <mat-icon>plus_one</mat-icon>
                </button>
            </div>

            <!-- ADD MEMBER -->

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput>
                </mat-form-field>
                <ng-container matColumnDef="membername">
                    <th mat-header-cell *matHeaderCellDef> Nom Membre </th>
                    <td mat-cell *matCellDef="let element"> {{element.lastname}} {{element.firstname}} </td>
                </ng-container>
                <ng-container matColumnDef="Gender">
                    <th mat-header-cell *matHeaderCellDef> Genre </th>
                    <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Téléphone</th>
                    <td mat-cell *matCellDef="let element"> {{element.phone1}} </td>
                </ng-container>
                <ng-container matColumnDef="agency">
                    <th mat-header-cell *matHeaderCellDef> Agence </th>
                    <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
                </ng-container>
                <ng-container matColumnDef="memberType">
                    <th mat-header-cell *matHeaderCellDef> Type de Membre </th>
                    <td mat-cell *matCellDef="let element"> {{element.sensibilisator}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </mat-tab>

    </mat-tab-group>
</form>