import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Transaction } from 'src/app/models/cartType.model';
import { ApiService } from 'src/app/services/api.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { combineLatest } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-eavg',
  templateUrl: './eavg.component.html',
  styleUrls: ['./eavg.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EavgComponent implements AfterViewInit, OnInit {
  loading = true
  dataSource = new MatTableDataSource()
  columnsToDisplay = ['agency','groupName', 'activityNature', 'activityAxis','sensibilisator'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null;
  currencies: []
  transactionTypes: []
  savings: []
  value1: number = 1;

  collectors: []
  isCollected:boolean
  selected_collector_id = ''
  model = new Transaction()

  constructor(private apiService: ApiService, private messageService: MessageService, private router: Router) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit(): void {
    this.fetchData()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  fetchData() {
    const currencies$ = this.apiService.currencies_getAll()
    const transactionTypes$ = this.apiService.transactionTypes_getAll()
    const eavgAccounts$ = this.apiService.getGroupsEavAccounts(this.apiService.getItem('username'))
    const collectors$ = this.apiService.getAgencySalesAgents(this.apiService.getItem('username'))

    combineLatest([eavgAccounts$, currencies$, transactionTypes$, collectors$]).subscribe(([eavgAccounts, currencies, transactionTypes, collectors]) => {
      this.dataSource.data = eavgAccounts
      this.currencies = currencies
      this.transactionTypes = transactionTypes
      this.collectors = collectors
      this.loading = false
    })
  }
  transaction_handle(issuer: number) {
    this.model.username = this.apiService.getItem('username')
    this.model.issuer = issuer
    this.model.userId = parseInt(this.selected_collector_id)
    if (this.model.currency && this.model.issuer && this.model.transactionAmount && this.model.transactionType && this.model.username && this.model.recipiendaire) {
      this.apiService.transaction_handle(this.model).subscribe((res) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Transaction réussie',
        });
        setTimeout(() => this.router.navigate(['eavg']), 2000);

      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Something went wrong',
        });
      })
    }
  }
}
export interface PeriodicElement {
  demandeur: string;
  date_demande: Date;
  montant: number;
  taux: number;
  echeance: number
  type_remboursement: string
  mode_remboursement: string
  decision: string
  option: string
}
