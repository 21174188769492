<h2 mat-dialog-title>Ajout compte DAT</h2>
<mat-spinner *ngIf="loading"></mat-spinner>

<mat-dialog-content class="mat-typography" *ngIf="!loading">
    <form class="example-form">

        <table class="example-full-width" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field>
                        <mat-label>Type de compte</mat-label>
                        <mat-select name="daAccountType" [(ngModel)]="model.accountType" required>
                            <mat-option *ngFor="let accountType of accountTypes"
                                value="{{accountType.accountType}}">{{accountType.accountType}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Propriétaire</mat-label>
                        <mat-select name="daCustomer" [(ngModel)]="selected_member_id" required>
                            <mat-option>
                                <ngx-mat-select-search (keyup)="filterAgencyCustomers($event.target.value)"  [placeholderLabel]="'Filtrer le propriétaire...'" [noEntriesFoundLabel]="'no matching member found'"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let agencycustomer of filteredAgencyCustomers"
                                value="{{agencycustomer.customerId}}">{{agencycustomer.lastname+'-'+agencycustomer.postname+'
                                '+agencycustomer.firstname}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="example-full-width" style="margin: 2px;">
                        <mat-label>Libellé</mat-label>
                        <input matInput name="daAccountLibelle" [(ngModel)]="model.accountLibelle" required>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width" style="margin-left: 3px;">
                        <mat-label>Agence</mat-label>
                        <mat-select name="daAgency" [(ngModel)]="userAgency.agencyId" required>
                            <mat-option [value]="userAgency.agencyId">{{userAgency.agencyName}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Indice</mat-label>
                        <input matInput name="daAccountIndice" [(ngModel)]="model.accountIndice" required>
                    </mat-form-field>

                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Dévise</mat-label>
                        <mat-select name="daCurrency" [(ngModel)]="model.currency" required>
                            <mat-option *ngFor="let currency of currencies" value="{{currency.currency}}">{{currency.currency}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td><mat-form-field class="example-full-width">
                        <mat-label>Taux d'intérêt</mat-label>
                        <input matInput type="number" name="daInterestRate" [(ngModel)]="model.interestRate">
                    </mat-form-field></td>
                <td><mat-form-field class="example-full-width">
                        <mat-label>Echéance</mat-label>
                        <input matInput type="number" name="daPeriod" [(ngModel)]="model.period" required>
                    </mat-form-field></td>

            </tr>
        </table>
    </form>

</mat-dialog-content>
<p-messages></p-messages>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-raised-button color="primary" (click)="onDatCreation()">
        Ajouter le compte
    </button>
</mat-dialog-actions>