import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Delestage } from 'src/app/models/delestage.model';
import { Account } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-coffre',
  templateUrl: './coffre.component.html',
  styleUrls: ['./coffre.component.css']
})
export class CoffreComponent implements AfterViewInit, OnInit {
  displayedColumns = ['transactionId', 'transaction_type', 'source_account', 'amount', 'receiver', 'date_created']
  dataSource = new MatTableDataSource();
  isLoadingResults = false

  coffreAccounts: Account[]
  cashAccounts: Account[]
  isDelestage: boolean
  isApprobation: boolean

  pendingDelestages: Delestage[]
  delestage = new Delestage()
  sourceAccount: Account
  destinationAccount: Account



  constructor(private _liveAnnouncer: LiveAnnouncer, private apiService: ApiService, private messageService: MessageService, private router: Router) { }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.fetchData()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  fetchData() {

    const managerTransactions$ = this.apiService.userTransactions_getAll(this.apiService.getItem('username'))
    const coffreAccounts$ = this.apiService.getAgencyCoffreAccounts(this.apiService.getItem('username'))
    const cashAcounts$ = this.apiService.getAgencyCashAccounts(this.apiService.getItem('username'))
    const pendingDelestages$ = this.apiService.pendingDelestages_get(this.apiService.getItem('username'))

    combineLatest([managerTransactions$, coffreAccounts$, cashAcounts$, pendingDelestages$]).subscribe(([managerTransactions, coffreAccounts, cashAccounts, pendingDelestages]) => {
      this.dataSource.data = managerTransactions
      this.coffreAccounts = coffreAccounts
      this.cashAccounts = cashAccounts
      this.pendingDelestages = pendingDelestages
      console.log(managerTransactions)
    })
  }
  createAccountDelestages() {
    this.delestage.username = this.apiService.getItem("username")
    if (this.sourceAccount.currency === this.destinationAccount.currency) {
      this.delestage.sourceAccountId = this.sourceAccount.accountId
      this.delestage.destinationAccountId = this.destinationAccount.accountId
      this.apiService.createAccountDelestages(this.delestage).subscribe((res) => {
        this.messageService.add({
          severity: "success",
          summary: "Délestage exécuté avec succès. Merci de suivre son approbation"
        })
        setTimeout(() => this.router.navigate(['coffre']), 2000);

      }, () => {
        this.messageService.add({
          severity: "error",
          summary: "Oups! something went wrong."
        })
      })
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Merci de lélester les comptes d'une même dévise"
      })
    }
  }

  delestageValidate() {
    if (this.delestage) {
      this.delestage.username = this.apiService.getItem('username')
      this.apiService.delestageValidate(this.delestage).subscribe((res) => {
        this.apiService.createAccountDelestages(this.delestage).subscribe((res) => {
          this.messageService.add({
            severity: "success",
            summary: "Approuvé avec succès"
          })
          setTimeout(() => this.router.navigate(['cashier']), 2000);

        }, () => {
          this.messageService.add({
            severity: "error",
            summary: "Huum! something went wrong."
          })
        })
      })
    }
  }
}
