import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import config from '../../../../auth_config.json'
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { CartDialogComponent } from 'src/app/components/cart-dialog/cart-dialog.component';
import { CartType, CollectType } from 'src/app/models/cartType.model';
import { Customer } from 'src/app/models/customer.model';
import { CartCollect } from 'src/app/models/transactions.model';
import { Agency } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-carts',
  templateUrl: './carts.component.html',
  styleUrls: ['./carts.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CartsComponent implements AfterViewInit, OnInit {
  loading = true
  dataSource = new MatTableDataSource()
  columnsToDisplay = ['agency', 'membername', 'Gender', 'cartType', 'cartMise', 'cartAmount', 'currency', 'collector'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: CartElements | null;
  agencyCollectors: []
  collectTypes: CollectType[]
  model = new CartCollect()
  transactionType = ''
  transactionAmount = ''
  username = ''
  selected_collector_id = ''
  API_URI = `${config.apiUri}/api/getFile/`

  constructor(public dialog: MatDialog, private apiService: ApiService, private messageService: MessageService, private router: Router) {
    this.username = apiService.getItem('username')
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.fetchData()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  openDialog() {
    const dialogRef = this.dialog.open(CartDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  fetchData() {
    const carts$ = this.apiService.getAgencyCarts(this.apiService.getItem('username'))
    const agencyCollectors$ = this.apiService.agencyCollectors_getAll(this.apiService.getItem('username'))
    const collectType$ = this.apiService.getAllCollectTypes()
    combineLatest([carts$, agencyCollectors$, collectType$]).subscribe(([carts, agencyCollectors, collectTypes]) => {
      this.dataSource.data = carts
      this.agencyCollectors = agencyCollectors
      this.collectTypes = collectTypes
      this.loading = false
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    this.dataSource.filterPredicate = (data: CartElements, filter: string) => {
      return (
        (data.customer?.lastname || '').toLowerCase().includes(filter) ||
        (data.customer?.firstname || '').toLowerCase().includes(filter) ||
        (data.user?.firstname || '').toLowerCase().includes(filter) ||
        (data.user?.lastname || '').toLowerCase().includes(filter) ||
        data.cartType.cartType.toLowerCase().includes(filter)
      );
    };

    this.dataSource.filter = filterValue;
  }

  onCartCollect(cartId: number, currency: string) {
    this.model.username = this.username
    this.model.cartId = cartId
    this.model.userId = parseInt(this.selected_collector_id)
    this.model.currency = currency
    this.apiService.saveMemberCart(this.model).subscribe((res) => {
      this.messageService.add({
        severity: 'success',
        summary: res.message,
      });
      setTimeout(() => this.router.navigate(['carts']), 1000);

    })
  }
}

export interface CartElements {
  customer: Customer
  cartType: CartType
  cartMise: number
  currency: string
  user: { username: string, firstname: string, lastname: string }
  agency: Agency
}

