<h2><span>Transactions</span> <button mat-mini-fab color="primary" aria-label="icon button to add a transaction"
        title="Ajouter un décaissement" (click)="openDialog()">
        <mat-icon>plus_one</mat-icon>
    </button></h2>

<!-- MESSAGES -->
<div class="caisse">
    <!-- CDF BALANCE -->
    <div class="panel">
        <mat-form-field class="large" appearance="fill">
            <mat-label>Compte source</mat-label>
            <mat-select name="daType" required (selectionChange)="issuerAccount_get()" [(ngModel)]="issuerAccount_id">
                <mat-option>
                    <ngx-mat-select-search (keyup)="filterIssuerAccounts($event.target.value)"
                        [placeholderLabel]="'Filtrer le compte source..'"
                        [noEntriesFoundLabel]="'no matching account found'"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let account of filteredIssuerAccounts"
                    [value]="account.accountId">{{account.currency}}
                    : {{
                    account.customer?.lastname||account.customer?.firstname ||account.customer?.postname||
                    account.customerGroup?.customerGroupName || account?.accountLibelle
                    }}-{{account?.agency?.agencyName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="issuerAccount" class="card">
            <p>Balance :
                <span>{{issuerAccount.currency=='CDF'?(issuerAccount?.accountSold|currency:'CDF'):issuerAccount?.accountSold|currency}}</span>
            </p>
        </div>
        <div class="bank-notes">
            <mat-form-field appearance="fill">
                <mat-label>Montant Transaction</mat-label>
                <input type="number" name="amount" matInput [(ngModel)]="model.transactionAmount" required>
            </mat-form-field>
        </div>
    </div>

    <!-- USD BALANCE -->
    <div class="panel">
        <mat-form-field class="large" appearance="fill">
            <mat-label>Compte de destination</mat-label>
            <mat-select name="daType" required (selectionChange)="receiverAccount_get()"
                [(ngModel)]="receiverAccount_id">
                <mat-option>
                    <ngx-mat-select-search (keyup)="filterReceiverAccounts($event.target.value)"
                        [placeholderLabel]="'Filtrer le compte de destination...'"
                        [noEntriesFoundLabel]="'no matching account found'"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let account of filteredReceiverAccounts"
                    [value]="account.accountId">{{account.currency}}
                    : {{
                    account.customer?.lastname||account.customer?.firstname ||account.customer?.postname ||
                    account.customerGroup?.customerGroupName || account?.accountLibelle
                    }}-{{account?.agency?.agencyName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="receiverAccount" class="card">
            <p>Balance :
                <span>{{receiverAccount.currency=='CDF'?(receiverAccount?.accountSold|currency:'CDF'):receiverAccount?.accountSold|currency}}</span>
            </p>
        </div>
        <div>
            <mat-form-field class="large" appearance="fill">
                <mat-label>Communication</mat-label>
                <textarea matInput name="daInfo" [(ngModel)]="model.communication"></textarea>
            </mat-form-field>

        </div>

    </div>

    <div class="panel big">
        <div>
            <h5>Type de transaction</h5>
            <!-- DROPDOWN CURRENCY -->
            <mat-form-field class="large" appearance="fill">
                <mat-label>Type de transaction</mat-label>
                <mat-select name="daType" required [(ngModel)]="model.transactionType">
                    <mat-option *ngFor="let type of transactionTypes"
                        value="{{type.transactionType}}">{{type.transactionType}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Nom du bénéficiaire</mat-label>
                <input name="beneficiaire" matInput [(ngModel)]="model.beneficiary" required>
            </mat-form-field>

        </div>

        <div class="transactions-container">
            <p-messages></p-messages>
            <button mat-raised-button color="primary" (click)="onSubmit()">Valider</button>

            <div class="example-loading-shade">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                <div class="example-rate-limit-reached">

                </div>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
            class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="transactionId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                    ID Transaction
                </th>
                <td mat-cell *matCellDef="let element"> {{element.transactionId}} </td>

            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="transactionType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                    Type Transaction
                </th>
                <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>

            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="transactionAmount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                    Montant
                </th>
                <td mat-cell *matCellDef="let element"> {{element.transactionAmount}} </td>

            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="currency">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                    Dévise
                </th>
                <td mat-cell *matCellDef="let element"> {{element.currency}} </td>

            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="issuer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                    Compte Source
                </th>
                <td mat-cell *matCellDef="let element"> {{element.issuerAccount?.accountLibelle}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="receiver">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                    Compte Destination
                </th>
                <td mat-cell *matCellDef="let element"> {{element.receiverAccount?.accountLibelle}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
                    Date Transaction
                </th>
                <td mat-cell *matCellDef="let element"> {{element.createdDate | date}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="num_cart">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                    Numero Carte
                </th>
                <td mat-cell *matCellDef="let element"> {{element.cardId}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[11, 20, 100]" aria-label="Select page of users"></mat-paginator>