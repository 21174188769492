<div class="mat-elevation-z4">
    <h4 class="title"><span class="material-symbols-outlined">
            arrow_back_ios </span>{{title}}</h4>
    <mat-card style="display: flex; flex-flow: row wrap;justify-content:flex-start;">
        <!-- <div style="padding: 5px;">
            <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>Shiba Inu</mat-card-title>
                <mat-card-subtitle>Dog Breed</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-card-title>Genre : <label>The Shiba Inu is</label></mat-card-title>
                <mat-card-title>Téléphone :<label>0852299974</label></mat-card-title>
                <mat-card-title>Adresse : <label>The Shiba Inu is</label></mat-card-title>
                <mat-card-title>Numero compte CDF : <label>0852299974</label></mat-card-title>
                <mat-card-title>Numero compte USD :<label>T0852299974</label></mat-card-title>

            </mat-card-content>

        </div> -->
        <div class="card">
            <p-table [value]="products" [tableStyle]="{ 'width': '43rem' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Nom & Postnom</th>
                        <th>Genre</th>
                        <th>Téléphone</th>
                        <th>Montant</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>{{ product.customer_name }}</td>
                        <td>{{ product.gender }}</td>
                        <td>{{ product.phone }}</td>
                        <td> <input type="text" class="p-inputtext-sm input" pInputText placeholder="Echéance en mois"
                                value="{{ product.amount }}" /></td>
                    </tr>
                </ng-template>

            </p-table>
        </div>

        <mat-stepper orientation="vertical" #stepper class="stepper">
            <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>De quel type de crédit s'agi-til ?</ng-template>
                    <mat-form-field>
                        <mat-label>Type de crédit</mat-label>
                        <mat-select formControlName="firstCtrl">
                            <mat-option *ngFor="let loan_type of loanTypes" value="{{loan_type.loanTypeId}}">{{loan_type.loanType}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup">
                <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Combien a-t-il demandé?</ng-template>
                    <mat-form-field>
                        <mat-label>Montant</mat-label>
                        <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" required>
                    </mat-form-field>
                    <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup">
                <form [formGroup]="thirdFormGroup">
                    <ng-template matStepLabel>Dans quelle devise?</ng-template>
                    <mat-form-field>
                        <mat-label>Devise</mat-label>
                        <mat-select formControlName="thirdCtrl">
                            <mat-option value="one">First option</mat-option>
                            <mat-option value="two">Second option</mat-option>
                        </mat-select> </mat-form-field>
                    <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="fourthFormGroup">
                <form [formGroup]="fourthFormGroup">
                    <ng-template matStepLabel>Au taux de combien?</ng-template>
                    <mat-form-field>
                        <mat-label>Taux d'intérêt</mat-label>
                        <input matInput formControlName="fourthCtrl" placeholder="Ex. 1 Main St, New York, NY" required>
                    </mat-form-field>
                    <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="fithFormGroup">
                <form [formGroup]="fithFormGroup">
                    <ng-template matStepLabel>Comment va-t-il rembourser?</ng-template>
                    <mat-form-field>
                        <mat-label>Mode de remboursement</mat-label>
                        <mat-select formControlName="fithCtrl">
                            <mat-option value="one">First option</mat-option>
                            <mat-option value="two">Second option</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="sixthFormGroup">
                <form [formGroup]="sixthFormGroup">
                    <ng-template matStepLabel>Choisir l'agence </ng-template>
                    <mat-form-field>
                        <mat-label>Agence</mat-label>
                        <mat-select formControlName="sixthCtrl">
                            <mat-option value="one">First option</mat-option>
                            <mat-option value="two">Second option</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <p>Vous êtes à la fin</p>
                <div>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button color="primary" (click)="stepper.reset()">Envoyer la demande</button>
                </div>
            </mat-step>
        </mat-stepper>
    </mat-card>
</div>