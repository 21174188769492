import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { DischargeComponent } from 'src/app/components/discharge/discharge.component';
import { Transactions } from 'src/app/models/aggregate';
import { Account } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  isLoadingResults = false
  displayedColumns: string[] = ['transactionId', 'transactionType', 'transactionAmount', 'currency', 'issuer', 'receiver', 'createdDate', 'num_cart'];
  dataSource = new MatTableDataSource();

  issuerAccount_id = ''
  issuerAccount: Account
  receiverAccount_id = ''
  receiverAccount: Account
  transactionTypes: []
  userTransactions: []
  allAccounts: Account[]

  filteredIssuerAccounts: Account[]
  filteredReceiverAccounts: Account[]

  model = new Transactions()

  constructor(private _liveAnnouncer: LiveAnnouncer, private apiService: ApiService, private messageService: MessageService, public dialog: MatDialog, private router: Router) { }
  ngOnInit(): void {
    this.fetchData()

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  fetchData() {
    const allAccounts$ = this.apiService.accounts_getAll()
    const transactionTypes$ = this.apiService.transactionTypes_getAll()
    const userTransactions$ = this.apiService.userTransactions_getAll(this.apiService.getItem('username'))
    combineLatest([allAccounts$, transactionTypes$, userTransactions$]).subscribe(([allAccounts, transactionTypes, userTransactions]) => {
      this.allAccounts = allAccounts
      this.transactionTypes = transactionTypes
      this.dataSource.data = userTransactions
      console.log(userTransactions)

    })
  }
  onSubmit() {

    if (this.issuerAccount.currency === this.receiverAccount.currency) {
      if (this.model.transactionAmount && this.model.transactionType && this.issuerAccount_id && this.receiverAccount_id) {
        this.isLoadingResults = true
        this.model.issuer = parseInt(this.issuerAccount_id)
        this.model.receiver = parseInt(this.receiverAccount_id)
        this.model.currency = this.issuerAccount.currency
        this.model.username = this.apiService.getItem('username')

        this.apiService.transaction_commit(this.model).subscribe((res) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Transaction reussie'
          })
          this.model.currency = ''
          this.model.issuer = null
          this.model.receiver = null
          this.model.transactionAmount = 0
          this.model.transactionType = ''
          this.isLoadingResults = false
          this.issuerAccount = null
          this.receiverAccount = null
          this.allAccounts = []
          setTimeout(() => this.router.navigate(['transactions']), 2000);

        }, () => {
          this.messageService.add({
            severity: 'error',
            summary: `Oups, Balance insuffisante pour le compte  ${this.issuerAccount.accountLibelle}`
          })
          this.isLoadingResults = false

        })
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Merci de remplir tous les champs requis'
        })
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Les deux comptes doivent avoir la même dévise'
      })
      this.isLoadingResults = false
    }
  }
  issuerAccount_get() {
    const issuerAccount_id = parseInt(this.issuerAccount_id)
    this.apiService.sourceAccounts_get(issuerAccount_id).subscribe((issuer) => {
      this.issuerAccount = issuer
    })
  }
  receiverAccount_get() {
    const receiverAccount_id = parseInt(this.receiverAccount_id)
    this.apiService.sourceAccounts_get(receiverAccount_id).subscribe((receiver) => {
      this.receiverAccount = receiver
    })
  }
  openDialog() {
    const dialogRef = this.dialog.open(DischargeComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  filterIssuerAccounts(searchTerm: string) {
    this.filteredIssuerAccounts = this.allAccounts.filter(account => {
      let search = searchTerm.toLowerCase();
      let accountInfo = account.currency + ': ' +
        (account.customer?.lastname || account.customerGroup?.customerGroupName || account?.accountLibelle);
      return accountInfo.toLowerCase().includes(search);
    });
  }
  filterReceiverAccounts(searchTerm: string) {
    this.filteredReceiverAccounts = this.allAccounts.filter(account => {
      let search = searchTerm.toLowerCase();
      let accountInfo = account.currency + ': ' +
        (account.customer?.lastname || account.customerGroup?.customerGroupName || account?.accountLibelle);
      return accountInfo.toLowerCase().includes(search);
    });
  }

}
