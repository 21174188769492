import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Cart, CartType, Collector, Currency, Customer } from 'src/app/models/cartType.model';
import { Agency } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cart-dialog',
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.css']
})
export class CartDialogComponent implements OnInit {
  cartTypes: CartType[]
  collectors: Collector[]
  members: Customer[]
  agency: Agency
  currencies: Currency[]

  cartModel = new Cart()

  selected_member = ''
  selected_cartType = ''
  selected_currency = ''
  selected_collector = ''
  cartMise: number
  loading = true
  filteredMembers = []

  constructor(private apiService: ApiService, private messageService: MessageService) { }
  ngOnInit(): void {
    this.fetchData()
    this.filteredMembers = this.members;
  }

  onSubmit() {
    this.cartModel.customerId = parseInt(this.selected_member)
    this.cartModel.cartTypeId = parseInt(this.selected_cartType)
    this.cartModel.agencyId = this.agency.agencyId

    this.cartModel.userId = parseInt(this.selected_collector)
    this.apiService.newCart_save(this.cartModel).subscribe((res) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Operation successful',
      });

      // Clear form data
      this.selected_member = '';
      this.selected_cartType = '';
      this.cartMise = 0;
      this.cartModel.currency = ''
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: error.mesage })
    })
  }
  formIsValid() {
    let success = false
    success = this.selected_cartType != '' &&
      this.selected_member != '' &&
      this.cartModel.currency != undefined &&
      this.cartModel.cartMise != undefined

    return success

  }
  fetchData() {
    const cartTypes$ = this.apiService.cartTypes_getAll()
    const collectors$ = this.apiService.agencyCollectors_getAll(this.apiService.getItem('username'))
    const members$ = this.apiService.getAllAgencyMembers(this.apiService.getItem('username'))
    const currencies$ = this.apiService.currencies_getAll()
    const collectorAgency$ = this.apiService.getUserAgency(this.apiService.getItem('username'))
    combineLatest([cartTypes$, collectors$, members$, currencies$, collectorAgency$]).subscribe(([cartTypes, collectors, members, currencies, collectorAgency]) => {
      this.cartTypes = cartTypes
      this.collectors = collectors
      this.members = members
      this.agency = collectorAgency
      this.currencies = currencies
      this.loading = false
    })

  }
  onKey(value: string) {
    this.filteredMembers = this.search(value);
  }

  search(value: string): Customer[] {
    return this.members.filter(member =>
      member.lastname.toLowerCase().includes(value.toLowerCase()) ||
      member.postname.toLowerCase().includes(value.toLowerCase()) ||
      member.firstname.toLowerCase().includes(value.toLowerCase())
    );
  }

}
