import { Component, Inject, Input } from '@angular/core';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { AuthScopeService } from 'src/app/services/auth-permissions.service';



@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent {
  isCollapsed = true;
  loading: boolean
  faUser = faUser;
  faPowerOff = faPowerOff;
  active$: Observable<boolean>
  permissions: { [key: string]: boolean } = {};
  user_permissions:any

  @Input() username: string
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    public auth: AuthService,
    private router: Router,
    public daAuth: AuthScopeService,
    public apiService: ApiService,

  ) {
    this.loading = true
    if (this.auth.isAuthenticated$) {
      this.auth.user$.subscribe((profile) => {

        if (profile) {
          this.apiService.setItem('username', profile.email)
        } else {
          this.loading = false
        }

      })
    }

  }

  async ngOnInit() {
    console.log('INIT NAVBAR')
  }


  loginWithRedirect() {
    this.auth.loginWithRedirect();

  }

  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
  goToHere(where) {
    this.router?.navigate(where)
  }
}
