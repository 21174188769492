import { Component, OnInit } from '@angular/core';
import { CustomerGroup } from 'src/app/models/group.model';
import { Agency } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-group-dialog',
  templateUrl: './group-dialog.component.html',
  styleUrls: ['./group-dialog.component.css']
})
export class GroupDialogComponent implements OnInit {
  loading = true
  model: CustomerGroup = new CustomerGroup()
  userAgency: Agency
  managers: []

  constructor(private readonly apiService: ApiService, private router: Router, private messageService: MessageService) { }
  ngOnInit(): void {
    this.fetchData()
  }
  formIsValid() {
    let success = false
    success =
      this.model.customerGroupName &&
      this.model.activityAxis &&
      this.model.customerGroupManager &&
      this.model.activityNature &&
      Number.isNaN(this.model.agencyId) == false
    return success
  }
  onGroupSave() {
    this.model.agencyId = this.userAgency.agencyId
    if (this.model) {
      this.apiService.groups_save(this.model).subscribe((res) => {
        if (res.message == true) {
          this.messageService.add({
            severity: 'success',
            summary: "Nouveau groupe créé"
          })
          setTimeout(() => this.router.navigate(['groups']), 2000);
        } else {
          this.messageService.add({
            severity: 'error',
            summary: "Ce groupe existe déjà"
          })
        }

      })
    }
  }
  fetchData() {
    const userAgency$ = this.apiService.getUserAgency(this.apiService.getItem('username'))
    const managers$ = this.apiService.getAgencyLoanManagers(this.apiService.getItem('username'))
    combineLatest([userAgency$, managers$]).subscribe(([userAgency, managers]) => {
      this.userAgency = userAgency
      this.managers = managers
      this.loading = false
    })
  }
}
