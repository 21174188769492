import { Customer } from "./customer.model"
import { CustomerGroup } from "./group.model"

export class UserAccount {
    userId: number
    username: string = ''
    firstname: string
    lastname: string
    roleId: number
    birthDate: Date
    agency_id: number
    imageUrl: string
    active: boolean
}
export class UserCollect {
    collectorId: number
    collectDate: string
}
export class Account {
    accountId: number
    accountIndice: string
    currency: string
    accountType: string
    accountSold: number
    agencyId: number
    accountLibelle: string
    username: string
    createdDate: Date
    updatedDate: Date
    customer: Customer
    customerGroup: CustomerGroup
    agency: Agency
    user:UserAccount

}


export class Agency {
    agencyId: number
    agencyName: string
    agencyAdress: string
    agencyDescription: string
    cityId: number
    institutionId: number
    accounts:Account[]

    constructor(values: Agency = undefined) {
        if (values) {
            this.agencyId = values.agencyId
            this.agencyName = values.agencyName
            this.agencyAdress = values.agencyAdress
            this.agencyDescription = values.agencyDescription
            this.cityId = values.cityId
            this.institutionId = values.institutionId
        }
    }
}
export class FileType {
    fileTypeId: number
    fileType: string
}
export class Gender_options {
    genderId: number
    gender: string
}
export class City {
    cityId: number
    cityName: string
    cityZip: string
}
export class CustomerFiles {
    id: number
    category: string
    imageUrl: string
}
export class Residence {
    residenceId: number
    residence: string
}
export class Organisation {
    organisationId: number
    organisation: string
}
export class StudyLevel {
    levelId: number
    level: string
}
export class CustomerType {
    customerTypeId: number
    customerType: string
}
