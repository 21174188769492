import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Account } from 'src/app/models/account.model';
import { LoanRequest } from 'src/app/models/loanRequest.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-approbation',
  templateUrl: './approbation.component.html',
  styleUrls: ['./approbation.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ApprobationComponent implements AfterViewInit, OnInit {
  dataSource = new MatTableDataSource();
  columnsToDisplay = ['membername', 'agency', 'requestedAmount', 'currency', 'interestRate', 'period', 'decision', 'createdDate'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  rates: { value: string; label: string }[] = [
    { value: 'Rate1', label: 'Rate 1' },
  ];
  selectedRate: string = this.rates[0].value;

  modes: { value: string; label: string }[] = [
    { value: 'Mode1', label: 'Mode 1' },
  ];
  selectedMode: string = this.modes[0].value

  types: { value: string; label: string }[] = [
    { value: 'Type1', label: 'Type 1' },
  ];
  selectedType: string = this.types[0].value

  readonly CUSTOMER_EAV_ACCOUNT_TYPE = "Compte EAV individuel"
  readonly GROUP_EAV_ACCOUNT_TYPE = "Compte EAV de groupe"

  reimbursementModes: []
  reimbursementTypes: []
  rate_options: []
  currencyRate: number
  loading = true
  unApprovedRequest: LoanRequest
  customerAccounts: Account[]

  constructor(private apiService: ApiService, private messageService: MessageService, private router: Router) {
    this.currencyRate = parseFloat(this.apiService.getItem('currencyRate'))

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.fetchData()
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  fetchData() {
    const rateOptions$ = this.apiService.rateOptios_getAll()
    const unApprovedRequestData$ = this.apiService.getUnapprovedRequests()
    const reimbursementModes$ = this.apiService.reimbursementModes_getAll()
    const reimbursementTypes$ = this.apiService.reimbursementTypes_getAll()

    combineLatest([rateOptions$, unApprovedRequestData$, reimbursementModes$, reimbursementTypes$]).subscribe(([rateOptions, unApprovedRequestData, reimbursementModes, reimbursementTypes]) => {
      this.rate_options = rateOptions
      this.dataSource.data = unApprovedRequestData
      console.log(unApprovedRequestData)
      this.reimbursementModes = reimbursementModes
      this.reimbursementTypes = reimbursementTypes

      this.loading = false
    })

  }
  onApprove(approbationData: LoanRequest) {
    approbationData.currencyRate = this.currencyRate
    if (this.selectedRate !== 'Rate1') approbationData.interestRate = parseFloat(this.selectedRate)
    if (this.selectedMode !== 'Mode1') approbationData.reimbursementMode = this.selectedMode
    if (this.selectedType !== 'Type1') approbationData.reimbursementType = this.selectedType
    if (approbationData.customer) {
      this.apiService.customerRequest_approve(approbationData).subscribe((res) => {
        console.log(res)
        this.messageService.add({
          severity: 'success',
          summary: 'Aprobation réussie'
        })
        setTimeout(() => this.router.navigate(['approbation']), 2000);

      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: "Balance insuffisante pour cette opération"
        })
        console.log(error)
      })
    } else if (approbationData.customerGroup) {
      const totalCreditAmount = approbationData.customerGroup.members.reduce((acc, member) => acc + member.creditAmount, 0);
      if (approbationData.requestedAmount == totalCreditAmount) {
        this.apiService.groupRequest_approve(approbationData).subscribe((res) => {
          this.messageService.add({
            severity: 'info',
            summary: res.message
          })
          setTimeout(() => this.router.navigate(['approbation']), 2000)
        })
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Merci d\ajuster le montant demandé entre les membres'
        })
      }

    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Vous tentez de valider une demande erronée'
      })
    }
  }
}
export interface PeriodicElement {
  membername: string;
  agency: string;
  requestedAmount: number;
  currency: string;
  interestRate: number;
  decision: string;
  requestStaus: string

}
