<H2>Configuration</H2>
<form style="overflow: hidden!important;">

    <mat-tab-group style="overflow: hidden!important;">

        <mat-tab label="Institution">

            <div style="height: 350px;position: relative;">
                <mat-spinner *ngIf="loading" class="spinner-configuration"></mat-spinner>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Name</mat-label>
                        <input name="daName" matInput [(ngModel)]="model.institutionName" required maxlength="50">
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="fill" class="extra-large">
                        <mat-label>Description</mat-label>
                        <textarea name="daDesc" matInput [(ngModel)]="model.description"></textarea>
                    </mat-form-field>
                </div>

                <!-- STREET -->
                <div>
                    <mat-form-field class="large" appearance="fill">
                        <mat-label>adresse</mat-label>
                        <input name="daStreet" matInput [(ngModel)]="model.adress" required>
                    </mat-form-field>

                    <mat-form-field class="large" appearance="fill">
                        <mat-label>Choisir le pays</mat-label>
                        <mat-select name="daCountry" [(ngModel)]="model.country.countryId" required>
                            <mat-option [value]="model.country.countryId">{{model.country.countryName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <p-calendar name="daDate" [(ngModel)]="model.createdDate" dateFormat="dd/mm/yy"></p-calendar>
                </div>

            </div>
            <div>
                <button mat-raised-button color="primary" (click)="onSubmit()">Sauvegarder</button>
            </div>

        </mat-tab>
        <mat-tab label="Agence"> Detail Agence
            <div style="display:relative">
                <mat-spinner *ngIf="loading" class="spinner"></mat-spinner>
                <mat-form-field>
                    <mat-label>Choose Institution</mat-label>
                    <mat-select name="daInstitution" [(ngModel)]="model.institutionId">
                        <mat-option></mat-option>
                        <mat-option [value]="model.institutionId">{{ model.institutionName }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Select City</mat-label>
                    <mat-select name="daBranchCity" [(ngModel)]="selected_city_id" required>
                        <mat-option *ngFor="let city of cities" value="{{city.cityId}}">{{city.cityName}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div style="overflow: hidden;">
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Nom agence</mat-label>
                        <input name="daBranchName" [(ngModel)]="agency.agencyName" matInput required maxlength="50">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="large" appearance="fill">
                        <mat-label>adresse</mat-label>
                        <input name="daBranchStreet" [(ngModel)]="agency.agencyAdress" matInput required>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="fill" class="extra-large">
                        <mat-label>Description</mat-label>
                        <textarea name="daBrancheDesc" [(ngModel)]="agency.agencyDescription" matInput></textarea>
                    </mat-form-field>
                </div>

                <button mat-mini-fab color="primary" aria-label="icon button to add a branch" (click)="onAgencySave()">
                    <mat-icon>plus_one</mat-icon>
                </button>

            </div>
        </mat-tab>
        <mat-tab label="Compte"> Detail du Compte
            <div style="display:relative">
                <mat-spinner *ngIf="loading" class="spinner"></mat-spinner>
                <div>
                    <mat-form-field class="extra-large" appearance="fill">
                        <mat-label>Selectionner Agence</mat-label>
                        <mat-select name="daCurrentAgency" [(ngModel)]="selected_agency_id" required>
                            <mat-option *ngFor="let ag of agencies"
                                value="{{ag.agencyId}}">{{ag.agencyName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-form-field class="large" appearance="fill">
                    <mat-label>Option Compte</mat-label>
                    <mat-select name="daAccountOption" (selectionChange)="toggleUsers()"
                        [(ngModel)]="cashAccount.accountOption" required>
                        <mat-option *ngFor="let ao of accountOptions"
                            value="{{ao.accountOption}}">{{ao.accountOption}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="large" appearance="fill" *ngIf="isToggled">
                    <mat-label>Attacher à</mat-label>
                    <mat-select name="daUsers" [(ngModel)]="selected_userId">
                        <mat-option *ngFor="let user of users" value="{{user.userId}}">{{user.firstname+'
                            '+user.lastname}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div style="overflow: hidden;">
                <mat-form-field class="large" appearance="fill">
                    <mat-label>Type de compte</mat-label>
                    <mat-select name="daAccountType" [(ngModel)]="accountType.accountType" required>
                        <mat-option 
                            [value]="accountType.accountType">{{accountType.accountType}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="large" appearance="fill">
                    <mat-label>Dévise</mat-label>
                    <mat-select name="daDevise" [(ngModel)]="cashAccount.currency" required>
                        <mat-option *ngFor="let c of currencies" value="{{c.currency}}">{{c.currency}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Indice du compte</mat-label>
                        <input matInput name="daIndice" [(ngModel)]="cashAccount.accountIndice" required>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>Libellé du compte</mat-label>
                        <input matInput name="daLibelle" [(ngModel)]="cashAccount.accountLibelle">
                    </mat-form-field>

                </div>

                <div>
                    <p-messages></p-messages>

                    <button mat-mini-fab color="primary" aria-label="icon button to add a branch"
                        (click)="onAccountCreation()">
                        <mat-icon>plus_one</mat-icon>
                    </button>
                </div>

            </div>
        </mat-tab>

    </mat-tab-group>
</form>