<div class="mat-elevation-z8">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <table mat-table [dataSource]="dataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="cartOwner">
            <th mat-header-cell *matHeaderCellDef> Propriétaire. </th>
            <td mat-cell *matCellDef="let element"> {{element.customer?.lastname+' '+element.customer?.firstname}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="collectDate">
            <th mat-header-cell *matHeaderCellDef> Date Collecte. </th>
            <td mat-cell *matCellDef="let element"> {{element.collectDate| date:'dd-MM-yyyy'}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="collectedAmount">
            <th mat-header-cell *matHeaderCellDef> Montant Collecté. </th>
            <td mat-cell *matCellDef="let element"> {{element.transactionAmount}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="collectStatus">
            <th mat-header-cell *matHeaderCellDef> Status Collecte. </th>
            <td mat-cell *matCellDef="let element"> {{element.transactionStatus}} </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="transactor">
            <th mat-header-cell *matHeaderCellDef> Gestionnaire </th>
            <td mat-cell *matCellDef="let element"> {{element.user.lastname+' '+element.user.firstname}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>