import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { AccountType, DatAccout } from 'src/app/models/account.model';
import { Customer } from 'src/app/models/customer.model';
import { Agency } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.css']
})
export class AccountDialogComponent implements OnInit {
  loading=true
  userAgency: Agency
  accountTypes: AccountType
  agencyCustomers: Customer[]
  filteredAgencyCustomers: Customer[]
  selected_member_id = ''
  selected_accountType_id = ''

  model = new DatAccout()
  currencies: []

  constructor(private apiService: ApiService, private messageService: MessageService) { }
  ngOnInit(): void {
    this.fetchData()
  }
  fetchData() {
    const accountTypes$ = this.apiService.accountType_getAll()
    const agencyCustomers$ = this.apiService.getAllAgencyMembers(this.apiService.getItem('username'))
    const userAgency$ = this.apiService.getUserAgency(this.apiService.getItem('username'))
    const currencies$ = this.apiService.currencies_getAll()
    combineLatest([accountTypes$, agencyCustomers$, userAgency$, currencies$]).subscribe(([accountTypes, agencyCustomers, userAgency, currencies]) => {
      this.accountTypes = accountTypes
      this.agencyCustomers = agencyCustomers
      this.userAgency = userAgency
      this.currencies = currencies
      this.loading=false

    })
  }
  filterAgencyCustomers(searchTerm: string) {
    this.filteredAgencyCustomers = this.agencyCustomers.filter(member => {
      let search = searchTerm.toLowerCase();
      let memberInfo = member.lastname + '-' +
        (member.postname || member.firstname);
      return memberInfo.toLowerCase().includes(search);
    });
  }
  onDatCreation() {
    this.model.customerId = parseInt(this.selected_member_id)
    this.model.agencyId = this.userAgency.agencyId
    this.apiService.createDatAccount(this.model).subscribe((res) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Compte DAT crée avec succès'
      })
    },()=>{
      this.messageService.add({
        severity: 'error',
        summary: 'Oups! something went wrong'
      })
    })
  }
}
