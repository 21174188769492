import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { MemberRepayment } from 'src/app/models/customer.model';
import { LoanRequest } from 'src/app/models/loanRequest';
import { Customer } from 'src/app/models/loanRequest.model';
import { LoanRequestData } from 'src/app/models/loanRequestData';
import { ApiService } from 'src/app/services/api.service';

interface RepaymentResult {
  convertedAmount: number
  totalRepayment: number
  interestAmount: number
  repaymentAmount: number
  remainingCredit: number
}

@Component({
  selector: 'app-group-repayments',
  templateUrl: './group-repayments.component.html',
  styleUrls: ['./group-repayments.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GroupRepaymentsComponent implements AfterViewInit, OnInit {
  title = "Crédits à rembourser"
  loading = true;
  readonly TODAY_REPAYMENTS = "todayRepayements"
  readonly FUTURE_REPAYMENTS = "futureRepayements"
  readonly OVERDUE_REPAYMENTS = "overDueRepayements"
  readonly PROGRESSIVE_METHOD = "Progressif de capital"
  readonly CONSTANT_METHOD = "Remboursement Constant"
  readonly DEGRESSIVE_METHOD = "Remboursement Dégressif"
  readonly WEEKLY_REPAYMENT_MOD = "Hebdomadaire"
  readonly MONTHLY_REPAYMENT_MOD = "Mensuel"
  readonly BI_WEEKLY_REPAYMENT_MOD = "Bi-hebdomadaire"


  readonly CDF = "CDF"
  readonly USD = "USD"

  columnsToDisplay = ['customerGroupName', 'customerGroupManager', 'activityNature', 'activityAxis','agency'];
  displayedColumns: string[] = ['repaymentNum', 'dueDate', 'repaymentDate', 'repaymentAmount', 'interestAmount', 'totalRepayment', 'remainingCredit', 'paymentStatus', 'overDueDays'];
  memberColumns: string[] = ['memberName', 'repaymentAmount', 'interestAmount', 'totalRepayment', 'remainingCredit']
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: LoanRequest | null;
  dataSource = new MatTableDataSource();

  repaymentStatus = ''
  loanManager = ''
  loanManagers: []
  overDueDays: number
  groupMembers: Customer[]
  currencyRate: number = 0
  totalRepaymentSum: number = 0
  username: string


  constructor(private router: Router, private apiService: ApiService, private messageService: MessageService) {
    this.currencyRate = parseFloat(apiService.getItem('currencyRate'))
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.fetchData()
    this.username = this.apiService.getItem('username')

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  fetchData() {
    const groupInProgressLoanRepayments$ = this.apiService.getgroupsInProgressRepayments(this.apiService.getItem('username'))
    const loanManagers$ = this.apiService.agencyLoanManagers_getAll(this.apiService.getItem(('username')))
    combineLatest([groupInProgressLoanRepayments$, loanManagers$]).subscribe(([groupInProgressLoanRepayments, loanManagers]) => {
      groupInProgressLoanRepayments.forEach(group => {
        if (group.loanRequests) {
          group.loanRequests.forEach(loanRequest => {
            group.members.forEach((member) => {
              let { reimbursementType, interestRate, period, convertedAmount, constantAmount, currency } = loanRequest
              convertedAmount = member.convertedAmount;
              constantAmount = member.repaymentDetails.creditAmount

              // Calculate repayment for each member:
              member.repaymentDetails = this.calculateMemberRepayment(
                reimbursementType,
                interestRate,
                period,
                convertedAmount,
                constantAmount,
                currency
              );
            })

          });
          // Calculate total repayment sum for the loan:
          this.totalRepaymentSum = group.members?.reduce(
            (sum, member) => sum + member.repaymentDetails.totalRepayment, 0);
        }
      })
      this.dataSource.data = groupInProgressLoanRepayments
      this.loanManagers = loanManagers
      this.loading = false
    })
  }
  onStatusChange() {
    switch (this.repaymentStatus) {
      case this.TODAY_REPAYMENTS:
        this.apiService.getTodayDuedateLoanRequests(this.apiService.getItem('username')).subscribe((todayRepayments) => {
          this.dataSource = todayRepayments
        })
        break
      case this.OVERDUE_REPAYMENTS:
        this.apiService.getOverdueLoanRequests(this.apiService.getItem('username')).subscribe((overdueRepayments) => {
          this.dataSource = overdueRepayments
        })
        break
      case this.FUTURE_REPAYMENTS:
        this.apiService.getFutureLoanRequest(this.apiService.getItem('username')).subscribe((futureRepayments) => {
          this.dataSource = futureRepayments
        })
      default:
        return this.fetchData()

    }
  }

  calculateMemberRepayment(reimbursementType: string, interestRate: number, period: number, convertedAmount: number, constantAmount: number, currency: string) {
    switch (reimbursementType) {
      case this.CONSTANT_METHOD:
        return this.handleConstantMethod({ interestRate, period, convertedAmount, constantAmount, currency })
      case this.PROGRESSIVE_METHOD:
        return this.handleProgressiveMethod({ interestRate, period, convertedAmount, constantAmount, currency })
      case this.DEGRESSIVE_METHOD:
        return this.handleDegressiveMethod({interestRate, period, convertedAmount, constantAmount, currency})
      default:
        return new Error('Invalid repayment method provided.');
    }
  }
  handleProgressiveMethod({ interestRate, period, convertedAmount, constantAmount, currency }): RepaymentResult {

    const interestRatio = interestRate / 100;
    const totalRepayment = (constantAmount * interestRatio) / (1 - Math.pow(1 + interestRatio, -period));
    const interestAmount = convertedAmount * interestRatio;
    const repaymentAmount = totalRepayment - interestAmount;
    const remainingCredit = convertedAmount - repaymentAmount;

    const progressiveResult = {
      convertedAmount,
      totalRepayment,
      interestAmount,
      repaymentAmount,
      remainingCredit,
    };

    if (currency === this.CDF) {
      progressiveResult.convertedAmount *= this.currencyRate;
      progressiveResult.totalRepayment *= this.currencyRate;
      progressiveResult.interestAmount *= this.currencyRate;
      progressiveResult.repaymentAmount *= this.currencyRate;
      progressiveResult.remainingCredit *= this.currencyRate
    }

    return progressiveResult;
  }

  handleConstantMethod({ interestRate, period, convertedAmount, constantAmount, currency }): RepaymentResult {
    const interestRatio = interestRate / 100
    const repaymentAmount = Math.round(constantAmount / period)
    const interestAmount = constantAmount * interestRatio
    const totalRepayment = repaymentAmount + interestAmount
    const remainingCredit = convertedAmount - repaymentAmount
    const constantResult = {
      convertedAmount,
      totalRepayment,
      interestAmount,
      repaymentAmount,
      remainingCredit
    }
    if (currency === this.CDF) {
      // Conditionally multiply values for CDF currency
      constantResult.convertedAmount *= this.currencyRate;
      constantResult.totalRepayment *= this.currencyRate;
      constantResult.interestAmount *= this.currencyRate;
      constantResult.repaymentAmount *= this.currencyRate;
      constantResult.remainingCredit *= this.currencyRate
    }

    return constantResult
  }
  handleDegressiveMethod({interestRate, period, convertedAmount, constantAmount, currency}): RepaymentResult {
    const interestRatio = interestRate / 100
    const repaymentAmount = Math.round(constantAmount / period)
    const remainingCredit = convertedAmount - repaymentAmount
    const interestAmount = convertedAmount * interestRatio
    const totalRepayment = repaymentAmount + interestAmount
    const degressiveResult = {
      convertedAmount,
      totalRepayment,
      interestAmount,
      repaymentAmount,
      remainingCredit
    }
    if (currency === this.CDF) {
      // Conditionally multiply values for CDF currency
      degressiveResult.convertedAmount *= this.currencyRate;
      degressiveResult.totalRepayment *= this.currencyRate;
      degressiveResult.interestAmount *= this.currencyRate;
      degressiveResult.repaymentAmount *= this.currencyRate;
      degressiveResult.remainingCredit *= this.currencyRate;
    }
    return degressiveResult
  }
  handleGroupRepayment(
    currencyRate: number,
    customerGroupId: number,
    loanRequests: LoanRequestData[],
    members: MemberRepayment[],
    username: string
  ) {
    currencyRate = this.currencyRate
    username = this.username

    this.apiService.handleGroupRepayment(
      currencyRate,
      customerGroupId,
      loanRequests,
      members,
      username
    ).subscribe((res) => {
      this.messageService.add({
        severity: 'info',
        summary: res.message
      })
      setTimeout(() => this.router.navigate(['group-repayments']), 2000);
    })
  }
}
