<section class="example-container mat-elevation-z8" tabindex="0">
  <div style="display: flex; justify-content: space-between;">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>

  </div>
  <mat-spinner *ngIf="loading"></mat-spinner>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="customerGroupName">
      <th mat-header-cell *matHeaderCellDef> Nom Groupe. </th>
      <td mat-cell *matCellDef="let element"> {{element.customerGroupName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="customerGroupManager">
      <th mat-header-cell *matHeaderCellDef> Gestionnaire </th>
      <td mat-cell *matCellDef="let element"> {{element.customerGroupManager}} </td>
    </ng-container>

    <ng-container matColumnDef="activityNature">
      <th mat-header-cell *matHeaderCellDef> Nature d'activité </th>
      <td mat-cell *matCellDef="let element"> {{element.activityNature}} </td>
    </ng-container>
    <ng-container matColumnDef="activityAxis">
      <th mat-header-cell *matHeaderCellDef> Axe d'activité </th>
      <td mat-cell *matCellDef="let element"> {{element.activityAxis}} </td>
    </ng-container>
    <ng-container matColumnDef="agency">
      <th mat-header-cell *matHeaderCellDef> Agence.</th>
      <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="expand row"
          (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div style="display: flex;flex-flow: row wrap;width: 100%;">
            <div class="example-element-diagram mat-elevation-z2">
              <p-image src="../assets/images/group.png" alt="Profil" width="150"
              height="150"></p-image>
              <div class="example-element-symbol"> {{element.customerGroupName}} </div>
              <div class="example-element-position"> {{element.activityAxis}} </div>
              <div *ngFor="let loanRequest of element?.loanRequests">
                <div class="example-element-name"> {{element.reimbursementMode}}
                </div>
                <div class="example-element-name"> {{element.reimbursementType}} </div>
                <div *ngFor="let account of element.accounts"><span
                    *ngIf="account.accountType=='Compte EAV de groupe'">Balance
                    :{{account.currency=='CDF'?(account.accountSold|currency:'CDF'):account.accountSold|currency}}</span>
                </div>

              </div>
              <div class="currencyRate">Taux du jour : {{currencyRate}}</div>
            </div>

            <section tabindex="0">
              <table mat-table [dataSource]="element.members" class="mat-elevation-z2">
                <ng-container matColumnDef="memberName">
                  <th mat-header-cell *matHeaderCellDef> Nom & Postnom </th>
                  <td mat-cell *matCellDef="let member"> {{member.lastname +' '+member.firstname }} </td>
                </ng-container>


                <!-- Weight Column -->
                <ng-container matColumnDef="repaymentAmount">
                  <th mat-header-cell *matHeaderCellDef> Montant Remb. </th>
                  <td mat-cell *matCellDef="let member"> {{element.currency=='CDF'?(
                    member.repaymentDetails.repaymentAmount|currency:'CDF'):
                    member.repaymentDetails.repaymentAmount|currency }} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="interestAmount">
                  <th mat-header-cell *matHeaderCellDef> Intérêts. </th>
                  <td mat-cell *matCellDef="let member">
                    {{ element.currency=='CDF'?(member.repaymentDetails.interestAmount|currency:'CDF'):
                    member.repaymentDetails.interestAmount|currency}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="totalRepayment">
                  <th mat-header-cell *matHeaderCellDef> Total Remb. </th>
                  <td mat-cell *matCellDef="let member">
                    {{element.currency=='CDF'?(member.repaymentDetails.totalRepayment|currency:'CDF'):
                    member.repaymentDetails.totalRepayment|currency}}

                  </td>
                </ng-container>
                <ng-container matColumnDef="remainingCredit">
                  <th mat-header-cell *matHeaderCellDef>Restant dû. </th>
                  <td mat-cell *matCellDef="let member">{{element.currency=='CDF'?(
                    member.repaymentDetails.remainingCredit|currency:'CDF' ):
                    member.repaymentDetails.remainingCredit|currency}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="memberColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: memberColumns;"></tr>
              </table>
              <p-messages></p-messages>
              <div class="example-button-row" style="position: relative;float: right;"> <button mat-raised-button
                  color="primary" (click)="handleGroupRepayment(
                    currencyRate,
                    element.customerGroupId,
                    element.loanRequests,
                    element.members,
                    username
                    )">Valider le rembourser</button></div>
            </section>
            <section *ngFor="let loanRequest of element.loanRequests" class="card">
              <mat-table [dataSource]="loanRequest.loanRepayments">
                <!-- Position Column -->
                <ng-container matColumnDef="repaymentNum">
                  <mat-header-cell *matHeaderCellDef> N°. </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment"> {{loanRepayment.repaymentNum}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dueDate">
                  <mat-header-cell *matHeaderCellDef> Date Remb. </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment"> {{loanRepayment.dueDate | date:'dd-MM-yyyy HH:mm' }}
                  </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="repaymentDate">
                  <mat-header-cell *matHeaderCellDef> Date Payement. </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment"> {{loanRepayment.repaymentDate | date:'dd-MM-yyyy HH:mm'}}
                  </mat-cell>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="repaymentAmount">
                  <mat-header-cell *matHeaderCellDef> Montant Remb. </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment">
                    {{loanRequest.currency=="CDF"?(loanRepayment.repaymentAmount|currency:"CDF"):loanRepayment.repaymentAmount|currency}}
                  </mat-cell>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="interestAmount">
                  <mat-header-cell *matHeaderCellDef> Intérêts </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment">
                    {{loanRequest.currency=="CDF"?(loanRepayment.interestAmount|currency:"CDF"):loanRepayment.interestAmount|currency}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalRepayment">
                  <mat-header-cell *matHeaderCellDef> Total Remb. </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment">
                    {{loanRequest.currency=="CDF"?(loanRepayment.totalRepayment|currency:"CDF"):loanRepayment.totalRepayment|currency}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="remainingCredit">
                  <mat-header-cell *matHeaderCellDef> Restant Dû. </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment">
                    {{loanRequest.currency=="CDF"?(loanRepayment.remainingCredit|currency:"CDF"):loanRepayment.remainingCredit|currency}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="paymentStatus">
                  <mat-header-cell *matHeaderCellDef> Status Payement. </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment">
                    <span class="material-symbols-outlined" *ngIf="loanRepayment.paymentStatus === 'Payé'"
                      style="color: blue;">
                      done_all
                    </span>
                    <span class="material-symbols-outlined" *ngIf="loanRepayment.paymentStatus === 'Soldé'"
                      style="color: rgba(0, 0, 255, 0.936);">
                      recommend
                    </span>
                    <span class="material-symbols-outlined" *ngIf="loanRepayment.paymentStatus === 'En attente'"
                      style="color: brown;">
                      preliminary
                    </span>
                    {{loanRepayment.paymentStatus}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="overDueDays">
                  <mat-header-cell *matHeaderCellDef> Jours de retard. </mat-header-cell>
                  <mat-cell *matCellDef="let loanRepayment"> {{loanRepayment.overdueDays
                    }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </section>

          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
</section>