import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-user-collects',
  templateUrl: './user-collects.component.html',
  styleUrls: ['./user-collects.component.css']
})
export class UserCollectsComponent implements OnInit {
  displayedColumns: string[] = ['cartOwner', 'collectDate', 'collectedAmount', 'collectStatus','transactor'];
  dataSource = new MatTableDataSource();
  userCollects: []
  constructor(private apiService: ApiService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit(): void {
    this.fetchData()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  fetchData() {
    this.apiService.getUserCollects(this.apiService.getItem('username')).subscribe((collects) => {
      this.dataSource.data = collects
    })
  }

}
