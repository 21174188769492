<h2 mat-dialog-title>Creation du groupe</h2>
<mat-dialog-content class="mat-typography">
    <form class="example-form">
        <div class="example-container">
            <mat-form-field>
                <mat-label>Nom du groupe</mat-label>
                <input matInput readonly>
            </mat-form-field>

            <mat-form-field floatLabel="always" style="margin-left: 5px;">
                <mat-label>Gestionnaire</mat-label>
                <input matInput readonly class="example-right-align">
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>Choisir le membre</mat-label>
            <mat-select>
                <mat-option>None</mat-option>
                <mat-option> </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-mini-fab color="accent" aria-label="icon button to add a branch" style="margin-left: 15px;"
            (click)="addMemberSelectTo(elementRef.nativeElement)">
            <mat-icon>delete</mat-icon>
        </button>
        <button mat-mini-fab color="primary" aria-label="icon button to add a branch" style="margin-left: 15px;">
            <mat-icon>plus_one</mat-icon>
        </button>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-raised-button color="primary" [disabled]>
        Ajouter
    </button>
</mat-dialog-actions>