<H2 mat-dialog-title>{{title}}</H2>
<mat-dialog-content class="mat-typography">
    <form>
        <!-- IDENTIFIANT -->
        <mat-form-field class="large" appearance="fill">
            <mat-label>Identifiant</mat-label>
            <input name="daUsername" matInput [(ngModel)]="model.username" maxlength="50">
        </mat-form-field>
        <!-- BRANCH -->
        <mat-form-field class="list" appearance="fill" style="margin-left:4px">
            <mat-label>Selectionner Agence</mat-label>
            <mat-select name="daAgency" required [(ngModel)]="selected_agency_id">
                <mat-option *ngFor="let agency of agencies"
                    value="{{agency.agencyId}}">{{agency.agencyName}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div>
            <!-- PRENOM -->
            <mat-form-field class="large" appearance="fill">
                <mat-label>Prénom</mat-label>
                <input name="daFirtName" matInput required maxlength="50" [(ngModel)]="model.firstname">
            </mat-form-field>
            <mat-checkbox name="daCheck" [(ngModel)]="model.active">Utilisateur Actif</mat-checkbox>

        </div>

        <div>
            <!-- NOM -->
            <mat-form-field class="large" appearance="fill">
                <mat-label>Nom</mat-label>
                <input name="daLastName" matInput required maxlength="50" [(ngModel)]="model.lastname">
            </mat-form-field>
        </div>
        <div>
            <!-- DOB -->
            <div>Date de Naissance</div>
            <p-calendar name="dtNaissance" dateFormat="dd-mm-yy" inputId="basic" [(ngModel)]="date_of_birth">
            </p-calendar>
        </div>
    </form>
</mat-dialog-content>
<p-messages></p-messages>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-raised-button color="primary" [disabled]="!formIsValid()" (click)="onSave()">Ajouter</button>
</mat-dialog-actions>