import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { MatPaginator } from '@angular/material/paginator';
import { combineLatest } from 'rxjs';
import config from '../../../../auth_config.json'
import { Transaction } from 'src/app/models/cartType.model';
import { MessageService } from 'primeng/api';
import { MatDialog } from '@angular/material/dialog';
import { AccountDialogComponent } from '../account-dialog/account-dialog.component';

@Component({
  selector: 'app-dat',
  templateUrl: './dat.component.html',
  styleUrls: ['./dat.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DatComponent implements AfterViewInit, OnInit {
  dataSource = new MatTableDataSource()
  columnsToDisplay = ['membername', 'Gender', 'phone', 'memberType', 'agency'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null;
  currencies: []
  transactionTypes: []
  savings: []
  value1: number = 1;
  collectors: []
  selected_collector_id = ''
  API_URI=`${config.apiUri}/api/getFile/`

  model = new Transaction()

  constructor(public dialog: MatDialog,private apiService: ApiService, private messageService: MessageService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit(): void {
    this.fetchData()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  fetchData() {
    const currencies$ = this.apiService.currencies_getAll()
    const transactionTypes$ = this.apiService.transactionTypes_getAll()
    const savings$ = this.apiService.getMemberDatAccounts(this.apiService.getItem('username'))
    const collectors$ = this.apiService.getAgencySalesAgents(this.apiService.getItem('username'))

    combineLatest([savings$, currencies$, transactionTypes$, collectors$]).subscribe(([savings, currencies, transactionTypes, collectors]) => {
      this.dataSource.data = savings
      this.currencies = currencies
      this.transactionTypes = transactionTypes
      this.collectors = collectors
    })

  }
  openDialog() {
    const dialogRef = this.dialog.open(AccountDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  transaction_handle(issuer: number) {
    this.model.username = this.apiService.getItem('username')
    this.model.issuer = issuer
    this.model.userId = parseInt(this.selected_collector_id)
    if (this.model.currency && this.model.issuer && this.model.transactionAmount && this.model.transactionType && this.model.username) {
      this.apiService.transaction_handle(this.model).subscribe((res) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Transaction réussie',
        });
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Une erreur est survenue lors de cette transaction',
        });
      })
    }
  }
 
}
export interface PeriodicElement {
  demandeur: string;
  date_demande: Date;
  montant: number;
  taux: number;
  echeance: number
  type_remboursement: string
  mode_remboursement: string
  decision: string
  option: string
}
