import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { CustomerComponent } from './pages/customer/customer.component';
import { CartsComponent } from './pages/carts/carts.component';
import { CashierComponent } from './pages/cashier/cashier.component';
import { CoffreComponent } from './pages/coffre/coffre.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { MonitoringComponent } from './pages/monitoring/monitoring.component';
import { CustomerDetailsComponent } from './pages/customer-details/customer-details.component';
import { GoupRequestComponent } from './pages/goup-request/goup-request.component';
import { MemberRequestComponent } from './pages/member-request/member-request.component';
import { ApprobationComponent } from './pages/approbation/approbation.component';
import { UsersComponent } from './pages/users/users.component';
import { CashFlowComponent } from './pages/cash-flow/cash-flow.component';
import { MemberRepaymentsComponent } from './pages/member-repayments/member-repayments.component';
import { GroupRepaymentsComponent } from './pages/group-repayments/group-repayments.component';
import { MemberPortfolioComponent } from './pages/member-portfolio/member-portfolio.component';
import { GroupPortfolioComponent } from './pages/group-portfolio/group-portfolio.component';
import { DatComponent } from './pages/dat/dat.component';
import { EavComponent } from './pages/eav/eav.component';
import { EavgComponent } from './pages/eavg/eavg.component';
import { EntrepriseComponent } from './pages/entreprise/entreprise.component';
import { AccountComponent } from './pages/account/account.component';
import { GroupComponent } from './pages/group/group.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customers',
    component: CustomerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'member-details',
    component: CustomerDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'groups',
    component: GroupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'member-repayments',
    component: MemberRepaymentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'group-repayments',
    component: GroupRepaymentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'carts',
    component: CartsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dat',
    component: DatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'eavi',
    component: EavComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'eavg',
    component: EavgComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cashier',
    component: CashierComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cash-flow',
    component: CashFlowComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'coffre',
    component: CoffreComponent,
    canActivate: [AuthGuard],
  },
  
  {
    path: 'transactions',
    component: TransactionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'configuration',
    component: ConfigurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-api',
    component: ExternalApiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],

  },
  {
    path: 'group-request',
    component: GoupRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'monitoring',
    component: MonitoringComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'member-request',
    component: MemberRequestComponent,
    canActivate: [AuthGuard],

  },
  {
    path: 'approbation',
    component: ApprobationComponent,
    canActivate: [AuthGuard],

  },
  {
    path: 'member-portfolio',
    component: MemberPortfolioComponent,
    canActivate: [AuthGuard],

  },
  {
    path: 'group-portfolio',
    component: GroupPortfolioComponent,
    canActivate: [AuthGuard],

  },
  {
    path: 'entreprises',
    component: EntrepriseComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
