import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { CollectType } from 'src/app/models/cartType.model';
import { CartCollect } from 'src/app/models/transactions.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-entreprise',
  templateUrl: './entreprise.component.html',
  styleUrls: ['./entreprise.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EntrepriseComponent implements OnInit{
  loading=true
  dataSource = new MatTableDataSource()
  columnsToDisplay = ['name', 'weight', 'symbol', 'position'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null;

  agencyCollectors: []
  collectTypes: CollectType[]
  model = new CartCollect()
  transactionType = ''
  transactionAmount = ''
  username = ''
  selected_collector_id = ''

  constructor(private apiService: ApiService, private messageService: MessageService, private router: Router) {
    this.username = apiService.getItem('username')
  }
  ngOnInit(): void {
    this.fetchData()
  }
  fetchData() {
    const carts$ = this.apiService.getAgencyCarts(this.apiService.getItem('username'))
    const agencyCollectors$ = this.apiService.agencyCollectors_getAll(this.apiService.getItem('username'))
    const collectType$ = this.apiService.getAllCollectTypes()
    combineLatest([carts$, agencyCollectors$, collectType$]).subscribe(([carts, agencyCollectors, collectTypes]) => {
      this.dataSource.data = carts
      this.agencyCollectors = agencyCollectors
      this.collectTypes = collectTypes
      this.loading = false
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
