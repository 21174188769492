import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoanRequest } from 'src/app/models/loanRequest.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-discharge',
  templateUrl: './discharge.component.html',
  styleUrls: ['./discharge.component.css']
})
export class DischargeComponent {
  loan_request: LoanRequest
  decaissements: []
  constructor(private readonly apiService: ApiService, private messageService: MessageService, private router: Router) {
    this.apiService.approvedRequests_getAll().subscribe((response) => {
      this.decaissements = response
    })
  }
  decaisser() {
    if (this.loan_request) {
      console.log(this.loan_request)
      this.apiService.loanRequest_validate(this.loan_request).subscribe((res) => {
        console.log(res)
        this.messageService.add({
          severity: 'success',
          summary: "Décaissement effectué avec succès"
        })
        setTimeout(() => this.router.navigate(['transactions']), 1000);

      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: error.message
        })
      })
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Aucun crédit n\'a été selectionné'
      })
    }
  }
}
