import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AggragatedData } from 'src/app/models/aggregate';
import { ApiService } from 'src/app/services/api.service';
import { AuthScopeService } from 'src/app/services/auth-permissions.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  daPanel = 'dark-panel'
  loading: boolean = true
  permissions: string[];

  profileJson: string = null;
  aggragatedData: any
  currencyRate: number = 0

  constructor(public auth: AuthService,
    private apiService: ApiService,
    public daAuth: AuthScopeService,
    private router: Router) {
    if (this.auth.isAuthenticated$) {
      this.auth.user$.subscribe((profile) => {
        if (profile) {
          this.apiService.setItem('username', profile.email)
        }
        this.currencyRate = parseFloat(this.apiService.getItem('currencyRate'))

      })
    }
  }
  ngOnInit(): void {
    this.fetchAggragatedData()
    this.currencyRate = parseFloat(this.apiService.getItem('currencyRate'))

  }

  fetchAggragatedData() {
    this.apiService.getAggragatedData(this.apiService.getItem('username')).subscribe((data: AggragatedData) => {
      this.aggragatedData = data
      this.loading = false
    })
  }

  goToPage(url: string) {
    this.router.navigate([url]);
  }

  reLoad() {

    this.router.navigate([this.router.url])
  }

}
