<section class="example-container mat-elevation-z8" tabindex="0">
    <div style="display: flex; justify-content: space-between;">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
        </mat-form-field>

    </div>
    <mat-spinner *ngIf="loading"></mat-spinner>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef> Nom </th>
            <td mat-cell *matCellDef="let element"> {{element.lastname}}
            </td>
        </ng-container>
        <ng-container matColumnDef="postname">
            <th mat-header-cell *matHeaderCellDef> Postnom </th>
            <td mat-cell *matCellDef="let element"> {{element.postname}} </td>
        </ng-container>

        <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef> Prenom </th>
            <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
        </ng-container>
        <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef> Genre </th>
            <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
        </ng-container>
        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Téléphone </th>
            <td mat-cell *matCellDef="let element"> {{element.phone1}} </td>
        </ng-container>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> Agence </th>
            <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
        </ng-container>

        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div style="display: flex;flex-flow: row wrap;width: 100%;">
                        <p-inputNumber [(ngModel)]="value1" [style]="{'width': '4rem'}" [showButtons]="true"
                        buttonLayout="vertical" spinnerMode="vertical" inputId="vertical"
                        decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                        <div class="example-element-diagram mat-elevation-z2">
                            <p-image [src]="API_URI+element.profilImageName" alt="Profil" width="200"
                                height="200"></p-image>

                            <div class="example-element-position"> {{element.lastname}} </div>
                            <div class="example-element-symbol"> {{element.firstname}}</div>
                            <div class="example-element-name" *ngFor="let loanRequest of element?.loanRequests">
                                <div>Mode remb. : {{loanRequest.reimbursementMode}}</div>
                                <div>Méthode : {{loanRequest.reimbursementType}}</div>
                            </div>
                            <div *ngFor="let account of element?.accounts"><span
                                    *ngIf="account.accountType=='Compte EAV individuel'">Balance :
                                    {{account.currency=='CDF'?
                                    (account.accountSold|currency:'CDF'):account.accountSold|currency}}</span>
                            </div>

                            <div class="currencyRate">Taux du jour : {{currencyRate}}</div>
                        </div>
                        <div class="example-element-description card">
                            <form class="example-form">

                                <table class="example-full-width" cellspacing="0">
                                    <tr *ngFor="let loanRequest of element?.loanRequests">
                                        <td>
                                            <mat-form-field class="example-full-width">
                                                <mat-label>Cap. Emprunté.</mat-label>
                                                <input name="repaymentAmount" matInput
                                                    value="{{loanRequest.currency=='CDF'?(loanRequest.constantAmount*currencyRate|currency:'CDF'):loanRequest.constantAmount|currency}}"
                                                    readonly>
                                            </mat-form-field>
                                        </td>

                                    </tr>
                                    <tr *ngFor="let loanRequest of element?.loanRequests">
                                        <td><mat-form-field class="example-full-width">
                                                <mat-label>Montant. Remb.</mat-label>
                                                <input name="repaymentAmount" matInput
                                                    [value]="loanRequest.currency=='CDF'?(element.repaymentDetails.repaymentAmount|currency:'CDF'):element.repaymentDetails.repaymentAmount|currency">
                                            </mat-form-field></td>
                                        <td><mat-form-field class="example-full-width">
                                                <mat-label>Intérêts</mat-label>
                                                <input name="interestAmount" matInput
                                                    [value]="loanRequest.currency=='CDF'?(element.repaymentDetails.interestAmount|currency:'CDF'):element.repaymentDetails.interestAmount|currency">
                                            </mat-form-field></td>

                                    </tr>
                                </table>

                                <p *ngFor="let loanRequest of element?.loanRequests">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Total. Remb.</mat-label>
                                        <input matInput name="totalRepayment"
                                            [value]="loanRequest.currency=='CDF'?(element.repaymentDetails.totalRepayment|currency:'CDF'):element.repaymentDetails.totalRepayment|currency">
                                    </mat-form-field>
                                    <mat-form-field class="example-full-width" style="margin-left: 2px;">
                                        <mat-label>Restant. dû.</mat-label>
                                        <input name="remainingCredit" matInput
                                            [value]="loanRequest.currency=='CDF'?(element.repaymentDetails.remainingCredit|currency:'CDF'):element.repaymentDetails.remainingCredit|currency">
                                    </mat-form-field>

                                </p>
                            </form>
                            <p-messages></p-messages>
                            <button mat-mini-fab color="primary" aria-label="icon button to add a branch" (click)="handleCustomerRepayment( 
                                currencyRate,
                                element.customerId,
                                element.loanRequests,
                                element.repaymentDetails,
                                username
                                )">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>

                        <section *ngFor="let loanRequest of element.loanRequests">
                            <mat-table [dataSource]="loanRequest.loanRepayments">
                                <!-- Position Column -->
                                <ng-container matColumnDef="repaymentNum">
                                    <mat-header-cell *matHeaderCellDef> N°. </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment"> {{loanRepayment.repaymentNum}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="dueDate">
                                    <mat-header-cell *matHeaderCellDef> Date Remb. </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment"> {{loanRepayment.dueDate |
                                        date:'dd-MM-yyyy
                                        HH:mm' }}
                                    </mat-cell>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="repaymentDate">
                                    <mat-header-cell *matHeaderCellDef> Date Payement. </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment"> {{loanRepayment.repaymentDate |
                                        date:'dd-MM-yyyy HH:mm'}}
                                    </mat-cell>
                                </ng-container>

                                <!-- Weight Column -->
                                <ng-container matColumnDef="repaymentAmount">
                                    <mat-header-cell *matHeaderCellDef> Montant Remb. </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment">
                                        {{loanRepayment.currency=="CDF"?(loanRepayment.repaymentAmount|currency:"CDF"):loanRepayment.repaymentAmount|currency}}
                                    </mat-cell>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="interestAmount">
                                    <mat-header-cell *matHeaderCellDef> Intérêts </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment">
                                        {{loanRepayment.currency=="CDF"?(loanRepayment.interestAmount|currency:"CDF"):loanRepayment.interestAmount|currency}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="totalRepayment">
                                    <mat-header-cell *matHeaderCellDef> Total Remb. </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment">
                                        {{loanRepayment.currency=="CDF"?(loanRepayment.totalRepayment|currency:"CDF"):loanRepayment.totalRepayment|currency}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="remainingCredit">
                                    <mat-header-cell *matHeaderCellDef> Restant Dû. </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment">
                                        {{loanRepayment.currency=="CDF"?(loanRepayment.remainingCredit|currency:"CDF"):loanRepayment.remainingCredit|currency}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="paymentStatus">
                                    <mat-header-cell *matHeaderCellDef> Status Payement. </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment">
                                        <span class="material-symbols-outlined"
                                            *ngIf="loanRepayment.paymentStatus === 'Payé'" style="color: blue;">
                                            done_all
                                        </span>
                                        <span class="material-symbols-outlined"
                                            *ngIf="loanRepayment.paymentStatus === 'Soldé'"
                                            style="color: rgba(0, 0, 255, 0.936);">
                                            recommend
                                        </span>
                                        <span class="material-symbols-outlined"
                                            *ngIf="loanRepayment.paymentStatus === 'En attente'" style="color: brown;">
                                            preliminary
                                        </span>
                                        {{loanRepayment.paymentStatus}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="overDueDays">
                                    <mat-header-cell *matHeaderCellDef> Jours de retard. </mat-header-cell>
                                    <mat-cell *matCellDef="let loanRepayment"> {{loanRepayment.overdueDays
                                        }} </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>
                        </section>

                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</section>