<div class="mat-elevation-z8 demo-table">
    <mat-form-field>
        <mat-label>Filtre</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="enter a name" #input>
    </mat-form-field>
    <!-- ADD MEMBER -->
    <button mat-mini-fab color="primary" aria-label="icon button to add a branch" (click)="navigateTo()">
        <mat-icon>plus_one</mat-icon>
    </button>
    <mat-spinner *ngIf="isloading"></mat-spinner>
    <table mat-table [dataSource]="dataSource">


        <!-- Name Column -->
        <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef> NOM </th>
            <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="postname">
            <th mat-header-cell *matHeaderCellDef> POSTNOM </th>
            <td mat-cell *matCellDef="let element"> {{element.postname}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef> PRENOM </th>
            <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
        </ng-container>
        <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell *matHeaderCellDef> DATE NAISSANCE </th>
            <td mat-cell *matCellDef="let element"> {{element.birthDate | date:'dd-MM-yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> AGENCE </th>
            <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
        </ng-container>
        <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef> DATE CREATION </th>
            <td mat-cell *matCellDef="let element"> {{element.subscriptionDate | date:'dd-MM-yyyy HH:mm'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>