import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { GroupDialogComponent } from 'src/app/components/group-dialog/group-dialog.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GroupComponent implements OnInit,AfterViewInit {
  loading = true
  dataSource = new MatTableDataSource()
  columnsToDisplay = ['agency', 'customerGroupName', 'customerGroupManager', 'activityNature', 'activityAxis'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: customerGroup | null;

  members = []
  filteredMembers = []
  groupMembers = []
  selectedMember: any = [];
  member: []


  constructor(public dialog: MatDialog, private router: Router, private messageService: MessageService, private readonly apiService: ApiService) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.fetchData()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  fetchData() {
    const members$ = this.apiService.getAllAgencyMembers(this.apiService.getItem('username'))
    const groups$ = this.apiService.getAllAgencyGroups(this.apiService.getItem('username'));

    combineLatest([members$, groups$]).subscribe(([members, groups]) => {
      this.members = members;
      this.dataSource.data = groups
      this.loading = false
    })
  }
  openDialog() {
    this.dialog.open(GroupDialogComponent);
  }
  navigateTo(url: string, id: number) {
    this.router.navigate([url, id])
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: customerGroup, filter: string) => {
      return data.customerGroupName.toLowerCase().includes(filter)
    };
    this.dataSource.filter = filterValue;
  }
  onKey(value) {
    this.selectedMember = this.search(value);
  }

  search(value: string) {
    let filter = this.members.filter(item =>
      item.firstname.toLowerCase().includes(value.toLowerCase()) ||
      item.lastname.toLowerCase().includes(value.toLowerCase()) ||
      item.postname.toLowerCase().includes(value.toLowerCase())
    );
    return [...filter];
  }

  getSelectedMember(value) {
    this.member = value
  }
  onMemberPush() {
    this.groupMembers.push(this.member)
  }
  onRemove(member) {
    const memberToRemove = this.groupMembers.find((m) => m.customerId === member.customerId);
    if (memberToRemove) {
      const indexToRemove = this.groupMembers.indexOf(memberToRemove);
      this.groupMembers.splice(indexToRemove, 1);
    }
  }
  onMembersAdd(customerGroupId: number) {
    if (this.groupMembers.length != 0) {
      this.apiService.groupMembers_save(customerGroupId, this.groupMembers).subscribe((res) => {
        this.openSnackBar(res.message, "success")
        setTimeout(() => this.router.navigate(['groups']), 1000);

      }, (error) => this.openSnackBar(error.message, "error")
      )
    } else {
      this.openSnackBar("Merci d'ajouter aumoins un membre", "error")
    }
  }
  openSnackBar(message: string, action: string) {

    this.messageService.add({
      severity: action,
      summary: action,
      detail: message,
    });
  }

  filterMembers(searchTerm: string) {
    this.filteredMembers = this.members.filter(member => {
      let search = searchTerm.toLowerCase();
      let memberInfo = member.lastname
        (member.postname || member.firstname);
      return memberInfo.toLowerCase().includes(search);
    });
  }
}
export interface customerGroup {
  customerGroupName: string;
  customerGroupManager: string
  acttivityNature: string;
  activityAxis: string;
}
