<div class="mat-elevation-z8">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef> Nom Membre </th>
            <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
        </ng-container>
        <ng-container matColumnDef="postname">
            <th mat-header-cell *matHeaderCellDef> Postnom Membre </th>
            <td mat-cell *matCellDef="let element"> {{element.postname}} </td>
        </ng-container>
        <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef> Prénom</th>
            <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
        </ng-container>
        <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef> Genre </th>
            <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
        </ng-container>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> Agence </th>
            <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">

                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                    <div class="example-element-diagram card">
                        <div style="display: flex;">
                            <p-inputNumber [(ngModel)]="value1" [style]="{'width': '4rem'}" [showButtons]="true"
                                buttonLayout="vertical" spinnerMode="vertical" inputId="vertical"
                                decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                            <div style="margin-left: 15px;">
                                <p-image [src]="API_URI+element.profilImageName" alt="Profil" width="200" height="200"></p-image>
                                <div class="example-element-name"> {{element.lastname}} {{element.postname}}</div>
                                <div class="example-element-symbol"> {{element.firstname}}</div>

                                <div class="example-element-name"> Agence : {{element.agency.agencyName}}</div>
                                <div class="example-element-weight">Balance : {{element.accounts[0].currency=="USD"?
                                    (element.accounts[0].accountSold|currency):element.accounts[0].accountSold|currency:'CDF'}}
                                </div>
                                <div class="example-element-weight">Balance : {{element.accounts[1].currency=="USD"?
                                    (element.accounts[1].accountSold|currency):element.accounts[1].accountSold|currency:'CDF'}}
                                </div>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Taux du jour</mat-label>
                                    <input matInput [value]="usdToCdf" readonly>
                                    <input matInput type="hiden" disabled="true" [(ngModel)]="customerId"
                                        [value]="element.custmerId">

                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <mat-stepper orientation="vertical" #stepper class="stepper card" style="margin-left: 15px;">

                        <mat-step [stepControl]="requestedAmountFormGroup">
                            <form [formGroup]="requestedAmountFormGroup">
                                <ng-template matStepLabel>Combien a-t-il demandé?</ng-template>
                                <mat-form-field>
                                    <mat-label>Montant</mat-label>
                                    <input matInput type="number" formControlName="requestedAmount"
                                        placeholder="Ex. 6000" required>
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="currencyFormGroup">
                            <form [formGroup]="currencyFormGroup">
                                <ng-template matStepLabel>Dans quelle devise?</ng-template>
                                <mat-form-field>
                                    <mat-label>Devise</mat-label>
                                    <mat-select formControlName="currency">
                                        <mat-option *ngFor="let currency of currencies"
                                            value="{{currency.currency}}">{{currency.currency}}</mat-option>
                                    </mat-select> </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="interestRateFormGroup">
                            <form [formGroup]="interestRateFormGroup">
                                <ng-template matStepLabel>Au taux de combien?</ng-template>
                                <mat-form-field>
                                    <mat-label>Taux d'intérêt</mat-label>
                                    <input matInput formControlName="interestRate" type="number" required>
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="periodFormGroup">
                            <form [formGroup]="periodFormGroup">
                                <ng-template matStepLabel>pour un echéance en mois de combien ?</ng-template>
                                <mat-form-field>
                                    <mat-label>L'echéance en mois</mat-label>
                                    <input matInput type="number" formControlName="period" />
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="reimbursementModeFormGroup">
                            <form [formGroup]="reimbursementModeFormGroup">
                                <ng-template matStepLabel>Comment va-t-il rembourser?</ng-template>
                                <mat-form-field>
                                    <mat-label>Mode de remboursement</mat-label>
                                    <mat-select formControlName="reimbursementMode">
                                        <mat-option *ngFor="let mode of reimbursementModes"
                                            value="{{mode.reimbursementMode}}">{{mode.reimbursementMode}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="reimbursementTypeFormGroup">
                            <form [formGroup]="reimbursementTypeFormGroup">
                                <ng-template matStepLabel>Par quelle méthode de remboursement?</ng-template>
                                <mat-form-field>
                                    <mat-label>Type de remboursement</mat-label>
                                    <mat-select formControlName="reimbursementType">
                                        <mat-option *ngFor="let type of reimbursementTypes"
                                            value="{{type.reimbursementType}}">{{type.reimbursementType}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="loanManagerFormGroup">
                            <form [formGroup]="loanManagerFormGroup">
                                <ng-template matStepLabel>Gestionnaire du groupe</ng-template>
                                <mat-form-field>
                                    <mat-label>Gestionnaire</mat-label>
                                    <mat-select formControlName="loanManager">
                                        <mat-option *ngFor="let loanManager of loanManagers"
                                            value="{{loanManager.username}}">{{loanManager.firstname
                                            +' '+loanManager.lastname}}</mat-option>
                                    </mat-select> </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>

                        <mat-step>
                            <ng-template matStepLabel>Terminé</ng-template>
                            <p>Vous êtes à la fin</p>
                            <p-messages></p-messages>

                            <div>
                                <button mat-button matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary"
                                    (click)="onSend(element.customerId,element.agency.agencyId)">Envoyer
                                    la Demande</button>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>