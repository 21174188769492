<div class="mat-elevation-z8">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>
    <mat-spinner *ngIf="loading"></mat-spinner>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="groupName">
            <th mat-header-cell *matHeaderCellDef> Nom Groupe </th>
            <td mat-cell *matCellDef="let element"> {{element.customerGroupName}} </td>
        </ng-container>
        <ng-container matColumnDef="activityNature">
            <th mat-header-cell *matHeaderCellDef> Nature Activité. </th>
            <td mat-cell *matCellDef="let element"> {{element.activityNature}} </td>
        </ng-container>
        <ng-container matColumnDef="activityAxis">
            <th mat-header-cell *matHeaderCellDef> Axe Activité.</th>
            <td mat-cell *matCellDef="let element"> {{element.activityAxis}} </td>
        </ng-container>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> Agence </th>
            <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
        </ng-container>
        <ng-container matColumnDef="sensibilisator">
            <th mat-header-cell *matHeaderCellDef> Gestionnaire </th>
            <td mat-cell *matCellDef="let element"> {{element.customerGroupManager}} </td>
        </ng-container>

        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram card">
                        <div style="display: flex;">
                            <p-inputNumber [(ngModel)]="value1" [style]="{'width': '4rem','margin-right':'8px'}"
                                [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical" inputId="vertical"
                                decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                            <div>
                                <p-image src="../assets/images/group.png" alt="Profil" width="150"
                                    height="150"></p-image>
                                <div class="example-element-symbol"> {{element.customerGroupName}} </div>
                                <div class="example-element-position">Agence : {{element.agency.agencyName}} </div>

                                <div class="example-element-name"> Balance : {{element.accounts[0].currency}} :
                                    {{element.accounts[0].currency=='CDF'?(element.accounts[0].accountSold|currency:'CDF'):element.accounts[0].accountSold|currency}}
                                </div>
                                <div class="example-element-weight">Balance : {{element.accounts[1].currency}} :
                                    {{element.accounts[1].currency=='CDF'?(element.accounts[1].accountSold|currency:'CDF'):element.accounts[1].accountSold|currency}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="example-element-description card">
                        <mat-checkbox class="example-margin" [(ngModel)]="model.isCollected">Montant collecté?
                        </mat-checkbox>
                        <form class="example-form">

                            <table class="example-full-width" cellspacing="0">
                                <tr>
                                    <td>
                                        <mat-form-field class="example-full-width" *ngIf="model.isCollected">
                                            <mat-label>Collecteur</mat-label>
                                            <mat-select name="transactionType" [(ngModel)]="selected_collector_id">
                                                <mat-option *ngFor="let collector of collectors"
                                                    value="{{collector.userId}}">
                                                    {{collector.lastname+' '+collector.firstname}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </td>
                                </tr>
                                <tr>
                                    <td> <mat-form-field class="example-full-width">
                                            <mat-label>Type de transaction</mat-label>
                                            <mat-select name="transactionType" [(ngModel)]="model.transactionType"
                                                required>
                                                <mat-option *ngFor="let transactionType of transactionTypes"
                                                    value="{{transactionType.transactionType}}">
                                                    {{transactionType.transactionType}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field></td>
                                    <td><mat-form-field>
                                            <mat-label>Dévise</mat-label>
                                            <mat-select name="currency" [(ngModel)]="model.currency" required>
                                                <mat-option *ngFor="let currency of currencies"
                                                    value="{{currency.currency}}">
                                                    {{currency.currency}}
                                                </mat-option>
                                            </mat-select> </mat-form-field></td>
                                </tr>
                            </table>

                            <p>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Montant</mat-label>
                                    <input name="tranctionAmount" matInput [(ngModel)]="model.transactionAmount"
                                        type="number" required>
                                </mat-form-field>

                                <mat-form-field class="example-full-width" style="margin-left: 20px;">
                                    <mat-label>Récipiendaire</mat-label>
                                    <input name="recipiendaire" matInput [(ngModel)]="model.recipiendaire" required>
                                </mat-form-field>

                            </p>
                            <div>
                                <mat-form-field class="large" appearance="fill">
                                    <mat-label>Communication</mat-label>
                                    <textarea matInput name="daInfo" [(ngModel)]="model.communication"></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                        <p-messages></p-messages>

                        <button mat-mini-fab color="primary" aria-label="icon button to add a branch"
                            (click)="transaction_handle(element.customerGroupId)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>