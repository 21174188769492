import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Currency, ReimbursementMod, ReimbursementType } from 'src/app/models/currency.model';
import { Member } from 'src/app/models/customer.model';
import { LoanType } from 'src/app/models/loanType.model';
import { UserAccount } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-goup-request',
  templateUrl: './goup-request.component.html',
  styleUrls: ['./goup-request.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GoupRequestComponent {
  readonly CUSTOMER_GROUP_TYPE="Crédit de groupe"
  value1: number = 1;
  groupId: number
  isLoading = true
  creditAmounts: { [memberId: number]: string } = {};
  dataSource = new MatTableDataSource()
  displayedColumns: string[] = ['membername', 'membergender', 'memberphone', 'creditamount'];
  columnsToDisplay = ['groupName', 'activityNature', 'manager', 'activityAxis', 'agency'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null;

  requestedAmountFormGroup: FormGroup;
  currencyFormGroup: FormGroup;
  interestRateFormGroup: FormGroup;
  periodFormGroup: FormGroup;
  reimbursementModeFormGroup: FormGroup;
  reimbursementTypeFormGroup: FormGroup;
  loanManagerFormGroup: FormGroup

  loanTypes: LoanType[]
  currencies: Currency[]
  reimbursementModes: ReimbursementMod[]
  reimbursementTypes: ReimbursementType[]
  usdToCdf: number
  loanManagers = new UserAccount()

  groups: any[] = []

  constructor(private _formBuilder: FormBuilder, private readonly apiService: ApiService, private messageService: MessageService, private router: Router) { }
  ngOnInit() {
    this.fetchData()

    this.requestedAmountFormGroup = this._formBuilder.group({
      requestedAmount: ['', Validators.required],
    });

    this.currencyFormGroup = this._formBuilder.group({
      currency: ['', Validators.required],
    });
    this.interestRateFormGroup = this._formBuilder.group({
      interestRate: ['', Validators.required],
    });
    this.periodFormGroup = this._formBuilder.group({
      period: ['', Validators.required],
    });
    this.reimbursementModeFormGroup = this._formBuilder.group({
      reimbursementMode: ['', Validators.required],
    });
    this.reimbursementTypeFormGroup = this._formBuilder.group({
      reimbursementType: ['', Validators.required],
    });
    this.loanManagerFormGroup = this._formBuilder.group({
      loanManager: ['', Validators.required],
    });

    // Get the value of the firstFormGroup.group property.
    const firstFormGroupValue = this.requestedAmountFormGroup.value;

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  gatherCreditAmounts() {
    const creditAmountValues: string[] = [];
    for (const memberId in this.creditAmounts) {
      creditAmountValues.push(this.creditAmounts[memberId]);
    }
    return creditAmountValues;
  }

  onSend(groupId: number, agencyId: number, members: Member[]) {
    const creditAmounts = this.gatherCreditAmounts();
    for (let i = 0; i < creditAmounts.length; i++) {
      members[i].creditAmount = parseInt(creditAmounts[i])
    }
    if (this.isFormValid()) {
      const data: { [key: string]: any } = {
        requestedAmount: this.requestedAmountFormGroup.value,
        currency: this.currencyFormGroup.value,
        interestRate: this.interestRateFormGroup.value,
        period: this.periodFormGroup.value,
        reimbursementMode: this.reimbursementModeFormGroup.value,
        reimbursementType: this.reimbursementTypeFormGroup.value,
        username: this.loanManagerFormGroup.value

      }
      const flattenData = {
        requestedAmount: data.requestedAmount.requestedAmount,
        currency: data.currency.currency,
        interestRate: data.interestRate.interestRate,
        period: data.period.period,
        reimbursementMode: data.reimbursementMode.reimbursementMode,
        reimbursementType: data.reimbursementType.reimbursementType,
        username: data.username.loanManager,
        requestFees: (this.usdToCdf * 10),
        agencyId,
        members,
        groupId,
        currencyRate: this.usdToCdf,
        loanType:this.CUSTOMER_GROUP_TYPE
      };
      const totalCreditAmount = members.reduce((acc, member) => acc + member.creditAmount, 0)
      if (flattenData.requestedAmount == totalCreditAmount) {
        this.apiService.groupRequest_send(flattenData).subscribe((res) => {
          this.messageService.add({
            severity: "success",
            summary: res.message
          })
          this.resetForm();
          setTimeout(() => this.router.navigate(['group-request']), 3000);
        })
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Merci d\ajuster le montant entre les membres'
        })
      }
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Veuillez remplir tous les champs"
      })
    }
  }

  fetchData() {

    this.usdToCdf = parseFloat(this.apiService.getItem('currencyRate'))
    const loanTypes$ = this.apiService.loanTypes_getAll()
    const groups$ = this.apiService.getAllAgencyGroups(this.apiService.getItem('username'))
    const reimbursementModes$ = this.apiService.reimbursementModes_getAll()
    const reimbursementTypes$ = this.apiService.reimbursementTypes_getAll()
    const currencies$ = this.apiService.currencies_getAll()
    const loanManagers$ = this.apiService.agencyLoanManagers_getAll(this.apiService.getItem('username'))
    combineLatest([loanTypes$, groups$, reimbursementModes$, reimbursementTypes$, currencies$, loanManagers$]).subscribe(([loanTypes, groups, reimbursementModes, reimbursementTypes, currencies, loanManagers]) => {
      this.dataSource.data = groups
      this.groups = groups
      this.loanTypes = loanTypes
      this.reimbursementModes = reimbursementModes
      this.reimbursementTypes = reimbursementTypes
      this.currencies = currencies
      this.loanManagers = loanManagers
      this.isLoading = false
    })
  }
  isFormValid(): boolean {
    return (this.requestedAmountFormGroup.valid &&
      this.currencyFormGroup.valid &&
      this.interestRateFormGroup.valid &&
      this.periodFormGroup.valid &&
      this.reimbursementModeFormGroup.valid &&
      this.reimbursementTypeFormGroup.valid)
  }
  resetForm() {
    this.requestedAmountFormGroup.reset();
    this.currencyFormGroup.reset()
    this.interestRateFormGroup.reset()
    this.periodFormGroup.reset()
    this.reimbursementModeFormGroup.reset()
    this.reimbursementTypeFormGroup.reset()
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

