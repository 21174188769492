<h2><span>Compte</span> <button mat-mini-fab color="primary" aria-label="icon button to add a transaction"
    title="Clôture Caisse">
    <span class="material-symbols-outlined">
        toll
    </span>
</button></h2>
<div class="panel">
<form class="example-form">
    <mat-form-field>
        <mat-label>Type de compte</mat-label>
        <mat-select name="daAccoutType" [(ngModel)]="model.accountType">
            <mat-option *ngFor="let type of accountTypes" value="{{type.accountType}}">{{type.accountType}}</mat-option>
        </mat-select>
    </mat-form-field>

    <table class="example-full-width" cellspacing="0">
        <tr>
            <td><mat-form-field class="example-full-width">
                    <mat-label>Libellé</mat-label>
                    <input name="daLibelle" matInput [(ngModel)]="model.accountLibelle">
                </mat-form-field></td>
            <td><mat-form-field class="example-full-width">
                    <mat-label>Indice</mat-label>
                    <input name="daIndice" matInput [(ngModel)]="model.accountIndice">
                </mat-form-field></td>
        </tr>
    </table>

    <table class="example-full-width" cellspacing="0">
        <tr>
            <td><mat-form-field class="example-full-width">
                    <mat-label>Dévise</mat-label>
                    <mat-select name="currency" [(ngModel)]="model.currency">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let currency of currencies" value="{{currency.currency}}">{{currency.currency}}</mat-option>
                    </mat-select>
                </mat-form-field></td>
            <td><mat-form-field class="example-full-width">
                    <mat-label>Agence</mat-label>
                    <mat-select name="agency" [(ngModel)]="selected_agency_id">
                        <mat-option *ngFor="let agency of agencies" value="{{agency.agencyId}}">{{agency.agencyName}}</mat-option>
                    </mat-select>
                </mat-form-field></td>
        </tr>
    </table>
    <p-messages></p-messages>
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>

    <button mat-mini-fab color="primary" aria-label="icon button to add a branch" (click)="onSubmit()">
        <mat-icon>plus_one</mat-icon>
    </button>
</form>
</div>