import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { UserAccount } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { UserDetailsComponent } from '../user-details/user-details.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {
  title = 'Détails Utilisateur'
  loading: boolean = true
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['firstname', 'lastname', 'username', 'agency'];
  roles: []
  agencies: []
  user_data = new UserAccount()
  selected_role_id = ''
  selected_agency_id = ''
  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private messageService: MessageService) { }


  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit(): void {
    this.fetchData()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  fetchData() {
    const user_data$ = this.apiService.users_getAll()
    const roles$ = this.apiService.userRoles_getAll()
    const agencies$ = this.apiService.agencies_getAll()
    combineLatest([user_data$, roles$, agencies$]).subscribe(([user_data, roles, agencies]) => {
      this.dataSource.data = user_data
      this.roles = roles
      this.agencies = agencies
      this.loading = false
    })
  }
  onSelect(userInfos: UserAccount) {
    this.user_data.userId = userInfos.userId
    this.user_data.firstname = userInfos.firstname
    this.user_data.lastname = userInfos.lastname
    this.user_data.username = userInfos.username
  }
  onValidate() {
    this.user_data.agency_id = parseInt(this.selected_agency_id)
    this.user_data.roleId = parseInt(this.selected_role_id)

    if (this.user_data.agency_id && this.user_data.roleId) {
      console.log(this.user_data)
      this.apiService.user_update(this.user_data).subscribe((res) => {
        this.messageService.add({
          severity: 'success',
          summary: res.message
        })
      })
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Mercis de selectionner l'agence et le rôle de l'utilisateur"
      })
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(UserDetailsComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
