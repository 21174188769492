<div class="mat-elevation-z8">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Rechercher" #input>
    </mat-form-field>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="membername">
            <th mat-header-cell *matHeaderCellDef> Demandeur </th>
            <td mat-cell *matCellDef="let element">
                {{ element.customer ? element.customer.firstname + ' ' + element.customer.lastname :
                element.customerGroup.customerGroupName }}
            </td>
        </ng-container>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> Agence </th>
            <td mat-cell *matCellDef="let element"> {{element.agency?.agencyName}} </td>
        </ng-container>
        <ng-container matColumnDef="requestedAmount">
            <th mat-header-cell *matHeaderCellDef> Montant</th>
            <td mat-cell *matCellDef="let element"> {{element.requestedAmount}} </td>
        </ng-container>

        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef> Dévise </th>
            <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
        </ng-container>
        <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef> Taux</th>
            <td mat-cell *matCellDef="let element"> {{element.interestRate}} </td>
        </ng-container>
        <ng-container matColumnDef="decision">
            <th mat-header-cell *matHeaderCellDef> Décision </th>
            <td mat-cell *matCellDef="let element"> {{element.decision}} </td>
        </ng-container>
        <ng-container matColumnDef="period">
            <th mat-header-cell *matHeaderCellDef> Période </th>
            <td mat-cell *matCellDef="let element"> {{element.period}}</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef> Date demande </th>
            <td mat-cell *matCellDef="let element"> {{element.requestDate | date:'dd-MM-yyyy HH:mm'}}</td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram card">
                        <div class="example-element-position"> {{element.customer? element.customer.lastname:'Groupe
                            :'}}</div>
                        <div style="display: flex;justify-content: space-between;">
                            <div class="example-element-symbol"> {{element.customer?.firstname ||
                                element.customerGroup.customerGroupName}} </div>
                            <div class="infos">Agence : {{element.agency?.agencyName}}</div>
                        </div>

                        <div class="infos">Mode : {{element.reimbursementMode}} </div>
                        <div class="infos">Type : {{element.reimbursementType}} </div>
                        <div class="infos" *ngIf="element.customer">
                            <div *ngFor="let account of element.customer.accounts"><span
                                    *ngIf="account.accountType=='Compte EAV individuel'">Balance :{{account.currency +'
                                    : '+ account.accountSold}}</span></div>
                        </div>
                        <div class="infos" *ngIf="element.customerGroup">
                            <div *ngFor="let account of element.customerGroup.accounts"><span
                                    *ngIf="account.accountType=='Compte EAV de groupe'">Balance :{{account.currency +' :
                                    '+ account.accountSold}}</span></div>
                        </div>

                        <table mat-table [dataSource]="element?.customerGroup?.members" class="mat-elevation-z2"
                            *ngIf="element.customerGroup">
                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef> Nom & Postnom </th>
                                <td mat-cell *matCellDef="let member"> {{member.lastname +' '+member.firstname }} </td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Genre </th>
                                <td mat-cell *matCellDef="let member"> {{member.gender}} </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="weight">
                                <th mat-header-cell *matHeaderCellDef> Téléphone </th>
                                <td mat-cell *matCellDef="let member"> {{member.phone1}} </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="symbol">
                                <th mat-header-cell *matHeaderCellDef> Montant </th>
                                <td mat-cell *matCellDef="let member"><mat-form-field class="example-full-width">
                                        <mat-label>Montant (crédit)</mat-label>
                                        <input matInput type="number" name="creditAmount"
                                            [(ngModel)]="member.creditAmount">
                                        <mat-hint
                                            align="end">{{element.currency=='CDF'?((member.creditAmount/currencyRate).toFixed(2)|currency):member.creditAmount|currency}}</mat-hint>
                    
                                    </mat-form-field>
                            
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>



                    </div>
                    <div class="example-element-description card" style="margin-left: 4px;">
                        <form class="example-form">
                            <div>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Taux (du jour)</mat-label>
                                    <input matInput placeholder="1$=" name="convertedAmount" [value]="currencyRate"
                                        disabled="true">
                                </mat-form-field>
                            </div>
                            <mat-form-field class="example-full-width">
                                <mat-label>Montant (crédit)</mat-label>
                                <input matInput type="number" name="requestedAmount"
                                    [(ngModel)]="element.requestedAmount">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label>Montant (En dévise)</mat-label>
                                <input matInput name="convertedAmount"
                                    [value]="element.currency=='CDF'?((element.requestedAmount/currencyRate).toFixed(2)|currency):element.requestedAmount|currency"
                                    disabled="true">
                            </mat-form-field>

                            <table class="example-full-width" cellspacing="0">
                                <tr>
                                    <td><mat-form-field>
                                            <mat-label>{{element.interestRate}}</mat-label>
                                            <mat-select name="interestRate" [(ngModel)]="selectedRate">
                                                <mat-option *ngFor="let rate of rate_options"
                                                    [value]="rate.rateOption || element.interestRate">{{rate.rateOption
                                                    }}</mat-option>
                                            </mat-select>
                                        </mat-form-field></td>
                                    <td><mat-form-field class="example-full-width">
                                            <mat-label>{{element.period}}</mat-label>
                                            <input matInput name="period" type="number" [(ngModel)]="element.period">
                                        </mat-form-field></td>
                                </tr>
                                <tr>
                                    <td><mat-form-field>
                                            <mat-label>{{element.reimbursementMode}}</mat-label>
                                            <mat-select name="reimbursementMod" [(ngModel)]="selectedMode">
                                                <mat-option *ngFor="let reimbursementMode of reimbursementModes"
                                                    [value]="reimbursementMode.reimbursementMode">{{reimbursementMode.reimbursementMode}}</mat-option>
                                            </mat-select>
                                        </mat-form-field></td>
                                    <td><mat-form-field>
                                            <mat-label>{{element.reimbursementType}}</mat-label>

                                            <mat-select name="reimbursementType" [(ngModel)]="selectedType">
                                                <mat-option *ngFor="let reimbursementType of reimbursementTypes"
                                                    [value]="reimbursementType.reimbursementType">{{reimbursementType.reimbursementType}}</mat-option>
                                            </mat-select>
                                        </mat-form-field></td>
                                </tr>

                            </table>
                            <p-messages></p-messages>
                            <button mat-raised-button color="primary" (click)="onApprove(element)">Approuver</button>
                        </form>

                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>