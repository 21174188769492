export class Institution {
    institutionId: number
    institutionName: string
    description: string
    adress: string
    createdDate: Date
    country: Country
}
export class Country {
    countryId: number
    countryName: string
    countryCode: string
    countryIsoLetters: string
}
