import { Customer } from "./customer.model"

export class CustomerGroup{
   customerGroupName:string
   customerGroupManager: string
   activityNature: string
   activityAxis: string
   agencyId:number
}
export class GroupUpdate{
   customerGroupId:number
   members:Customer[]
}