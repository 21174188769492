import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements AfterViewInit {
  isloading = true
  title = "Liste de Membres"
  displayedColumns: string[] = ['agency','lastname', 'postname', 'firstname', 'dateOfBirth', 'dateCreated']
  dataSource = new MatTableDataSource();
  constructor(private router: Router, private readonly apiService: ApiService) {
    this.apiService.getAllAgencyMembers(apiService.getItem('username')).subscribe((customers) => {
      this.dataSource.data = customers
      this.isloading=false
    })
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  navigateTo() {
    this.router.navigateByUrl('member-details');

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}