<h2 mat-dialog-title>Décaissement Crédit</h2>
<mat-dialog-content class="mat-typography">
    <mat-form-field class="large" appearance="fill">
        <mat-label>Décaissemnt en attente</mat-label>
        <mat-select name="daDecaissement" [(ngModel)]="loan_request">
            <mat-option *ngFor="let decaissement of decaissements"
                [value]="decaissement">{{decaissement.customerGroup?.customerGroupName ||
                decaissement.customer?.lastname +' '+
                decaissement.customer?.firstname}}_{{decaissement.requestedAmount+'_'+decaissement.currency}}</mat-option>
        </mat-select>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Fermer</button>
    <button mat-button [mat-dialog-close]="true" (click)="decaisser()" cdkFocusInitial>Décaisser</button>
</mat-dialog-actions>