import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Delestage } from 'src/app/models/delestage.model';
import { Collect } from 'src/app/models/transactions.model';
import { Account } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.css']
})
export class CashierComponent implements AfterViewInit, OnInit {
  displayedColumns = ['transactionId', 'transaction_type', 'issuer', 'amount', 'currency', 'receiver', 'operator', 'date_created']
  dataSource = new MatTableDataSource()

  isLoadingResults = true
  transactionTypes: []
  cashierAccounts: Account[]
  coffreAccounts: Account[]
  pendingDelestages: Delestage[]

  selectedCollector = ''
  selected_eac_id = ''

  isCollecte: boolean;
  isDelestage: boolean
  isApprobation: boolean
  model = new Collect()
  delestage = new Delestage()
  cartAccount: Account
  cartAccounts: []
  sourceAccount: Account
  destinationAccount: Account

  constructor(private _liveAnnouncer: LiveAnnouncer, private apiService: ApiService, private messageService: MessageService, private router: Router) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnInit(): void {
    this.fetchData()
  }

  announceSortChange(sortState: Sort) {

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  fetchData() {

    const transactionTypes$ = this.apiService.transactionTypes_getAll()
    const cashierAcounts$ = this.apiService.cashierAccounts_get(this.apiService.getItem('username'))
    const cashierTransactions$ = this.apiService.userTransactions_getAll(this.apiService.getItem('username'))
    const cartAccounts$ = this.apiService.getCartAccounts(this.apiService.getItem('username'))
    const coffreAccounts$ = this.apiService.getAgencyCoffreAccounts(this.apiService.getItem('username'))
    const pendingDelestages$ = this.apiService.pendingDelestages_get(this.apiService.getItem('username'))


    combineLatest([transactionTypes$, cashierAcounts$, cashierTransactions$, cartAccounts$, coffreAccounts$, pendingDelestages$]).subscribe(([transactionTypes, cashierAccounts, cashierTransactions, cartAccounts, coffreAccounts, pendingDelestages]) => {
      this.transactionTypes = transactionTypes
      this.cashierAccounts = cashierAccounts
      this.dataSource.data = cashierTransactions
      this.cartAccounts = cartAccounts
      this.coffreAccounts = coffreAccounts
      this.pendingDelestages = pendingDelestages
      console.log(cashierTransactions)
      this.isLoadingResults = false
    })
  }
  onCollectorDeposit() {
    this.model.username = this.apiService.getItem('username')
    this.model.issuer = this.cartAccount.accountId
    this.model.currency = this.cartAccount.currency
    this.apiService.handleCollectorDeposit(this.model).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Transaction successful'
      })
      setTimeout(() => this.router.navigate(['cashier']), 2000);

    }, () => {
      this.messageService.add({
        severity: 'error',
        summary: 'Something went wrong'
      })
    })
  }
  createAccountDelestages() {
    this.delestage.username = this.apiService.getItem("username")
    if (this.sourceAccount.currency === this.destinationAccount.currency) {
      this.delestage.sourceAccountId = this.sourceAccount.accountId
      this.delestage.destinationAccountId = this.destinationAccount.accountId
      this.apiService.createAccountDelestages(this.delestage).subscribe((res) => {
        this.messageService.add({
          severity: "success",
          summary: "Délestage exécuté avec succès. Merci de suivre son approbation"
        })
        setTimeout(() => this.router.navigate(['cashier']), 2000);

      }, () => {
        this.messageService.add({
          severity: "error",
          summary: "Oups! something went wrong."
        })
      })
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Merci de lélester les comptes d'une même dévise"
      })
    }
  }
  delestageValidate() {
    if (this.delestage) {
      console.log(this.delestage)
      this.delestage.username = this.apiService.getItem('username')
      this.apiService.delestageValidate(this.delestage).subscribe((res) => {
        this.apiService.createAccountDelestages(this.delestage).subscribe((res) => {
          this.messageService.add({
            severity: "success",
            summary: "Approuvé avec succès"
          })
          setTimeout(() => this.router.navigate(['cashier']), 2000);

        }, () => {
          this.messageService.add({
            severity: "error",
            summary: "Huum! something went wrong."
          })
        })
      })
    }
  }
}
