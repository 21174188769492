<div class="nav-container">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container">
      <div class="navbar-brand logo"> </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav mr-auto" *ngIf="(auth.isAuthenticated$  | async)">
          <li class="nav-item">
            <a routerLink="/" class="nav-link">Home</a>
          </li>
          <!-- MEMBRES -->
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async)  && 
          (daAuth.userHasPermissions$(['read:customers'])  | async) === true" ngbDropdown>
            <a class="nav-link dropdown-toggle" ngbDropdownToggle data-toggle="dropdown">Membres</a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <div class="dropdown-header">
                Gestion de membres
              </div>
              <a routerLink="/customers" *ngIf="auth.isAuthenticated$ | async" class="dropdown-item dropdown-profile">
                Clients individuels
              </a>

              <a routerLink="/groups" *ngIf="(auth.isAuthenticated$ | async)" class="dropdown-item dropdown-profile">
                Groupes solidaires
              </a>
              <a routerLink="/entreprises" *ngIf="(auth.isAuthenticated$ | async)"
                class="dropdown-item dropdown-profile">
                Entreprises
              </a>
            </div>
          </li>
          <!-- CREDITS -->
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) && 
          (daAuth.userHasPermissions$(['read:loans'])  | async) === true" ngbDropdown>
            <a class="nav-link dropdown-toggle" ngbDropdownToggle data-toggle="dropdown">Crédits</a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <div class="dropdown-header">
                Gestion de crédits
              </div>
              <a routerLink="/member-request" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:loans'])  | async) === true" class="dropdown-item dropdown-profile">
                Demande individuel
              </a>
              <a routerLink="/group-request" *ngIf="(auth.isAuthenticated$ | async)&& 
              (daAuth.userHasPermissions$(['update:loans'])  | async) === true" class="dropdown-item dropdown-profile">
                Demande de groupe
              </a>
              <a routerLink="/approbation" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:loans'])  | async) === true" class="dropdown-item dropdown-profile">
                Aprobation crédit
              </a>

            </div>
          </li>
          <!-- EMBOURSEMENTS -->
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) && 
            (daAuth.userHasPermissions$(['read:loans'])  | async) === true" ngbDropdown>
            <a class="nav-link dropdown-toggle" ngbDropdownToggle data-toggle="dropdown">Remboursements</a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <div class="dropdown-header">
                Gestion de Remb.
              </div>
              <a routerLink="member-repayments" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:loans'])  | async) === true" class="dropdown-item dropdown-profile">
                Remb. individuels
              </a>
              <a routerLink="group-repayments" *ngIf="(auth.isAuthenticated$ | async)&& 
              (daAuth.userHasPermissions$(['update:loans'])  | async) === true" class="dropdown-item dropdown-profile">
                Remb. de groupes
              </a>
              <a routerLink="member-portfolio" *ngIf="auth.isAuthenticated$ | async"
                class="dropdown-item dropdown-profile">
                Portefeuille. CI
              </a>
              <a routerLink="group-portfolio" *ngIf="auth.isAuthenticated$ | async"
                class="dropdown-item dropdown-profile">
                Portefeuille. CG
              </a>
            </div>
          </li>
          <!-- SAVINGS -->
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) && 
          (daAuth.userHasPermissions$(['read:account'])  | async) === true" ngbDropdown>
            <a class="nav-link dropdown-toggle" ngbDropdownToggle data-toggle="dropdown">Comptes</a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <div class="dropdown-header">
                Gestion de comptes
              </div>
              <a routerLink="/carts" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:carts'])  | async) === true" class="dropdown-item dropdown-profile">
                EAC / EAL
              </a>
              <a routerLink="/eavi" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:accounts'])  | async) === true"
                class="dropdown-item dropdown-profile">
                EAV Individuel
              </a>
              <a routerLink="/eavg" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:accounts'])  | async) === true"
                class="dropdown-item dropdown-profile">
                EAV de groupe
              </a>
              <a routerLink="/dat" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:accounts'])  | async) === true"
                class="dropdown-item dropdown-profile">
                Compte DAT
              </a>
            </div>
          </li>
          <!-- CAISSE SAFE -->
          <li class="nav-item" *ngIf="auth.isAuthenticated$ | async" ngbDropdown>
            <a class="nav-link dropdown-toggle" ngbDropdownToggle data-toggle="dropdown">Caisses</a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <div class="dropdown-header">
                Gestion de la caisse
              </div>
              <a routerLink="/cashier" *ngIf="(auth.isAuthenticated$ | async) && 
                (daAuth.userHasPermissions$(['read:cash'])  | async) === true" class="dropdown-item dropdown-profile">
                Voir la caisse
              </a>
              <a routerLink="/coffre" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['read:coffre'])  | async) === true" class="dropdown-item dropdown-profile">
                Voir le coffre
              </a>
            </div>
          </li>
          <!-- COMPTABILITE -->
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) && 
          (daAuth.userHasPermissions$(['read:accounting'])  | async) === true" ngbDropdown>
            <a class="nav-link dropdown-toggle" ngbDropdownToggle data-toggle="dropdown">Comptabilité</a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <div class="dropdown-header">
                Gestion de comptabilité
              </div>

              <a routerLink="/account" *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:account'])  | async) === true"
                class="dropdown-item dropdown-profile">
                Compte
              </a>
              <a routerLink="/transactions"  *ngIf="(auth.isAuthenticated$ | async) && 
              (daAuth.userHasPermissions$(['update:transactions'])  | async) === true"class="dropdown-item dropdown-profile">
                Passation des écritures
              </a>
              <a routerLink="/close" class="dropdown-item dropdown-profile">
                Clôture de l'exercice
              </a>
            </div>
          </li>
          <!-- CONFIGURATION -->
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) && 
          (daAuth.userHasPermissions$(['read:settings'])  | async) === true" ngbDropdown>
            <a class="nav-link dropdown-toggle" ngbDropdownToggle data-toggle="dropdown">Configuration</a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <div class="dropdown-header">
                Configuration système
              </div>
              <a routerLink="/configuration" class="dropdown-item dropdown-profile">
                Configuration
              </a>
              <a routerLink="/users" class="dropdown-item dropdown-profile">
                Utilisateur
              </a>
              <a routerLink="/monitoring" class="dropdown-item dropdown-profile">
                Monitoring
              </a>
              <a routerLink="/cash-flow" class="dropdown-item dropdown-profile">
                Trésorerie
              </a>
            </div>
          </li>

          <!-- PROFILE -->
          <li class="nav-item" *ngIf="auth.isAuthenticated$ | async">
            <button class="btn btn-link p-0" style="min-width: unset" id="qsLogoutBtn" (click)="logout()">
              Log out
            </button>
          </li>
        </ul>

        <ul class="navbar-nav d-none d-md-block">
          <!-- Login button: show if NOT authenticated -->
          <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) === false">
            <button id="qsLoginBtn" class="btn btn-primary btn-margin" (click)="loginWithRedirect()">
              Log in
            </button>
          </li>

          <!-- Fullsize dropdown: show if authenticated -->
          <li class="nav-item dropdown" *ngIf="auth.user$ | async as user" ngbDropdown>
            <a ngbDropdownToggle class="nav-link dropdown-toggle" id="profileDropDown" data-toggle="dropdown">
              <!-- Profile image should be set to the profile picture from the id token -->
              <img [src]="user.picture" alt="Profile picture" class="nav-user-profile rounded-circle"
                style="width: 75px;" />
            </a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <!-- Show the user's full name from the id token here -->
              <div class="dropdown-header">
                {{ user.name }}
              </div>
              <a routerLink="/profile" class="dropdown-item dropdown-profile">
                <fa-icon [icon]="faUser" class="mr-3"></fa-icon> Profile
              </a>
              <button (click)="logout()" class="btn btn-link dropdown-item">
                <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon> Log out
              </button>
            </div>
          </li>
          <!-- /Fullsize dropdown -->
        </ul>

        <!-- Responsive login button: show if NOT authenticated -->
        <ul class="navbar-nav d-md-none" *ngIf="(auth.isAuthenticated$ | async) === false">
          <button class="btn btn-primary btn-block" id="qsLoginBtn" (click)="loginWithRedirect()">
            Log in
          </button>

        </ul>
        <!-- /Responsive login button -->

        <!-- Responsive profile dropdown: show if authenticated -->
        <ul class="navbar-nav d-md-none justify-content-between" *ngIf="auth.user$ | async as user"
          style="min-height: 170px;">
          <li class="nav-item">
            <span class="user-info">
              <!-- Profile image should be set to the profile picture from the id token -->
              <img alt="Profile picture" class="nav-user-profile d-inline-block rounded-circle mr-3"
                style="width: 75px;" [src]="user.picture" />
              <!-- Show the user's full name from the id token here -->
              <h6 class="d-inline-block">{{ user.name }}</h6>
            </span>
          </li>
          <li>
            <fa-icon [icon]="faUser" class="mr-3"></fa-icon>
            <a routerLink="/profile">Profile</a>
          </li>

          <li>
            <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon>
            <button class="btn btn-link p-0" (click)="logout()">
              Log out
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>