
export class Account {
    accountId: number

    accountIndice: string

    accountLibelle: string

    accountNumber: string

    accountType: string

    currency: string

    accountSold: number

    accountOption: string

    createdDate: Date

    updatedDate: Date

    deletedDate: Date
}
 export class AccountType{
    accountTypeId:number
    accountType:string
 }
 export class DatAccout{
    accountIndice: string
    accountLibelle: string
    accountType: string
    currency: string
    period:number
    interestRate:number
    customerId:number
    agencyId:number

 }
 