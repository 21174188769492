import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import config from '../../../../auth_config.json'

import { combineLatest } from 'rxjs';
import { Currency, ReimbursementMod, ReimbursementType } from 'src/app/models/currency.model';
import { LoanType } from 'src/app/models/loanType.model';
import { ApiService } from 'src/app/services/api.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { UserAccount } from 'src/app/models/user.model';



@Component({
  selector: 'app-member-request',
  templateUrl: './member-request.component.html',
  styleUrls: ['./member-request.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MemberRequestComponent implements AfterViewInit {
  isLoading = true
  value1: number = 1;
  customerId: number
  dataSource = new MatTableDataSource()
  columnsToDisplay = ['lastname', 'postname', 'firstname', 'gender', 'agency'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null;

  loanTypes: LoanType[]
  currencies: Currency[]
  reimbursementModes: ReimbursementMod[]
  reimbursementTypes: ReimbursementType[]
  usdToCdf: number
  loanManagers = new UserAccount()

  requestedAmountFormGroup: FormGroup;
  currencyFormGroup: FormGroup;
  interestRateFormGroup: FormGroup;
  periodFormGroup: FormGroup;
  reimbursementModeFormGroup: FormGroup;
  reimbursementTypeFormGroup: FormGroup;
  loanManagerFormGroup: FormGroup

  errorMessage: string | null = null;
  API_URI=`${config.apiUri}/api/getFile/`


  constructor(private _formBuilder: FormBuilder, private readonly apiService: ApiService, private messageService: MessageService, private router: Router) { }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit() {
    this.fetchData()

    this.requestedAmountFormGroup = this._formBuilder.group({
      requestedAmount: ['', Validators.required],
    });

    this.currencyFormGroup = this._formBuilder.group({
      currency: ['', Validators.required],
    });
    this.interestRateFormGroup = this._formBuilder.group({
      interestRate: ['', Validators.required],
    });
    this.periodFormGroup = this._formBuilder.group({
      period: ['', Validators.required],
    });
    this.reimbursementModeFormGroup = this._formBuilder.group({
      reimbursementMode: ['', Validators.required],
    });
    this.reimbursementTypeFormGroup = this._formBuilder.group({
      reimbursementType: ['', Validators.required],
    });
    this.loanManagerFormGroup = this._formBuilder.group({
      loanManager: ['', Validators.required],
    });

    // Get the value of the firstFormGroup.group property.
    const firstFormGroupValue = this.requestedAmountFormGroup.value;
  }
  onSend(customerId: number, agencyId: number) {
    if (this.isFormValid()) {
      const data: { [key: string]: any } = {
        requestedAmount: this.requestedAmountFormGroup.value,
        currency: this.currencyFormGroup.value,
        interestRate: this.interestRateFormGroup.value,
        period: this.periodFormGroup.value,
        reimbursementMode: this.reimbursementModeFormGroup.value,
        reimbursementType: this.reimbursementTypeFormGroup.value,
        username: this.loanManagerFormGroup.value
      }
      const flatenData = {
        requestedAmount: data.requestedAmount.requestedAmount,
        currency: data.currency.currency,
        interestRate: data.interestRate.interestRate,
        period: data.period.period,
        reimbursementMode: data.reimbursementMode.reimbursementMode,
        reimbursementType: data.reimbursementType.reimbursementType,
        username: data.username.loanManager,
        requestFees: (this.usdToCdf * 10),
        customerId,
        agencyId,
        currencyRate: this.usdToCdf
      }
      this.apiService.customerRequest_send(flatenData).subscribe((res) => {
        this.messageService.add({
          severity: 'info',
          summary: res.message
        })
        this.resetForm();
        setTimeout(() => this.router.navigate(['member-request']), 3000);
      })
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Veuillez remplir tous les champs'
      })
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  isFormValid(): boolean {
    return (this.requestedAmountFormGroup.valid &&
      this.currencyFormGroup.valid &&
      this.interestRateFormGroup.valid &&
      this.periodFormGroup.valid &&
      this.reimbursementModeFormGroup.valid &&
      this.reimbursementTypeFormGroup.valid)
  }
  fetchData() {
    const loanTypes$ = this.apiService.loanTypes_getAll()
    const members$ = this.apiService.getAllAgencyMembers(this.apiService.getItem('username'))
    const reimbursementModes$ = this.apiService.reimbursementModes_getAll()
    const reimbursementTypes$ = this.apiService.reimbursementTypes_getAll()
    const currencies$ = this.apiService.currencies_getAll()
    const loanManagers$ = this.apiService.agencyLoanManagers_getAll(this.apiService.getItem('username'))

    combineLatest([loanTypes$, members$, reimbursementModes$, reimbursementTypes$, currencies$, loanManagers$]).subscribe(([loanTypes, members, reimbursementModes, reimbursementTypes, currencies, loanManagers]) => {
      this.dataSource.data = members
      this.loanTypes = loanTypes
      this.reimbursementModes = reimbursementModes
      this.reimbursementTypes = reimbursementTypes
      this.currencies = currencies
      this.usdToCdf = parseFloat(this.apiService.getItem('currencyRate'))
      this.loanManagers = loanManagers
      console.log(members)
      this.isLoading = false

    })

  }
  resetForm() {
    this.requestedAmountFormGroup.reset();
    this.currencyFormGroup.reset()
    this.interestRateFormGroup.reset()
    this.periodFormGroup.reset()
    this.reimbursementModeFormGroup.reset()
    this.reimbursementTypeFormGroup.reset()
  }

}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

