import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css']
})
export class MonitoringComponent {
  loading = true
  displayedColumns: string[] = ['agency', 'loanManager', 'cdfPendingRepaymentCount', 'cdfPendingRepaymentSum', 'usdPendingRepaymentCount', 'usdPendingRepaymentSum', 'cdfWellPaidLoanRequestCount', 'cdfWellPaidLoanRequestVolume', 'usdWellPaidLoanRequestCount', 'usdWellPaidLoanRequestVolume', 'cdfPart1Count', 'cdfPart1Volume', 'usdPart1Count', 'usdPart1Volume', 'cdfPart30Count', 'cdfPart30Volume', 'usdPart30Count', 'usdPart30Volume', 'cdfPart90Count', 'cdfPart90Volume', 'usdPart90Count', 'usdPart90Volume', 'cdfPart180Count', 'cdfPart180Volume', 'usdPart180Count', 'usdPart180Volume', 'cdfPart360Count', 'cdfPart360Volume', 'usdPart360Count', 'usdPart360Volume'];
  columnsToDisplay: string[] = ['agency', 'collector', 'soldCartNumber', 'soldCartAmount', 'collectedEacAmount', 'collectedEavAmount'];

  dataSource1 = new MatTableDataSource()
  dataSource2 = new MatTableDataSource()
  dataSource3 = new MatTableDataSource()


  constructor(private apiService: ApiService) { }
  ngOnInit(): void {
    this.fetchData()
  }
  fetchData() {

    const customerPortfolio$ = this.apiService.getMicrodevCustomerPortfolio()
    const groupPortfolio$ = this.apiService.getMicrodevGroupPortfolio()
    const collectorsPortfolio$ = this.apiService.getMicrodevCollectorsPortfolio()
    combineLatest([customerPortfolio$, groupPortfolio$, collectorsPortfolio$]).subscribe(([customerPortfolios, groupPortfolios, collectorsPortfolios]) => {
      this.dataSource1.data = customerPortfolios
      this.dataSource2.data = groupPortfolios
      this.dataSource3.data = collectorsPortfolios
      this.loading = false

    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
}
