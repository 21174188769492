<section class="example-container mat-elevation-z8" tabindex="0">
  <mat-form-field stiky>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
  </mat-form-field>
  <mat-spinner *ngIf="loading"></mat-spinner>
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="agency" sticky>
      <th mat-header-cell *matHeaderCellDef> Agence </th>
      <td mat-cell *matCellDef="let data"> {{data.agencyname}} </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="loanManager"sticky>
      <th mat-header-cell *matHeaderCellDef> Gestionnaire </th>
      <td mat-cell *matCellDef="let manager"> {{manager.loanmanager}} </td>
    </ng-container>

    <!-- Count Column -->
    <ng-container matColumnDef="cdfPendingRepaymentCount">
      <th mat-header-cell *matHeaderCellDef> Nb.CI. (cdf) </th>
      <td mat-cell *matCellDef="let cdfPRC"> {{cdfPRC.groupwithcdfpendingrepaymentcount}} </td>
    </ng-container>

    <!-- First Column -->
    <ng-container matColumnDef="usdPendingRepaymentCount">
      <th mat-header-cell *matHeaderCellDef> Nb.CP. (usd) </th>
      <td mat-cell *matCellDef="let usdPRC"> {{usdPRC.groupwithusdpendingrepaymentcount}} </td>
    </ng-container>
    <!-- Last Column -->
    <ng-container matColumnDef="cdfPendingRepaymentSum">
      <th mat-header-cell *matHeaderCellDef> Volume </th>
      <td mat-cell *matCellDef="let cdfPRS"> {{cdfPRS.groupwithcdfpendingrepaymentsum|currency:'CDF'}} </td>
    </ng-container>
     <!-- Count Column -->
     <ng-container matColumnDef="cdfWellPaidLoanRequestCount">
      <th mat-header-cell *matHeaderCellDef> Nb.CS. (cdf) </th>
      <td mat-cell *matCellDef="let cdfWellPaidCount"> {{cdfWellPaidCount.cdfwellpaidloanrequestcount}} </td>
    </ng-container>
     <!-- Last Column -->
     <ng-container matColumnDef="cdfWellPaidLoanRequestVolume">
      <th mat-header-cell *matHeaderCellDef> Volume.</th>
      <td mat-cell *matCellDef="let cdfWellPaidVolume"> {{cdfWellPaidVolume.cdfwellpaidloanrequestvolume|currency:'CDF'}} </td>
    </ng-container>
     <!-- First Column -->
     <ng-container matColumnDef="usdWellPaidLoanRequestCount">
      <th mat-header-cell *matHeaderCellDef> Nb.CS. (usd) </th>
      <td mat-cell *matCellDef="let usdWellPaidCount"> {{usdWellPaidCount.usdwellpaidloanrequestcount}} </td>
    </ng-container>
   
    <!-- Even Column -->
    <ng-container matColumnDef="usdWellPaidLoanRequestVolume">
      <th mat-header-cell *matHeaderCellDef> Volume.</th>
      <td mat-cell *matCellDef="let usdWellPaidVolume"> {{usdWellPaidVolume.usdwellpaidloanrequestvolume|currency}} </td>
    </ng-container>
    <!-- Even Column -->
    <ng-container matColumnDef="usdPendingRepaymentSum">
      <th mat-header-cell *matHeaderCellDef> Volume </th>
      <td mat-cell *matCellDef="let usdPRS"> {{usdPRS.groupwithusdpendingrepaymentsum|currency}} </td>
    </ng-container>
    <!-- Odd Column -->
    <ng-container matColumnDef="cdfPart1Count">
      <th mat-header-cell *matHeaderCellDef> Part1. (cdf) </th>
      <td mat-cell *matCellDef="let cdfPart1"> {{cdfPart1.groupincdfpart1count}} </td>
    </ng-container>
    <!-- Odd Column -->
    <ng-container matColumnDef="usdPart1Count">
      <th mat-header-cell *matHeaderCellDef> Part.1 (usd) </th>
      <td mat-cell *matCellDef="let usdPart1"> {{usdPart1.groupinusdpart1count}} </td>
    </ng-container>
    <!-- Odd Column -->
    <ng-container matColumnDef="cdfPart1Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. </th>
      <td mat-cell *matCellDef="let cdfPart1Volume"> {{cdfPart1Volume.groupincdfpart1volume}} </td>
    </ng-container>
    <!-- Odd Column -->
    <ng-container matColumnDef="usdPart1Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. </th>
      <td mat-cell *matCellDef="let usdPart1Volume"> {{usdPart1Volume.groupinusdpart1volume}} </td>
    </ng-container>
    <!-- Odd Column -->
    <ng-container matColumnDef="cdfPart30Count">
      <th mat-header-cell *matHeaderCellDef> Part30. (cdf) </th>
      <td mat-cell *matCellDef="let cdfPart30Count"> {{cdfPart30Count.groupincdfpart30count}} </td>
    </ng-container>
    <!-- Odd Column -->
    <ng-container matColumnDef="usdPart30Count">
      <th mat-header-cell *matHeaderCellDef> Part30. (usd) </th>
      <td mat-cell *matCellDef="let usdPart30Count"> {{usdPart30Count.groupinusdpart30count}} </td>
    </ng-container>
    <!-- Odd Column -->
    <ng-container matColumnDef="cdfPart30Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. (cdf) </th>
      <td mat-cell *matCellDef="let cdfPart30Volume"> {{cdfPart30Volume.groupincdfpart30volume}} </td>
    </ng-container>
    <!-- Odd Column -->
    <ng-container matColumnDef="usdPart30Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. </th>
      <td mat-cell *matCellDef="let usdPart30Volume"> {{usdPart30Volume.groupincdfpart30volume}} </td>
    </ng-container>
   
    <ng-container matColumnDef="cdfPart90Count">
      <th mat-header-cell *matHeaderCellDef> Part90. (cdf) </th>
      <td mat-cell *matCellDef="let cdfPart90Count"> {{cdfPart90Count.groupincdfpart90count}} </td>
    </ng-container>
    <ng-container matColumnDef="usdPart90Count">
      <th mat-header-cell *matHeaderCellDef> Part90. (usd) </th>
      <td mat-cell *matCellDef="let usdPart90Count"> {{usdPart90Count.groupinusdpart90count}} </td>
    </ng-container>
    <ng-container matColumnDef="cdfPart90Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. </th>
      <td mat-cell *matCellDef="let cdfPart90Volume"> {{cdfPart90Volume.groupincdfpart90volume}} </td>
    </ng-container>
    <ng-container matColumnDef="usdPart90Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. </th>
      <td mat-cell *matCellDef="let usdPart90Volume"> {{usdPart90Volume.groupincdfpart90volume}} </td>
    </ng-container>
   
    <ng-container matColumnDef="cdfPart180Count">
      <th mat-header-cell *matHeaderCellDef> Part180. (cdf) </th>
      <td mat-cell *matCellDef="let cdfPart180Count"> {{cdfPart180Count.groupincdfpart180count}} </td>
    </ng-container>
    <ng-container matColumnDef="usdPart180Count">
      <th mat-header-cell *matHeaderCellDef> Part180. (usd) </th>
      <td mat-cell *matCellDef="let usdPart180Count"> {{usdPart180Count.groupinusdpart180count}} </td>
    </ng-container>
    <ng-container matColumnDef="cdfPart180Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. </th>
      <td mat-cell *matCellDef="let cdfPart180Volume"> {{cdfPart180Volume.groupincdfpart180volume}} </td>
    </ng-container>
    <ng-container matColumnDef="usdPart180Volume">
      <th mat-header-cell *matHeaderCellDef> Volume.</th>
      <td mat-cell *matCellDef="let usdPart180Volume"> {{usdPart180Volume.groupinusdpart180volume}} </td>
    </ng-container>
    <ng-container matColumnDef="cdfPart360Count">
      <th mat-header-cell *matHeaderCellDef> Part360. (cdf) </th>
      <td mat-cell *matCellDef="let cdfPart360Count"> {{cdfPart360Count.groupincdfpart360count}} </td>
    </ng-container>
    <ng-container matColumnDef="usdPart360Count">
      <th mat-header-cell *matHeaderCellDef> Part360. (usd) </th>
      <td mat-cell *matCellDef="let usdPart360Count"> {{usdPart360Count.groupinusdpart360count}} </td>
    </ng-container>
    <ng-container matColumnDef="cdfPart360Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. </th>
      <td mat-cell *matCellDef="let cdfPart360Volume"> {{cdfPart360Volume.groupincdfpart360volume}} </td>
    </ng-container>
    <ng-container matColumnDef="usdPart360Volume">
      <th mat-header-cell *matHeaderCellDef> Volume. </th>
      <td mat-cell *matCellDef="let usdPart360Volume"> {{usdPart360Volume.groupinusdpart360volume}} </td>
    </ng-container>

    <!-- Star Column -->
    <ng-container matColumnDef="star" stickyEnd>
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon>more_vert</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>