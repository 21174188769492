import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agency, UserAccount, UserCollect } from '../models/user.model';
import config from '../../../auth_config.json'
import { Institution } from '../models/institution.model';
import { CashAccount } from '../models/cash.model';
import { Customer, MemberRepayment } from '../models/customer.model';
import { CustomerGroup } from '../models/group.model';
import { LoanType } from '../models/loanType.model';
import { CustomerRequest, GroupRequestDto } from '../models/customerRequest.dto';
import { LoanRequest } from '../models/loanRequest.model';

import { Cart, Transaction } from '../models/cartType.model';
import { CartCollect, Collect, Transactions } from '../models/transactions.model';
import { Account } from '../models/user.model';
import { LoanRequestData } from '../models/loanRequestData';
import { RepaymentDetails } from '../models/loanRepayment.model';
import { DatAccout } from '../models/account.model';
import { Delestage} from '../models/delestage.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) { }

  ping$() {
    return this.http.get(`${config.apiUri}/api/users`);
  }

  public setItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }
  public getItem(key: string): string {
    return JSON.parse(localStorage.getItem(key));
  }
  public clearStorage() {
    localStorage.clear()
  }

  getUser_permisions(): Observable<string[]> {
    return this.http.post<string[]>(`${config.apiUri}/api/authz/scopes`, {})
  }
  currentUser_get(): Observable<any> {
    const currentUser = this.getItem('username')
    return this.http.get<UserAccount>(`${config.apiUri}/api/users/${currentUser}`)
  }
  agencies_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies`);
  }
  getUserAgency(username): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies/${username}`)
  }
  cities_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/cities`)
  }
  filetypes_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/file-types`)
  }
  gender_options_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/genders`)
  }
  fileUpload(
    filesToUpload: any[],
    customerId: number,
    category: string
  ): Observable<any> {
    let url = `${config.apiUri}/api/upload`;

    const formData: FormData = new FormData();

    formData.append('customerid', JSON.stringify(customerId));
    formData.append('category', JSON.stringify(category));

    for (let file of filesToUpload) {
      console.log('adding ', file);
      formData.append('myfile', file, file.name);
    }

    console.log('formData', formData);

    let headers = new HttpHeaders();

    return this.http.post(url, formData, { headers: headers });
  }
  get_userAgency(): Observable<any> {
    const currentUser = this.getItem('username');
    return this.http.get<any>(
      `${config.apiUri}/api/agencies/${currentUser}`
    );
  }
  institution_getOne(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/institution`)
  }

  accountTypes_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/account-types`)
  }
  account_create(account_data: Account): Observable<any> {
    return this.http.post<Account>(`${config.apiUri}/api/accounts`, account_data)
  }
  createDatAccount(account_data: DatAccout): Observable<any> {
    return this.http.post<DatAccout>(`${config.apiUri}/api/accounts`, account_data)
  }
  accounts_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/accounts/all`)
  }
  sourceAccounts_get(customerId: number): Observable<any> {
    return this.http.get(`${config.apiUri}/api/accounts/${customerId}/source`)
  }
  transaction_commit(transaction_data: Transactions): Observable<any> {
    return this.http.post<Transactions>(`${config.apiUri}/api/transactions`, transaction_data)
  }
  approvedRequests_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/requests/approved`)
  }
  loanRequest_validate(loanRequest: LoanRequest): Observable<any> {
    return this.http.post<LoanRequest>(`${config.apiUri}/api/requests/validate`, loanRequest)
  }
  getInProgressLoanRequests(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/requests/${username}/in_progress`);
  }
  getCustomersInProgressRepayments(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/requests/${username}/customers`);
  }
  getgroupsInProgressRepayments(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/requests/${username}/groups`);
  }
  agencyLoanManagers_getAll(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/users/${username}/agency/loan-managers`);
  }
  getTodayDuedateLoanRequests(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/requests/${username}/today`);
  }
  getFutureLoanRequest(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/requests/${username}future`);
  }
  getOverdueLoanRequests(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/requests/${username}/overdue`);
  }
  institution_update(institutionData: Institution): Observable<Institution> {
    return this.http.put<Institution>(`${config.apiUri}/api/institution/`, institutionData)
  }
  agency_create(newAgency: Agency): Observable<any> {
    return this.http.post<Agency>(`${config.apiUri}/api/agencies`, newAgency);
  }
  accountType_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/account-types`)
  }
  accountType_getOne(accountType: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/account-types/${accountType}`)
  }
  accountOptions_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/account-options`)
  }
  users_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/users`)
  }
  agencyCollectors_getAll(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies/${username}/collectors`)
  }
  loanTypes_getAll(): Observable<any> {
    return this.http.get<LoanType>(`${config.apiUri}/api/loan-types`)
  }
  cashAccount_create(cashAccountData: CashAccount): Observable<any> {
    return this.http.post<CashAccount>(`${config.apiUri}/api/accounts`, cashAccountData)
  }
  userRoles_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/roles`)
  }
  user_update(userData: UserAccount): Observable<any> {
    return this.http.put(`${config.apiUri}/api/users`, userData)
  }

  member_save(data): Observable<any> {
    return this.http.post(`${config.apiUri}/api/customers`, data);
  }
  member_update(data: Customer): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.put<Customer>(`${config.apiUri}/api/customers/${data.customerId}`, data, { headers: headers });
  }
  members_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/customers`);
  }
  residences_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/residences`);
  }
  organisations_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/organisations`);
  }
  levels_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/levels`);
  }
  customerTypes_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/customer-types`);
  }
  getAllAgencyGroups(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/groups/${username}/agencies`);
  }
  groups_save(data: CustomerGroup): Observable<any> {
    return this.http.post<CustomerGroup>(`${config.apiUri}/api/groups`, data);
  }
  groupMembers_save(customerGroupId: number, grouMembers: any[]): Observable<any> {
    return this.http.post(`${config.apiUri}/api/groups/${customerGroupId}/members`, grouMembers);
  }
  getAllAgencyMembers(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/customers/${username}/agencies`);

  }
  getAgencyLoanManagers(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/users/${username}/agencies`);
  }
  reimbursementModes_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/reimbursement-mode`)
  }
  reimbursementTypes_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/reimbursement-type`)
  }
  usdToCdf(amount: number): Observable<any> {
    return this.http.get(`${config.apiUri}/api/currency-converter/${amount}`)
  }
  customerRequest_send(requestData: CustomerRequest): Observable<any> {
    return this.http.post<CustomerRequest>(`${config.apiUri}/api/requests`, requestData)
  }
  groupRequest_send(requestData: GroupRequestDto): Observable<any> {
    return this.http.post<LoanRequest>(`${config.apiUri}/api/requests`, requestData)
  }
  getUnapprovedRequests(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/requests/unapproved`)
  }
  rateOptios_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/rate-options`)
  }
  customerRequest_approve(requestData: LoanRequest): Observable<any> {
    return this.http.post(`${config.apiUri}/api/requests/approve`, requestData)
  }
  groupRequest_approve(requestData: LoanRequest): Observable<any> {
    return this.http.post<LoanRequest>(`${config.apiUri}/api/requests/approve`, requestData)
  }
  getMembersEavAccounts(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/customers/${username}/eav`);

  }
  getGroupsEavAccounts(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/groups/${username}/eav`);

  }
  getMemberDatAccounts(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/customers/${username}/dat`);

  }
  cartTypes_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/cart-types`);
  }
  collectors_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/users`);

  }
  currencies_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/currencies`);
  }
  newCart_save(cartdata: Cart): Observable<any> {
    return this.http.post<Cart>(`${config.apiUri}/api/carts`, cartdata);
  }
  getAgencyCarts(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies/${username}/carts`);
  }
  getUserCarts(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/users/${username}/carts`)
  }
  getUserCollects(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/users/${username}/collects`)
  }
  transactionTypes_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/transaction-types`);
  }
  getAllCollectTypes(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/collects`);
  }
  saveMemberCart(cart_data: CartCollect): Observable<any> {

    return this.http.post(`${config.apiUri}/api/transactions`, cart_data);
  }
  transaction_handle(transactionData: Transaction): Observable<any> {
    return this.http.post<Transaction>(`${config.apiUri}/api/transactions`, transactionData)
  }
  cashierAccounts_get(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/accounts/${username}/cashier`);
  }
  userTransactions_getAll(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/transactions/${username}`);
  }
  handleCartCollect(collector: number, collectDate: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/transactions/${collector}/${collectDate}`)
  }
  userCollect_validate(userCollect: UserCollect): Observable<any> {
    const collect_data = {
      collectorId: userCollect.collectorId,
      collectDate: userCollect.collectDate
    }
    return this.http.post<UserCollect>(`${config.apiUri}/api/transactions/validate`, collect_data)
  }
  pendingDelestages_get(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/delestage/${username}`)
  }
  cashesAccounts_getAll(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/accounts/cashes`)
  }
  getAgencyCoffreAccounts(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/accounts/coffres/${username}`)
  }
  getAllAgencyCoffreAccounts(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies/coffres`)
  }
  getAgencyCashAccounts(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/accounts/cashes/${username}`)

  }

  userDetails_save(data: UserAccount): Observable<any> {
    return this.http.post<UserAccount>(
      `${config.apiUri}/api/users`,
      data
    );
  }
  getCartAccounts(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/accounts/${username}/carts`)

  }

  handleGroupRepayment(
    currencyRate: number,
    customerGroupId: number,
    loanRequests: LoanRequestData[],
    members: MemberRepayment[],
    username: string
  ): Observable<any> {
    const repaymentData = {
      currencyRate,
      customerGroupId,
      loanRequests,
      members,
      username
    }
    return this.http.post(`${config.apiUri}/api/repayment`, repaymentData);
  }
  handleCustomerRepayment(
    currencyRate: number,
    customerId: number,
    loanRequests: LoanRequestData[],
    repaymentDetails: RepaymentDetails,
    username: string): Observable<any> {
    const repaymentData = {
      currencyRate,
      customerId,
      loanRequests,
      repaymentDetails,
      username
    }
    return this.http.post(`${config.apiUri}/api/repayment`, repaymentData);
  }
  getAgencyGroupPortfolio(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies/${username}/groups/portfolio`)
  }
  getAgencyCustomerPortfolio(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies/${username}/customers/portfolio`)
  }
  getMicrodevGroupPortfolio(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/institution/portfolio/groups`)
  }
  getMicrodevCustomerPortfolio(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/institution/portfolio/customers`)
  }
  getMicrodevCollectorsPortfolio(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/institution/portfolio/users`)
  }
  getAgencySalesAgents(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies/${username}/collectors`)
  }
  handleCollectorDeposit(depositData: Collect) {
    return this.http.post<Collect>(`${config.apiUri}/api/transactions/eac`, depositData)
  }
  getAggragatedData(username: string): Observable<any> {
    return this.http.get(`${config.apiUri}/api/agencies/${username}/aggragate`)

  }
  createAccountDelestages(delestageData: Delestage): Observable<any> {
    return this.http.post<Delestage>(`${config.apiUri}/api/delestage`, delestageData)
  }
  delestageValidate(data:Delestage): Observable<any> {
    return this.http.post<Delestage>(`${config.apiUri}/api/delestage/validate`, data)
  }
}
