import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AccountDialogComponent } from '../account-dialog/account-dialog.component'
import { Agency, CustomerType, FileType, Gender_options, Organisation, Residence, StudyLevel } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { combineLatest } from 'rxjs';
import { Customer } from 'src/app/models/customer.model';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})

export class CustomerDetailsComponent implements AfterViewInit {
  title = "Detail du membre"
  loading: boolean = true

  userAgency: Agency
  residences: Residence[]
  organisations: Organisation[]
  levels: StudyLevel[]
  customerTypes: CustomerType[]
  filetypes: FileType[]
  genders: Gender_options[]
  agencyCollectors: []

  selected_agency_id = ''
  selected_residence_id = ''
  selected_organisation_id = ''
  selected_study_level_id = ''
  selected_customer_type_id = ''
  selectedChildrenNumber = ''
  specimenImage_url: any
  identityImage_url: any
  profilImage_url: any

  model: Customer = new Customer()

  displayedColumns = ['membername', 'Gender', 'phone', 'agency', 'memberType'];

  dataSource = new MatTableDataSource();

  constructor(public dialog: MatDialog, private readonly apiService: ApiService,
    private readonly router: Router, private messageService: MessageService,
  ) { }
  ngOnInit() {
    this.fetchData()
  }
  openDialog() {
    const dialogRef = this.dialog.open(AccountDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  onProfilUpload(event) {
    for (let file of event.files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.profilImage_url = e.target.result; // Assign the image data URL
        this.model.specimenImage = file;

      };

      reader.readAsDataURL(file);
      this.model.profilImage = file;
    }
  }
  onSpecimenUpload(event) {
    for (let file of event.files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.specimenImage_url = e.target.result; // Assign the image data URL
        this.model.specimenImage = file;

      };

      reader.readAsDataURL(file); // Read the file as a data URL
    }
  }
  onIdentityUpload(event) {
    for (let file of event.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.identityImage_url = e.target.result; // Assign the image data URL

      };

      reader.readAsDataURL(file); // Read the file as a data URL
      this.model.identityPieceImage = file;
    }
  }
  openSnackBar(message: string, action: string) {

    this.messageService.add({
      severity: action,
      summary: action,
      detail: message,
    });
  }

  formIsValid() {
    let success = false
    success =
      this.model.gender && //gender must be selected before SAVE
      this.model.profilImage &&
      this.model.identityPieceImage &&
      this.model.specimenImage &&
      this.model.firstname != undefined &&
      this.model.firstname.length > 2 &&
      this.model.lastname != undefined &&
      this.model.lastname.length > 2 &&
      this.model.street != undefined &&
      this.model.street.length > 2 &&
      this.model.phone1 != undefined && this.model.phone1.length > 5 &&
      this.model.identityPiece_type != '' &&
      this.model.document_num != '' &&
      this.model.nationality != ''

    return success

  }
  onMemberSave() {
    if (this.model != undefined) {
      this.model.agency_id = this.userAgency.agencyId
      const formData = new FormData();
      formData.append('profileImage', this.model.profilImage);
      formData.append('identityPieceImage', this.model.identityPieceImage);
      formData.append('specimenImage', this.model.specimenImage);
      formData.append('gender', this.model.gender);
      formData.append('birth_place', this.model.birth_place);
      formData.append('identityPiece_expiryDate', this.model.identityPiece_expiryDate.toISOString());
      formData.append('document_num', this.model.document_num);
      formData.append('identityPiece_type', this.model.identityPiece_type);
      formData.append('agency_id', this.model.agency_id.toString());
      formData.append('street', this.model.street);
      formData.append('email', this.model.email);
      formData.append('phone2', this.model.phone2);
      formData.append('phone1', this.model.phone1);
      formData.append('birthDate', this.model.birthDate.toISOString());
      formData.append('lastname', this.model.lastname);
      formData.append('postname', this.model.postname);
      formData.append('firstname', this.model.firstname);
      formData.append('nationality', this.model.nationality);

      this.apiService.member_save(formData).subscribe(res => {
        this.model = res.customer
        this.openSnackBar("Membre crée avec succè", "success")
        this.loading = false;
      });
    }
  }
  onComplete() {
    this.loading = true
    if (this.model.customerId != undefined) {
      this.model.customerType = this.selected_customer_type_id
      this.model.residence_type = this.selected_residence_id
      this.model.organisation = this.selected_organisation_id
      this.model.studyLevel = this.selected_study_level_id
      this.apiService.member_update(this.model).subscribe(res => {
        this.openSnackBar(res.message, "success")
        this.loading = false;
        setTimeout(() => this.router.navigate(['customers']), 2000);
      })

    }
  }
  fetchData() {

    const userAgency$ = this.apiService.getUserAgency(this.apiService.getItem('username'))
    const filetypes$ = this.apiService.filetypes_getAll();
    const genders$ = this.apiService.gender_options_getAll();
    const residences$ = this.apiService.residences_getAll()
    const organisations$ = this.apiService.organisations_getAll()
    const levels$ = this.apiService.levels_getAll()
    const customerTypes$ = this.apiService.customerTypes_getAll()
    const savings$ = this.apiService.getMemberDatAccounts(this.apiService.getItem('username'))
    const agencyCollectors$ = this.apiService.agencyCollectors_getAll(this.apiService.getItem('username'))

    combineLatest([userAgency$, filetypes$, genders$, residences$, organisations$, levels$, customerTypes$, savings$, agencyCollectors$]).subscribe(([userAgency, filetypes, genders, residences, organisations, levels, customerTypes, savings, agencyCollectors]) => {
      this.userAgency = userAgency
      this.filetypes = filetypes;
      this.genders = genders
      this.residences = residences
      this.organisations = organisations
      this.levels = levels
      this.customerTypes = customerTypes
      this.dataSource.data = savings
      this.agencyCollectors = agencyCollectors
      this.loading = false
    });
  }
}

