
export class Transactions {
    transactionAmount: number
    currency: string
    collectDate: string
    transactionType: string
    issuer: number
    receiver: number
    cartId: number
    username: string
    beneficiary: string
    communication: string
}
export interface AggragatedData {
    totalcredits: string
    totalcustomers: string
    totalgroups: string

}