import { Component, OnInit } from '@angular/core';
import { Account, Agency } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.css']
})
export class CashFlowComponent implements OnInit {
  agencyCoffreAccounts:Agency[]
  constructor(private apiService: ApiService) { }
  ngOnInit(): void {
    this.fetchData()
  }
  fetchData() {
    this.apiService.getAllAgencyCoffreAccounts().subscribe((coffres) => {
      this.agencyCoffreAccounts = coffres
      console.log(coffres)
    })
  }

}
