export class CartType {
    cartType: string
}
export class Collector {
    username: string
    firstname: string
    lastname: string
    imageUrl: string
    active: Boolean
    birthDate: Date

}
export class Currency {
    currency: string
}
export class Cart {
    customerId: number
    userId: number
    cartTypeId: number
    cartMise: number
    currency: string
    agencyId: number
}
export class Customer {
    firstname: string
    lastname: string
    postname: string
    uniqueColumn: string
    phone1: string
    phone2: string
    gender: string
    email: string
    birthDate: Date
    identityPieceImage_url: string
    identityPiece_type: string
    identityPiece_expiryDate: Date
    birth_place: string
    document_num: string
    nationality: string

    subscriptionDate: Date

    street: string

    partnerName: string

    partnerActivity: string

    partnerPhone: string

    referencePerson_phone: string

    referencePerson: string

    residence_type: string
    activityLocation: string
    activity: string
    profilImage_url: string
    specimenImage_url: string
    mandator: string
    mandatorPhone: string
    experience: number
    supplyLocation: string
    supplier: string
    otherActivity: string
    sourceOfIncome: string
    monthlyIncome: number
    childrenNumber: number
    personInCharge: number
    studyLevel: string
    creditAmount: number
    customerType: string
    organisation: string
    sensibilisator: string
}
export class Transaction {
    transactionAmount: number
    currency: string
    transactionType: string
    issuer: number
    receiver: number
    cartId: number
    username: string
    recipiendaire: string
    communication: string
    userId: number
    isCollected: boolean
}
export class TransactionType {
    transactionTypeId: number
    transactionType: string
}
export class CollectType {
    collectTypeId: number
    collectType: string
}