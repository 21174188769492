<h2 mat-dialog-title>Achat d'une nouvelle carte</h2>
<mat-dialog-content class="mat-typography">
    <form class="example-form">
        <mat-form-field class="example-full-width">
            <mat-label>Type de carte</mat-label>
            <mat-select name="daCartType" [(ngModel)]="selected_cartType">
                <mat-option *ngFor="let cartType of cartTypes"
                    value="{{cartType.cartTypeId}}">{{cartType.cartType}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" style="margin-left: 3px;">
            <mat-label>Agence</mat-label>
            <mat-select name="daMember" [(ngModel)]="agency.agencyId">
                <mat-option [value]="agency.agencyId">{{agency.agencyName}}</mat-option>

            </mat-select>
        </mat-form-field>

        <mat-spinner *ngIf="loading"></mat-spinner>

        <table class="example-full-width" cellspacing="0">
            <tr>
                <td><mat-form-field class="example-full-width">
                        <mat-label>Mise de la carte</mat-label>
                        <input name="daMise" type="number" matInput [(ngModel)]="cartModel.cartMise">
                    </mat-form-field></td>
                <td><mat-form-field class="example-full-width">
                        <mat-label>Dévise</mat-label>
                        <mat-select name="daCurrency" [(ngModel)]="cartModel.currency">
                            <mat-option *ngFor="let currency of currencies"
                                value="{{currency.currency}}">{{currency.currency}}</mat-option>
                        </mat-select>
                    </mat-form-field> </td>
            </tr>
        </table>

        <p>


            <mat-form-field class="example-full-width">
                <mat-label>Collecteur</mat-label>
                <mat-select name="daCurrency" [(ngModel)]="selected_collector">
                    <mat-option *ngFor="let collector of collectors" value="{{collector.userId}}">{{collector.lastname+'
                        '+collector.firstname}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" style="margin-left: 3px;">
                <mat-label>Propriétaire</mat-label>
                <mat-select name="daMember" [(ngModel)]="selected_member">
                    <mat-option>
                        <ngx-mat-select-search (keyup)="onKey($event.target.value)"
                            [placeholderLabel]="'Filtrer le compte à créditer...'"
                            [noEntriesFoundLabel]="'no matching account found'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let member of filteredMembers" value="{{member.customerId}}">{{member.lastname}}
                        {{member.postname}} {{member.firstname}}</mat-option>
                </mat-select>
            </mat-form-field>

        </p>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <p-messages></p-messages>
    <button mat-button mat-dialog-close>Fermer</button>
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!formIsValid()">Acheter</button>
</mat-dialog-actions>