<h2 mat-dialog-title>Creation du groupe</h2>
<mat-dialog-content class="mat-typography">
    <form class="example-form">
        <mat-form-field class="example-full-width">
            <mat-label>Nom du groupe</mat-label>
            <input name="groupName" [(ngModel)]="model.customerGroupName" matInput>
        </mat-form-field>
        <mat-spinner *ngIf="loading"></mat-spinner>
        <table class="example-full-width" cellspacing="0">
            <tr>
                <td><mat-form-field class="example-full-width">
                        <mat-label>Choisir le gestionnaire</mat-label>
                        <mat-select name="manager" [(ngModel)]="model.customerGroupManager">
                            <mat-option *ngFor="let manager of managers"
                                value="{{manager.lastname +' '+ manager.firstname}}">{{manager.lastname +' '+
                                manager.firstname}}</mat-option>

                        </mat-select>
                    </mat-form-field></td>
                <td><mat-form-field class="example-full-width">
                        <mat-label>Choisir l'agence</mat-label>
                        <mat-select name="agency" [(ngModel)]="userAgency.agencyId">
                            <mat-option [value]="userAgency.agencyId">{{userAgency.agencyName}}</mat-option>
                        </mat-select>
                    </mat-form-field></td>
            </tr>
        </table>

        <p>
            <mat-form-field class="example-full-width" style="margin-right: 2px;">
                <mat-label>Axe d'activité</mat-label>
                <input name="axe" [(ngModel)]="model.activityAxis" matInput>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Nature d'activité</mat-label>
                <input name="daNature" [(ngModel)]="model.activityNature" matInput>
            </mat-form-field>
        </p>

    </form>
    <p-messages></p-messages>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Fermer</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true"[disabled]="!formIsValid()" (click)="onGroupSave()">Créer le
        groupe</button>
</mat-dialog-actions>