<H4 class="title"><span class="material-symbols-outlined">
    arrow_back_ios
</span>{{title}}</H4>
<p-messages></p-messages>
<!-- <div *ngIf="model"> -->
<div style="display: flex; flex-flow: row wrap;">
<div class="mat-elevation-z2">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <button mat-mini-fab color="primary" aria-label="icon button to add a branch" (click)="openDialog()">
        <mat-icon>plus_one</mat-icon>
    </button>
    <table mat-table [dataSource]="dataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> Identifiant </th>
            <td mat-cell *matCellDef="let element" (click)=" onSelect(element)"> {{element.username}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef> Prenom </th>
            <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef> Nom </th>
            <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> Agence </th>
            <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
        aria-label="Select page of periodic elements">
    </mat-paginator>
</div>
<div class="form card">
    <form>
        <div style="display: flex;">
            <!-- IDENTIFIANT -->
            <mat-form-field class="large" appearance="fill">
                <mat-label>Identifiant</mat-label>
                <input name="daUser" matInput readonly maxlength="50" [(value)]="user_data.username">
            </mat-form-field>
            <!-- BRANCH -->
            <mat-form-field class="list" appearance="fill">
                <mat-label>Selectionner Agence</mat-label>
                <mat-select name="daAgency" [(ngModel)]="selected_agency_id" required>
                    <mat-option *ngFor="let ag of agencies" value="{{ag.agencyId}}">{{ag.agencyName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <!-- PRENOM -->
            <mat-form-field class="large" appearance="fill">
                <mat-label>Prénom</mat-label>
                <input name="daFirtName" matInput [(ngModel)]="user_data.firstname" required maxlength="50">
            </mat-form-field>
        </div>

        <div>
            <!-- NOM -->
            <mat-form-field class="large" appearance="fill">
                <mat-label>Nom</mat-label>
                <input name="daEditLastName" matInput [(ngModel)]="user_data.lastname" required maxlength="50">
            </mat-form-field>
        </div>
        <div>
            <!-- FONCTION -->
            <mat-form-field class="large" appearance="fill">
                <mat-label>Attribuer un rôle</mat-label>
                <mat-select name="daRole" [(ngModel)]="selected_role_id" required>
                    <mat-option *ngFor="let role of roles" value="{{role.roleId}}">{{role.role}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <button mat-raised-button color="primary" (click)="onValidate()">Attribuer</button>

    </form>
</div>
</div>