
export class Delestage {
    delestageId: number
    delestageAmount: number
    sourceAccountId: number
    destinationAccountId: number
    username: string
    communication: string
  
}
