export class Counts {
    customers: number
    accounts: number
    savings: number
    credits: number
}
export class Transactions {
    transactionAmount: number
    currency: string
    transactionType: string
    issuer: number
    receiver: number
    cartId: number
    username: string
    communication: string
}
export class Collect {
    transactionAmount: number
    username: string
    issuer: number
    communication: string
    depositor: string
    transactionType: string
    currency: string
    isEacCollect: boolean
}
export class CartCollect {
    transactionAmount: number
    transactionType: string
    username: string
    cartId: number
    userId: number
    currency: string
}