<div class="mat-elevation-z8">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="groupName">
            <th mat-header-cell *matHeaderCellDef> Nom Groupe </th>
            <td mat-cell *matCellDef="let element"> {{element.customerGroupName}} </td>
        </ng-container>
        <ng-container matColumnDef="activityNature">
            <th mat-header-cell *matHeaderCellDef> Nature Activité. </th>
            <td mat-cell *matCellDef="let element"> {{element.activityNature}} </td>
        </ng-container>
        <ng-container matColumnDef="activityAxis">
            <th mat-header-cell *matHeaderCellDef> Axe d'Activité </th>
            <td mat-cell *matCellDef="let element"> {{element.activityAxis}} </td>
        </ng-container>
        <ng-container matColumnDef="manager">
            <th mat-header-cell *matHeaderCellDef> Gestionnaire. </th>
            <td mat-cell *matCellDef="let element"> {{element.customerGroupManager}} </td>
        </ng-container>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> Agence. </th>
            <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram">
                        <div style="display: flex;">
                        <p-inputNumber [(ngModel)]="value1" [style]="{'width': '4rem'}" [showButtons]="true"
                        buttonLayout="vertical" spinnerMode="vertical" inputId="vertical"
                        decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                        <div style="margin-left: 15px;">
                            <p-image src="../assets/images/group.png" alt="Profil" width="150"
                            height="150"></p-image>
                            <div class="example-element-symbol"> {{element.customerGroupName}}</div>
                            <div class="example-element-name"> Agence : {{element.agency.agencyName}}</div>
                            <div class="example-element-weight">Balance :{{element.accounts[0].currency=="USD"?
                                (element.accounts[0].accountSold|currency):element.accounts[0].accountSold|currency:'CDF'}}
                             </div>
                             <div class="example-element-weight">Balance :{{element.accounts[1].currency=="USD"?
                                (element.accounts[1].accountSold|currency):element.accounts[1].accountSold|currency:'CDF'}}
                             </div>
                            <mat-form-field class="example-full-width">
                                <mat-label>Taux du jour</mat-label>
                                <input matInput [value]="usdToCdf" disabled="true">
                            </mat-form-field>

                        </div>
                    </div>
                        <table mat-table [dataSource]="element.members" class="mat-elevation-z1">

                            <!-- Position Column -->
                            <ng-container matColumnDef="membername">
                                <th mat-header-cell *matHeaderCellDef> Nom & Postnom </th>
                                <td mat-cell *matCellDef="let member"> {{member.firstname}} {{member.lastname}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="membergender">
                                <th mat-header-cell *matHeaderCellDef> Genre </th>
                                <td mat-cell *matCellDef="let member">{{member.gender}} </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="memberphone">
                                <th mat-header-cell *matHeaderCellDef> Téléphone </th>
                                <td mat-cell *matCellDef="let member"> {{member.phone1}} </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="creditamount">
                                <th mat-header-cell *matHeaderCellDef> Montant </th>
                                <td mat-cell *matCellDef="let member">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Montant (crédit)</mat-label>
                                        <input matInput [(ngModel)]="creditAmounts[member.customerId]">
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                            </tr>
                        </table>
                        <p-messages></p-messages>

                    </div>

                    <mat-stepper orientation="vertical" #stepper class="stepper card" style="margin-left: 15px;">

                        <mat-step [stepControl]="requestedAmountFormGroup">
                            <form [formGroup]="requestedAmountFormGroup">
                                <ng-template matStepLabel>Combien a-t-il demandé?</ng-template>
                                <mat-form-field>
                                    <mat-label>Montant</mat-label>
                                    <input matInput type="number" formControlName="requestedAmount"
                                        placeholder="Ex: 6000" required>
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="currencyFormGroup">
                            <form [formGroup]="currencyFormGroup">
                                <ng-template matStepLabel>Dans quelle devise?</ng-template>
                                <mat-form-field>
                                    <mat-label>Devise</mat-label>
                                    <mat-select formControlName="currency">
                                        <mat-option *ngFor="let currency of currencies"
                                            value="{{currency.currency}}">{{currency.currency}}</mat-option>
                                    </mat-select> </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="interestRateFormGroup">
                            <form [formGroup]="interestRateFormGroup">
                                <ng-template matStepLabel>Au taux de combien?</ng-template>
                                <mat-form-field>
                                    <mat-label>Taux d'intérêt</mat-label>
                                    <input matInput formControlName="interestRate" type="number" required>
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="periodFormGroup">
                            <form [formGroup]="periodFormGroup">
                                <ng-template matStepLabel>pour un echéance en mois de combien ?</ng-template>
                                <mat-form-field>
                                    <mat-label>L'echéance en mois</mat-label>
                                    <input matInput type="number" formControlName="period" />
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="reimbursementModeFormGroup">
                            <form [formGroup]="reimbursementModeFormGroup">
                                <ng-template matStepLabel>Comment va-t-il rembourser?</ng-template>
                                <mat-form-field>
                                    <mat-label>Mode de remboursement</mat-label>
                                    <mat-select formControlName="reimbursementMode">
                                        <mat-option *ngFor="let mode of reimbursementModes"
                                            value="{{mode.reimbursementMode}}">{{mode.reimbursementMode}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="reimbursementTypeFormGroup">
                            <form [formGroup]="reimbursementTypeFormGroup">
                                <ng-template matStepLabel>Par quelle méthode de remboursement?</ng-template>
                                <mat-form-field>
                                    <mat-label>Type de remboursement</mat-label>
                                    <mat-select formControlName="reimbursementType">
                                        <mat-option *ngFor="let type of reimbursementTypes"
                                            value="{{type.reimbursementType}}">{{type.reimbursementType}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="loanManagerFormGroup">
                            <form [formGroup]="loanManagerFormGroup">
                                <ng-template matStepLabel>Gestionnaire du groupe</ng-template>
                                <mat-form-field>
                                    <mat-label>Gestionnaire</mat-label>
                                    <mat-select formControlName="loanManager">
                                        <mat-option *ngFor="let loanManager of loanManagers"
                                            value="{{loanManager.username}}">{{loanManager.firstname
                                            +' '+loanManager.lastname}}</mat-option>
                                    </mat-select> </mat-form-field>
                                <div>
                                    <button mat-button matStepperPrevious>Back</button>
                                    <button mat-button matStepperNext>Next</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>Terminé</ng-template>
                            <p>Vous êtes à la fin</p>

                            <div>
                                <button mat-button matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary"
                                    (click)="onSend(element.customerGroupId,element.agency.agencyId,element.members)">Envoyer
                                    la Demande</button>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>