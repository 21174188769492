<div class="mat-elevation-z8">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>
    <button mat-mini-fab color="primary" aria-label="icon button to add a branch" (click)="openDialog()">
        <mat-icon>plus_one</mat-icon>
    </button>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> Agence </th>
            <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}}</td>
        </ng-container>
        <ng-container matColumnDef="membername">
            <th mat-header-cell *matHeaderCellDef> Nom Membre </th>
            <td mat-cell *matCellDef="let element"> {{element.customer.lastname+' '+element.customer.firstname}} </td>
        </ng-container>
        <ng-container matColumnDef="Gender">
            <th mat-header-cell *matHeaderCellDef> Genre </th>
            <td mat-cell *matCellDef="let element"> {{element.customer.gender}} </td>
        </ng-container>
        <ng-container matColumnDef="cartType">
            <th mat-header-cell *matHeaderCellDef> Type de carnet </th>
            <td mat-cell *matCellDef="let element"> {{element.cartType.cartType}} </td>
        </ng-container>
        <ng-container matColumnDef="cartMise">
            <th mat-header-cell *matHeaderCellDef> Mise </th>
            <td mat-cell *matCellDef="let element"> {{element.cartMise}} </td>
        </ng-container>
        <ng-container matColumnDef="cartAmount">
            <th mat-header-cell *matHeaderCellDef> Montant total </th>
            <td mat-cell *matCellDef="let element"> {{element.cartAmount}} </td>
        </ng-container>
        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef> Dévise </th>
            <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
        </ng-container>
        <ng-container matColumnDef="collector">
            <th mat-header-cell *matHeaderCellDef> Collecteur </th>
            <td mat-cell *matCellDef="let element"> {{element.user.lastname}} {{element.user.firstname}}</td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram card">
                        <p-image [src]="API_URI+element.customer.profilImageName" alt="Profil" width="230"
                        height="250"></p-image>
                        <div class="example-element-position"> {{element.customer.lastname}} </div>
                        <div class="example-element-symbol"> {{element.customer.firstname}} </div>
                        <div class="example-element-position">Type carnet : {{element.cartType.cartType}} </div>

                        <div class="example-element-name">Balance :
                            {{element.currency=='CDF'?(element.cartAmount|currency:'CDF'):element.cartAmount|currency}}
                        </div>
                    </div>
                    <div class="example-element-description card">
                        <form class="example-form">
                            <mat-form-field class="example-full-width">
                                <mat-label>Mise carnet</mat-label>
                                <input matInput disabled value="{{element.cartMise}}" required>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Dévise</mat-label>
                                <mat-select name="currency" [(ngModel)]="element.currency" required>
                                    <mat-option [value]="element.currency">
                                        {{element.currency}}
                                    </mat-option>
                                </mat-select> </mat-form-field>

                            <table class="example-full-width" cellspacing="0">
                                <tr>
                                    <td>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Type de transaction</mat-label>
                                            <mat-select name="transactionType" [(ngModel)]="model.transactionType"
                                                required>
                                                <mat-option *ngFor="let collectType of collectTypes"
                                                    value="{{collectType.collectType}}">
                                                    {{collectType.collectType}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </td>
                                    <td><mat-form-field>
                                            <mat-label>Collecteur</mat-label>
                                            <mat-select name="daCollector" [(ngModel)]="selected_collector_id" required>
                                                <mat-option *ngFor="let collector of agencyCollectors "
                                                    value="{{collector.userId}}">
                                                    {{collector.lastname+' '+collector.firstname}}
                                                </mat-option>
                                            </mat-select>
                                         </mat-form-field>
                                        </td>
                                </tr>
                            </table>

                            <p>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Montant de la transaction</mat-label>
                                    <input name="transactionAmount" matInput type="number"
                                        [(ngModel)]="model.transactionAmount" required>
                                </mat-form-field>

                            </p>
                        </form>
                        <p-messages></p-messages>
                        <button mat-mini-fab color="primary" aria-label="icon button to add a branch"
                            (click)="onCartCollect(element.cardId,element.currency)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>