import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoanType } from 'src/app/models/loanType.model';
import { ApiService } from 'src/app/services/api.service';

export interface Product {
  customer_name: string;
  gender: string;
  phone: string;
  amount: number;
}
interface Column {
  field: string;
  header: string;
}


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RequestComponent implements OnInit {
  title = "Demande de crédit"
  products!: Product[];
  cols!: Column[];

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fithFormGroup: FormGroup;
  sixthFormGroup: FormGroup;
  seventhFormGroup: FormGroup;
  loanTypes: LoanType[]

  constructor(private _formBuilder: FormBuilder, private readonly apiService: ApiService) {
    this.apiService.loanTypes_getAll().subscribe((res)=>this.loanTypes=res)
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });
    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ['', Validators.required],
    });
    this.fithFormGroup = this._formBuilder.group({
      fithCtrl: ['', Validators.required],
    });
    this.sixthFormGroup = this._formBuilder.group({
      sixthCtrl: ['', Validators.required],
    });

    // Get the value of the firstFormGroup.group property.
    const firstFormGroupValue = this.firstFormGroup.value;

    this.cols = [
      { field: 'customer_name', header: 'Nom & Postnom' },
      { field: 'gender', header: 'Genre' },
      { field: 'phone', header: 'Téléphone' },
      { field: 'amount', header: 'Montant demandé' }
    ];
  }
  getGroupData() {
    return [
      {
        customer_name: 'Lawson Mbuyi',
        gender: 'M',
        phone: '0827725983',
        amount: 5000
      },
      {
        customer_name: 'Lawson Mbuyi',
        gender: 'M',
        phone: '0827725983',
        amount: 5000
      },
      {
        customer_name: 'Lawson Mbuyi',
        gender: 'M',
        phone: '0827725983',
        amount: 5000
      },
      {
        customer_name: 'Lawson Mbuyi',
        gender: 'M',
        phone: '0827725983',
        amount: 5000
      },
    ]
  }
}
