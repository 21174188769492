import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-member-portfolio',
  templateUrl: './member-portfolio.component.html',
  styleUrls: ['./member-portfolio.component.css']
})
export class MemberPortfolioComponent {
  loading = true
  displayedColumns: string[] = ['agency', 'loanManager', 'cdfPendingRepaymentCount', 'cdfPendingRepaymentSum', 'usdPendingRepaymentCount', 'usdPendingRepaymentSum', 'cdfWellPaidLoanRequestCount', 'cdfWellPaidLoanRequestVolume', 'usdWellPaidLoanRequestCount', 'usdWellPaidLoanRequestVolume', 'cdfPart1Count', 'cdfPart1Volume', 'usdPart1Count', 'usdPart1Volume', 'cdfPart30Count', 'cdfPart30Volume', 'usdPart30Count', 'usdPart30Volume', 'cdfPart90Count', 'cdfPart90Volume', 'usdPart90Count', 'usdPart90Volume', 'cdfPart180Count', 'cdfPart180Volume', 'usdPart180Count', 'usdPart180Volume', 'cdfPart360Count', 'cdfPart360Volume', 'usdPart360Count', 'usdPart360Volume'];
  dataSource = new MatTableDataSource()
  constructor(private apiService: ApiService) { }
  ngOnInit(): void {
    this.fetchData()
  }
  fetchData() {
    this.apiService.getAgencyCustomerPortfolio(this.apiService.getItem('username')).subscribe((portfolio) => {
      this.dataSource.data = portfolio
      this.loading = false
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
