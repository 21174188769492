import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AuthScopeService } from './services/auth-permissions.service';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Microdev BankApp';
  permissions: { [key: string]: boolean } = {};
  username: string
  profileJson: string = null
  mySubscription

  constructor(public auth: AuthService, private apiService: ApiService, private authScope: AuthScopeService, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false
      }
    })
    this.apiService.getUser_permisions().subscribe((permissions)=>{
      this.authScope.savePermissions(permissions)
    })
    this.apiService.usdToCdf(1).subscribe((rate) => {
      this.apiService.setItem('currencyRate',rate)
    })
    this.auth.user$.subscribe((profile) => {

      if (profile) {
        this.apiService.setItem('username', profile.email)
      }
    })

  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
