import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthScopeService {
  constructor(private auth: AuthService) { }

  public savePermissions(permissions: string[]) {

    localStorage.setItem('USER_PERMISSIONS', JSON.stringify(permissions))
  }

  public userHasPermissions(permissions: Array<string>): boolean {
    const grantedPermissions = JSON.parse(localStorage.getItem('USER_PERMISSIONS'));
    return permissions.every(permission => grantedPermissions.includes(permission));
  }

  public userHasPermissions$(permissions: Array<string>): Observable<boolean> {
    let success = false
    if (!this.auth.isAuthenticated$) {
      return of(success)
    }
    const grantedPermissions = localStorage.getItem('USER_PERMISSIONS')
    if (!grantedPermissions) {
      return of(success)
    }

    return of(permissions.every(permission => grantedPermissions.includes(permission)))
  }

}
