import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Agency, UserAccount } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent {
  title = 'Détails Utilisateur'
  loading: boolean
  model = new UserAccount()
  agencies: Agency[]
  selected_agency_id: string = ''
  date_of_birth: Date
  isChecked: boolean


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private messageService: MessageService,) {
    this.activatedRoute.params.subscribe((params) => {
      this.model.username = params['username']
    })
  }

  ngOnInit() {
   this.fetchData()
  }
  navigateTo() {
    this.router.navigateByUrl("/users")
  }

  formIsValid() {
    let success = false
    success = this.model.username
      && (this.model.firstname != undefined && this.model.firstname.length > 2)
      && (this.model.lastname != undefined && this.model.lastname.length > 2)
    return success
  }
  onSave() {
    this.loading = true
    this.model.agency_id = parseInt(this.selected_agency_id)
    this.model.birthDate = this.date_of_birth
    console.log(this.model)
    this.apiService.userDetails_save(this.model).subscribe(response => {
      this.messageService.add({ severity: 'success', detail: 'Inscription réussie' });
      setTimeout(() => this.router.navigate(['home']), 2000);
    }, error => {
      this.loading = false
      this.messageService.add({ severity: 'error', summary: "Huum! Something went wrong. Try again" });
    });
  }
  fetchData(){
    this.apiService.agencies_getAll().subscribe((agencies) => {
      this.agencies = agencies
    })
  }

}
