<div class="mat-elevation-z8">
    <mat-form-field>
        <mat-label>Filter le groupe</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>
    <button mat-mini-fab color="primary" aria-label="icon button to add a branch" (click)="openDialog()">
        <mat-icon>plus_one</mat-icon>
    </button>
    <mat-spinner *ngIf="loading"></mat-spinner>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef> Agence. </th>
            <td mat-cell *matCellDef="let element"> {{element.agency.agencyName}} </td>
        </ng-container>
        <ng-container matColumnDef="customerGroupName">
            <th mat-header-cell *matHeaderCellDef> Nom Group. </th>
            <td mat-cell *matCellDef="let element"> {{element.customerGroupName}} </td>
        </ng-container>
        <ng-container matColumnDef="customerGroupManager">
            <th mat-header-cell *matHeaderCellDef> Gestionnaire. </th>
            <td mat-cell *matCellDef="let element"> {{element.customerGroupManager}} </td>
        </ng-container>
        <ng-container matColumnDef="activityNature">
            <th mat-header-cell *matHeaderCellDef> Nature D'activité. </th>
            <td mat-cell *matCellDef="let element"> {{element.activityNature}} </td>
        </ng-container>
        <ng-container matColumnDef="activityAxis">
            <th mat-header-cell *matHeaderCellDef> Axe D'activité. </th>
            <td mat-cell *matCellDef="let element"> {{element.activityAxis}} </td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram card">
                        <div class="example-element-symbol"> {{element. customerGroupName}} </div>
                        <div class="example-element-name"> {{element. customerGroupManager}} </div>
                        <div class="example-element-weight"> {{element.activityManager}} </div>
                        <div class="example-element-weight"> {{element.activityNature}} </div>
                        <div class="example-element-weight"> Balance : {{element.accounts[0].currency=="USD"?
                            (element.accounts[0].accountSold|currency):element.accounts[0].accountSold|currency:'CDF'}}
                        </div>
                        <div class="example-element-weight">Balance : {{element.accounts[1].currency=="USD"?
                            (element.accounts[1].accountSold|currency):element.accounts[1].accountSold|currency:'CDF'}}
                        </div>

                    </div>

                    <div class="example-element-description card">
                        <form class="example-form">
                            <mat-form-field class="example-full-width">
                                <input matInput disabled value="{{element.customerGroupName}}">
                            </mat-form-field>

                            <table class="example-full-width" cellspacing="0">
                                <tr>
                                    <td><mat-form-field class="example-full-width">
                                            <input matInput disabled value="{{element.customerGroupManager}}">
                                        </mat-form-field></td>
                                    <td><mat-form-field>
                                            <mat-select [(value)]="element.agency.agencyName">
                                                <mat-option value="{{element.agency.agencyName}}">
                                                    {{element.agency.agencyName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>

                                </tr>
                                <tr>
                                    <td> <mat-form-field>
                                            <mat-label>Filter</mat-label>
                                            <mat-select (selectionChange)="getSelectedMember($event.value)">
                                                <mat-option>
                                                    <ngx-mat-select-search (keyup)="onKey($event.target.value)"
                                                        [placeholderLabel]="'Filtrer le membre à ajouter...'"
                                                        [noEntriesFoundLabel]="'no matching member found'"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let member of selectedMember"
                                                    [value]="member">{{member.lastname}} {{member.postname}}
                                                    {{member.firstname}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td> <button mat-mini-fab color="primary" aria-label="icon button to add a branch"
                                            (click)="onMemberPush()">
                                            <mat-icon>plus_one</mat-icon>
                                        </button></td>


                                </tr>
                                <tr *ngFor="let member of groupMembers" style="display: flex; flex-direction: column;">
                                    <td><mat-form-field><input matInput
                                                value="{{member.firstname}}  {{member.lastname}}  {{member.postname}}" /></mat-form-field>
                                        <button mat-mini-fab color="warn"
                                            aria-label="Example icon button with a delete icon"
                                            style="margin: 5px;padding:5px" (click)="onRemove(member)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>

                            </table>

                        </form>
                        <p-messages></p-messages>
                        <button mat-mini-fab color="primary" aria-label="icon button to add a branch"
                            (click)="onMembersAdd(element.customerGroupId)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>