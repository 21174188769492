import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { AccountType } from 'src/app/models/account.model';
import { CashAccount } from 'src/app/models/cash.model';
import { Institution } from 'src/app/models/institution.model';
import { Agency } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
  loading = false
  readonly BILAN_ACCOUNT_TYPE="Compte de bilan"
  isToggled = false
  institution = new Institution()
  country_id: number
  model = new Institution()
  cities: []
  currencies: []
  agencies: []
  accountType:AccountType
  users: []
  accountOptions: []
  agency = new Agency()
  selected_city_id = ''
  selected_institution_id: number
  selected_agency_id = ''
  selected_userId = ''

  cashAccount = new CashAccount()

  constructor(private apiService: ApiService, private messageService: MessageService,private router:Router) { }
  ngOnInit(): void {
    this.fetchData()
  }
  fetchData() {
    const countryInstitution$ = this.apiService.institution_getOne()
    const cities$ = this.apiService.cities_getAll()
    const agencies$ = this.apiService.agencies_getAll()
    const accountType$ = this.apiService.accountType_getOne(this.BILAN_ACCOUNT_TYPE)
    const accountOptions$ = this.apiService.accountOptions_getAll()
    const users$ = this.apiService.users_getAll()
    const currencies$ = this.apiService.currencies_getAll()

    combineLatest([countryInstitution$, cities$, agencies$, accountType$, accountOptions$, users$, currencies$]).subscribe(([countryInstitution, cities, agencies, accountType, accountOptions, users, currencies]) => {
      this.model = countryInstitution
      this.cities = cities
      this.agencies = agencies
      this.accountType = accountType
      this.accountOptions = accountOptions
      this.users = users
      this.currencies = currencies
    })
  }
  onSubmit() {
    this.apiService.institution_update(this.model).subscribe((res) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Effectué avec succès'
      })
    }, () => {
      this.messageService.add({ severity: 'error', summary: 'erreur lors de la soumission des données' })
    })
  }
  onAgencySave() {
    this.agency.institutionId = this.model.institutionId
    this.agency.cityId = parseInt(this.selected_city_id)
    console.log(this.agency)
    if (this.agency.cityId && this.agency.institutionId && this.agency.agencyName) {
      this.apiService.agency_create(this.agency).subscribe((res) => {
        console.log(res)
        this.messageService.add({
          severity: 'success',
          summary: 'Successfully created'
        })
      }, () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Error lors de la creation de l\'agence'
        })
      })
    }
  }
  toggleUsers() {
    this.isToggled = ['Compte caisse', 'Compte coffre'].includes(this.cashAccount.accountOption);
  }
  onAccountCreation() {
    this.cashAccount.agencyId = parseInt(this.selected_agency_id)
    this.cashAccount.userId = parseInt(this.selected_userId)
    this.cashAccount.accountType=this.accountType.accountType
    
    console.log(this.cashAccount)
    this.apiService.cashAccount_create(this.cashAccount).subscribe((account) => {
      this.messageService.add({
        severity: 'success',
        summary: 'Compte crée avec succè'
      })
      setTimeout(() => this.router.navigate(['configuration']), 1000);

    }, () => {
      this.messageService.add({
        severity: 'error',
        summary: 'Erreur lors de la création du compte'
      })
    })
  }
}
