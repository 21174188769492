<div style="position: relative;">
  <p-panel
    *ngIf="(auth.isAuthenticated$ | async) === false || (daAuth.userHasPermissions$(['read:customers'])  | async) === false"
    header="Permissions" styleClass="dark-panel">
    Sorry, you have no permissions to use BankApp
  </p-panel>
  <div *ngIf="!loading">
    <h2>Dashboard</h2>
    <div class="dashboard"
      *ngIf="((auth.isAuthenticated$ | async) === true && (daAuth.userHasPermissions$(['read:customers'])  | async)) === true">
      <mat-card class="home-card">
        <mat-card-header>
          <mat-card-title>Membres<span class="material-symbols-outlined">
            person
          </span>
          </mat-card-title>
          <mat-card-subtitle>
            1
            <span class="material-symbols-outlined">
              currency_exchange
            </span>{{currencyRate}}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p class="total">{{aggragatedData.totalcustomers}}</p>

        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="goToPage('customers')">Voir</button>

        </mat-card-actions>
      </mat-card>

      <mat-card class="home-card" *ngIf="(auth.isAuthenticated$ | async) && 
      (daAuth.userHasPermissions$(['read:groups'])  | async) === true">
        <mat-card-header>
          <mat-card-title>Groupes
            <span class="material-symbols-outlined">
              group
            </span>

          </mat-card-title>
          <mat-card-subtitle>
            1
            <span class="material-symbols-outlined">
              currency_exchange
            </span>={{currencyRate}}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p class="total">{{aggragatedData.totalgroups}}</p>

        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="goToPage('groups')">Voir</button>

        </mat-card-actions>
      </mat-card>

      <mat-card class="home-card" *ngIf="(auth.isAuthenticated$ | async) && 
      (daAuth.userHasPermissions$(['read:loans'])  | async) === true">
        <mat-card-header>
          <mat-card-title>Crédits
            <span class="material-symbols-outlined">
              money
            </span>
          </mat-card-title>
          <mat-card-subtitle>
            1
            <span class="material-symbols-outlined">
              currency_exchange
            </span>={{currencyRate}}
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>

          <div *ngIf="aggragatedData">
            <p class="total">{{aggragatedData.totalcredits}}</p>
          </div>

        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="goToPage('member-request')">Voir</button>

        </mat-card-actions>
      </mat-card>


      <mat-card class="home-card" *ngIf="(auth.isAuthenticated$ | async) && 
      (daAuth.userHasPermissions$(['read:loans'])  | async) === true">
        <mat-card-header>
          <mat-card-title>Remb. CI
            <span class="material-symbols-outlined">
              money
            </span>

          </mat-card-title>
          <mat-card-subtitle>1
            <span class="material-symbols-outlined">
              currency_exchange
            </span>={{currencyRate}}
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="goToPage('member-repayments')">Voir</button>
        </mat-card-actions>
      </mat-card>

    </div>

  </div>

</div>