<h2>Cash flow</h2>
<mat-tab-group dynamicHeight>
  <div *ngFor="let agencyCoffreAccount of agencyCoffreAccounts">
    <mat-tab label="{{agencyCoffreAccount.agencyName}}">
      <div class=" cash-flow example-large-box card">
        <h2> <span>Coffre </span> <button mat-mini-fab color="primary" aria-label="icon button to add a transaction"
            title="Clôture Caisse">
            <span class="material-symbols-outlined">
              acute
            </span>
          </button>
        </h2>
        <div class="panel">
          <mat-form-field class="large" appearance="fill">
            <mat-label>Balance </mat-label>
            <input name="daName" matInput readonly required maxlength="50"
              [value]="agencyCoffreAccount.accounts[0].currency=='CDF'?(agencyCoffreAccount.accounts[0].accountSold|currency:'CDF'):''">

          </mat-form-field>
        </div>
        <div class="panel">
          <mat-form-field class="large" appearance="fill">
            <mat-label>Balance </mat-label>
            <input name="daName" matInput readonly required maxlength="50"
              [value]="agencyCoffreAccount.accounts[1].currency=='USD'?(agencyCoffreAccount.accounts[1].accountSold|currency):''">
          </mat-form-field>
        </div>
      </div>
    </mat-tab>
  </div>

</mat-tab-group>