import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent {
  memberSelects: Array<{
    matFormField: HTMLElement;
    matSelect: HTMLElement;
  }> = [];
  elementRef: ElementRef;
  constructor(elementRef: ElementRef) { this.elementRef = elementRef; }
  
  addMemberSelectTo(parent: HTMLElement) {
    const matFormField = document.createElement('mat-form-field');
    const matLabel = document.createElement('label');
    matLabel.textContent = 'Selectionner le membre';
    const matSelect = document.createElement('select') as HTMLSelectElement;
    (matSelect as HTMLSelectElement).options.add(new Option('None'));
    (matSelect as HTMLSelectElement).options.add(new Option(' '));

    matFormField.appendChild(matLabel);
    matFormField.appendChild(matSelect);

    // Append the button to the mat-form-field.
    const button = document.createElement('button');
    button.type = 'button';
    button.classList.add('mat-mini-fab');
    button.classList.add('mat-accent');
    button.setAttribute('aria-label', 'icon button to add a branch');
    button.style.marginLeft = '15px';
    const matIcon = document.createElement('mat-icon');
    matIcon.textContent = 'delete';
    button.appendChild(matIcon);
    matFormField.appendChild(button);

    parent.appendChild(matFormField);

    this.memberSelects.push({ matFormField, matSelect });
  }
}
