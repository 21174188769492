import { RepaymentDetails } from "./loanRepayment.model"

export class Customer {
    firstname: string
    postname: string
    lastname: string
    birthDate: Date
    phone1: string
    phone2: string
    email: string
    street: string
    agency_id: number
    identityPieceImage: File
    profilImage: File
    specimenImage: string
    identityPiece_type: string
    document_num: string
    identityPiece_expiryDate: Date
    birth_place: string
    gender: string
    nationality: string

    customerId: number
    activity: string
    activityLocation: string
    supplyLocation: string
    supplier: string
    otherActivity: string
    sourceOfIncome: string
    monthlyIncome: number
    childrenNumber: number
    personInCharge: number
    studyLevel: string
    customerType: string

    partnerName: string
    partnerActivity: string
    partnerPhone: string

    experience: string

    organisation: string

    referencePerson: string
    referencePerson_phone: string

    mandator: string
    mandatorPhone: string
    sensibilisator: string

    residence_type: string
}

export class Member {
    customerId: number

    firstname: string

    lastname: string

    postname: string

    uniqueColumn: string

    phone1: string

    phone2: string

    gender: string

    email: string

    birthDate: Date

    identityPieceImage_url: string

    identityPiece_type: string

    identityPiece_expiryDate: Date

    birth_place: string

    document_num: string

    nationality: string

    subscriptionDate: Date

    street: string

    partnerName: string

    partnerActivity: string

    partnerPhone: string

    referencePerson_phone: string

    referencePerson: string

    residence_type: string

    activityLocation: string

    activity: string

    profilImage_url: string

    specimenImage_url: string

    mandator: string

    mandatorPhone: string

    experience: number

    supplyLocation: string

    supplier: string

    otherActivity: string

    sourceOfIncome: string

    monthlyIncome: number

    childrenNumber: number

    personInCharge: number

    studyLevel: string

    creditAmount: number
    convertedAmount: number

    customerType: string

    organisation: string

    sensibilisator: string
}
export class MemberRepayment {
    convertedAmount: number
    creditAmount: number
    customerId: number
    repaymentDetails: RepaymentDetails
}
