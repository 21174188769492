<h2><span>Coffre</span> <button mat-mini-fab color="primary" aria-label="icon button to add a transaction"
        title="Clôture Caisse">

        <span class="material-symbols-outlined">
            acute
        </span>
    </button></h2>
<div class="caisse">

    <!-- CDF BALANCE -->
    <div class="panel">
        <mat-form-field class="large" appearance="fill">
            <mat-label>Balance</mat-label>
            <input name="daName" matInput required maxlength="50"
                [value]="coffreAccounts[1]?.currency=='USD'? (coffreAccounts[1]?.accountSold|currency):coffreAccounts[1]?.accountSold|currency:'CDF'">
        </mat-form-field>

    </div>
    <!-- USD BALANCE -->
    <div class="panel">

        <mat-form-field class="large" appearance="fill">
            <mat-label>Balance</mat-label>
             <input name="daName" matInput required maxlength="50"
                [value]="coffreAccounts[0].currency=='CDF'? (coffreAccounts[0].accountSold|currency:'CDF'):coffreAccounts[0].accountSold|currency">
        </mat-form-field>
    </div>

    <div class="panel big">

        <!-- FILTER -->
        <div>
            <mat-checkbox class="example-margin" [(ngModel)]="isDelestage">Delestage
            </mat-checkbox>
            <mat-checkbox class="example-margin" [(ngModel)]="isApprobation">Approbation
            </mat-checkbox>
        </div>

        <div *ngIf="isDelestage">
            <h5>Déléstage</h5>
            <div>
                <mat-form-field class="large" appearance="fill">
                    <mat-label disabled>Compte source</mat-label>
                    <mat-select name="daSourceAccount" required [(ngModel)]="sourceAccount">
                        <mat-option *ngFor="let coffreAccount of coffreAccounts"
                            [value]="coffreAccount">{{coffreAccount.currency+'-'+coffreAccount.accountLibelle}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="large" appearance="fill">
                    <mat-label disabled>Compte de destination</mat-label>
                    <mat-select name="daCartAccount" required [(ngModel)]="destinationAccount">
                        <mat-option *ngFor="let cashAccount of cashAccounts"
                            [value]="cashAccount">{{cashAccount.currency+'-'+cashAccount.accountLibelle}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div>
                <mat-form-field class="large" appearance="fill">
                    <mat-label>Montant delestage </mat-label>
                    <input name="daAmount" matInput type="number" [(ngModel)]="delestage.delestageAmount" maxlength="50"
                        required>
                </mat-form-field>

            </div>
            <div>
                <mat-form-field class="large" appearance="fill">
                    <mat-label>Communication</mat-label>
                    <textarea matInput required [(ngModel)]="delestage.communication"></textarea>
                </mat-form-field>

            </div>
        </div>
        <div *ngIf="isApprobation">
            <h5>Approbation</h5>

            <div class=" card" style="width: 400px;">
                <mat-form-field class="large" appearance="fill">
                    <mat-label disabled>Approbation en attente</mat-label>
                    <mat-select name="daTransaction" required [(ngModel)]="delestage.delestageId">
                        <mat-option *ngFor="let pendingDelestage of pendingDelestages"
                            value="{{pendingDelestage.delestageId}}">{{pendingDelestage.destinationAccount.accountLibelle}}
                            :
                            {{pendingDelestage.destinationAccount.currency=="CDF"?(pendingDelestage.delestageAmount|currency:'CDF'):pendingDelestage.delestageAmount|currency}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- TABLE OF TRANSACTIONS -->
        <div class="transactions-container mat-elevation-z8">

            <button mat-mini-fab color="primary" aria-label="icon button to add a transaction"
                title="Ajouter une transaction" (click)="createAccountDelestages()" *ngIf="isDelestage">
                <mat-icon>plus_one</mat-icon>
            </button>
            <button mat-mini-fab color="primary" aria-label="icon button to add a transaction"
                title="Ajouter une transaction" (click)="delestageValidate()" *ngIf="isApprobation">
                <mat-icon>plus_one</mat-icon>
            </button>
            <!-- MESSAGES -->
            <p-messages></p-messages>
            <div class="example-loading-shade">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                <div class="example-rate-limit-reached">

                </div>
            </div>

            <div class="example-table-container">
                <table mat-table [dataSource]="dataSource" matSort matSortActive="date_created"
                    (matSortChange)="announceSortChange($event)" class="example-table">

                    <!-- Position Column -->
                    <ng-container matColumnDef="transactionId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                            ID transaction.
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.transactionId}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="transaction_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                            Type de transaction
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.transactionType}} </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="source_account">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
                            Compte source
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.issuerAccount?.accountLibelle}} </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                            Montant
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.transactionAmount}} </td>
                    </ng-container>
                    <ng-container matColumnDef="receiver">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                            Compte de destination
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.receiverAccount?.accountLibelle}} </td>
                    </ng-container>

                    <ng-container matColumnDef="date_created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                            Date transaction
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdDate | date:'dd-MM-yyyy HH:mm'}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

            </div>

            <mat-paginator [pageSizeOptions]="[10, 20, 100]" aria-label="Select page of users"></mat-paginator>

        </div>

    </div>

</div>