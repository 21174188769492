import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { Account } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  constructor(private apiService: ApiService, private messageService: MessageService,private router:Router) { }
  accountTypes: []
  isLoadingResults: boolean
  agencies: []
  currencies: []
  model = new Account()
  selected_agency_id = ''

  ngOnInit(): void {
    this.fetchData()
  }

  fetchData() {
    const accountTypes$ = this.apiService.accountTypes_getAll()
    const currencies$ = this.apiService.currencies_getAll()
    const agencies$ = this.apiService.agencies_getAll()
    combineLatest([accountTypes$, currencies$, agencies$]).subscribe(([accountTypes, currencies, agencies]) => {
      this.accountTypes = accountTypes
      this.currencies = currencies
      this.agencies = agencies
    })
  }
  onSubmit() {
    this.isLoadingResults = true

    if (this.model.accountIndice && this.model.accountLibelle && this.model.accountType && this.model.currency) {
      this.model.agencyId = parseInt(this.selected_agency_id)
      this.apiService.account_create(this.model).subscribe((res) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Compte crée avec succès'
        })
        this.model.accountIndice = ''
        this.model.accountLibelle = ''
        this.model.accountType = ''
        this.model.currency = ''
        this.selected_agency_id = ''
        this.isLoadingResults = false
        setTimeout(() => this.router.navigate(['account']), 2000);

      }, () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Something went wrong'
        })
        this.isLoadingResults = false
      })
    }
  }


}
